<h2>D&D Stats</h2>

<section *ngFor="let character of characters; let i=index">
  <header>
    <h3>
      <input type="text" [(ngModel)]="character.name" class="name" placeholder="Character Name">
    </h3>
    <!-- <button class="remove" (click)="removeCharacter(i)">remove</button> -->
  </header>

  <div class="stats">
    <ng-container *ngFor="let stat of character.stats">
      <div class="cell cell--modifier">{{ stat.modifier | statModifier }}</div>
      <div class="cell cell--val-input"><input type="range" step="1" min="-5" max="7" [(ngModel)]="stat.modifier" /></div>
      <div class="cell cell--title"><h4>{{ stat.title }}</h4></div>
      <div class="cell cell--description">{{ stat.description }}</div>
    </ng-container>
  </div>
</section>

<div class="actions">
  <button (click)="addCharacter()">Add Character</button>
</div>
