import { Component, OnInit } from '@angular/core';
import { ApplicationOptions, PixiComponent } from '../pixi-component';
import { Engine, Events } from 'matter-js';
import { Universe } from './universe.interface';
import * as SimplexNoise from 'simplex-noise';
import { Point, Vector } from '../point';
import { Cell } from './cell';
import { Tile } from './tile';
import { WorldView } from './world-view';
// import { WorldViewGismo } from './world-view-gizmo';
import { Gizmo } from './gizmo';
import { Container, Graphics } from 'pixi.js';
import { WorldViewGismo } from './world-view-gizmo';

const noise = new SimplexNoise('josh');

@Component({
  selector: 'app-scrolling-tiles',
  templateUrl: './scrolling-tiles.component.html',
  styleUrls: ['./scrolling-tiles.component.scss'],
})
export class ScrollingTilesComponent extends PixiComponent implements OnInit {
  isMouseDown = false;

  readonly applicationOptions: ApplicationOptions = { antialias: false };

  ngOnInit(): void {
    super.ngOnInit();
    void this.init();
  }

  private init() {
    const width = this.app.view.width;
    const height = this.app.view.height;

    const engine = Engine.create();

    const universe: Universe = {
      world: engine.world,
      app: this.app,
      entities: [],
      physBodyToEntityMap: {},
    };

    Events.on(engine, 'collisionStart', c => {
      c.pairs.forEach(pair => {
        const entityA = universe.physBodyToEntityMap[pair.bodyA.id];
        const entityB = universe.physBodyToEntityMap[pair.bodyB.id];

        if (entityA && entityB) {
          entityA.handleCollision(entityB);
          entityB.handleCollision(entityA);
        }
      });
    });

    engine.world.gravity.y = 0;

    const map = new Container();
    map.sortableChildren = true;
    this.app.stage.addChild(map);

    const gizmosContainer = new Container();
    const gizmos = new Set<Gizmo>();
    this.app.stage.addChild(gizmosContainer);

    const center = new Graphics();
    center.beginFill(0xffffff);
    center.drawCircle(0, 0, 16);
    center.endFill();
    center.alpha = 0.5;
    center.position.copyFrom({
      x: width / 2,
      y: height / 2,
    });
    this.app.stage.addChild(center);

    // Cells
    const size = 10;
    const cols = Math.ceil(width / size);
    const rows = Math.ceil(height / size);
    for (let col = 0; col < cols; col++) {
      for (let row = 0; row < rows; row++) {
        const pos = new Vector({
          x: col * size,
          y: row * size,
        });
        const randPos = pos.clone().multiply(0.05 / size);
        const elevation = noise.noise2D(randPos.x, randPos.y);

        const cell = new Cell(col, row, {
          elevation,
        });
      }
    }

    const worldView = new WorldView({
      width,
      height,
      cellSize: Tile.size,
      limit: {
        top: 0,
        left: 0,
        right: cols + 1,
        bottom: rows + 1,
      },
    });

    const scale = 0.25;
    const worldViewGismo = new WorldViewGismo(worldView, scale);
    worldViewGismo.container.position.copyFrom({
      x: width * (-scale * 0.5) + width / 2,
      y: height * (-scale * 0.5) + height / 2,
    });
    gizmos.add(worldViewGismo);
    gizmosContainer.addChild(worldViewGismo.container);

    const target = new Point();

    const targetGraphic = new Graphics();
    targetGraphic.lineStyle(5, 0x990000);
    targetGraphic.drawCircle(0, 0, 24);

    const cell = worldView.worldPointToCell(target);
    const point = worldView.cellToWorldPoint(cell);

    targetGraphic.position.copyFrom(worldView.worldPointToUiPoint(point));
    this.app.stage.addChild(targetGraphic);

    let mouseHasLifted = false;

    this.app.ticker.add(() => {
      if (this.keyPressed.has('r')) {
        worldView.cellSize *= 11 / 10;
        Tile.size = worldView.cellSize;
      } else if (this.keyPressed.has('f')) {
        worldView.cellSize *= 10 / 11;
        Tile.size = worldView.cellSize;
      }

      if (this.isMouseDown) {
        if (mouseHasLifted) {
          mouseHasLifted = false;
          const worldPoint = worldView.uiPointToWorldPoint(this.mouse);
          const cell = worldView.worldPointToCell(worldPoint);
          target.set({
            x: (cell.col + 0.5) * Tile.size,
            y: (cell.row + 0.5) * Tile.size,
          });
        }
      } else {
        mouseHasLifted = true;
      }

      const dist = worldView.center.distanceTo(target);
      worldView.center.moveToward(target, dist / 10);
      targetGraphic.position.copyFrom(worldView.worldPointToUiPoint(target));

      const added: string[] = [];
      const dropped: string[] = [];

      const visibleCellIds = new Set([...worldView.visibleCells()].map(c => JSON.stringify(c)));
      // console.log(visibleCellIds);

      Object.values(Tile.tiles).forEach(tile => {
        const cellId = JSON.stringify({
          col: tile.cell.col,
          row: tile.cell.row,
        });

        if (visibleCellIds.has(cellId)) {
          visibleCellIds.delete(cellId);
        } else {
          tile.destroy();
          dropped.push(`[${tile.cell.col},${tile.cell.row}]`);
        }
      });

      visibleCellIds.forEach(id => {
        const coords = JSON.parse(id);
        const cell = Cell.getCell(coords.col, coords.row);
        if (cell) {
          added.push(`[${cell.col},${cell.row}]`);
          new Tile(cell, map);
        }
      });

      // if (added.length || dropped.length) {
      //   console.log('added', added.join('   '));
      //   console.log('dropped', dropped.join('   '));
      // }

      Object.values(Tile.tiles).forEach(tile => {
        tile.graphics.position.copyFrom(worldView.cellToWorldPoint(tile));
        tile.graphics.scale.copyFrom({
          x: Tile.size,
          y: Tile.size,
        });
      });

      // Object.values(Cell.cells).forEach(cell => {
      //   const pos = Tile.position(cell);
      //   const isInRange = this.mouse.distanceTo(pos) < 500;
      //   const tile = Tile.getTile(cell.col, cell.row);

      //   if (isInRange && !tile) {
      //     const tile = new Tile(cell, map);
      //   }

      //   if (!isInRange && tile) {
      //     tile.destroy();
      //   }
      // });
    });

    this.app.ticker.add(() => {
      // Destroy those marked for deletion
      universe.entities.forEach((ent, i) => {
        if (ent.delete) {
          ent.onDestroy();
          universe.entities.splice(i, 1);
        }
      });

      // Update everything
      universe.entities.forEach(entity =>
        entity.onUpdate({ mouse: this.mouse, isMouseDown: this.isMouseDown, keyPressed: this.keyPressed }),
      );

      gizmos.forEach(gizmo => gizmo.update());

      // Draw everything
      universe.entities.forEach(entity => entity.onDraw());
    });
    Engine.run(engine);
  }

  onMouseDown(ev: MouseEvent) {
    this.isMouseDown = true;
  }

  onMouseUp(ev: MouseEvent) {
    this.isMouseDown = false;
  }
}
