import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IntroTimingService {
  private phaseSubject = new BehaviorSubject<number>(0);
  readonly phase = this.phaseSubject.pipe(distinctUntilChanged());

  private completeSubject = new BehaviorSubject<boolean>(false);
  readonly complete = this.completeSubject.pipe(distinctUntilChanged());

  constructor() {}

  async run() {
    await this.sleepThenIncement(500); // start "this is"
    await this.sleepThenIncement(1000); // start "josh"
    await this.sleepThenIncement(200); // start "hansen"
    await this.sleepThenIncement(500); // end of "hansen" animation
    await this.sleepThenIncement(500); // additional delay
    this.completeSubject.next(true);
  }

  private async sleepThenIncement(ms: number) {
    await this.sleep(ms);
    this.incrementPhase();
  }

  private incrementPhase() {
    const current = this.phaseSubject.value;
    this.phaseSubject.next(current + 1);
  }

  private async sleep(ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms));
  }
}
