import { Graphics } from 'pixi.js';
import { rgb } from 'src/app/rgb';
import { PointLike } from '../point';
import { Cell, CellType } from './cell';

export class Tile {
  static size = 64;
  static tiles: Record<string, Tile> = {};

  static getTile(col: number, row: number) {
    return this.tiles[Cell.id(col, row)];
  }

  graphics: Graphics;

  constructor(readonly cell: Cell, private stage: PIXI.Container) {
    Tile.tiles[this.id] = this;
    const size = Tile.size;

    const square = new Graphics();
    square.position.copyFrom(this.position);
    square.beginFill(0xffffff);
    square.drawRect(0, 0, 1, 1);
    square.endFill();

    // square.beginFill(0xcccccc);
    // square.drawRect(0, size, size, size / 2);
    // square.endFill();
    this.stage.addChild(square);

    square.zIndex = this.row;

    square.tint = cell.type === CellType.land ? rgb(0, 1 - cell.elevation / 2, 0) : rgb(0, 0, 1 + cell.elevation / 2);

    this.graphics = square;
  }

  static id(col: number, row: number) {
    return `${col}_${row}`;
  }

  static position(colOrCell: Cell | number, row = 0): PointLike {
    if (typeof colOrCell == 'number') {
      return {
        x: colOrCell * Tile.size,
        y: row * Tile.size,
      };
    }
    return {
      x: colOrCell.col * Tile.size,
      y: colOrCell.row * Tile.size,
    };
  }

  get position() {
    return Tile.position(this.col, this.row);
  }

  get col() {
    return this.cell.col;
  }

  get row() {
    return this.cell.row;
  }

  get id() {
    return Cell.id(this.col, this.row);
  }

  destroy() {
    this.stage.removeChild(this.graphics);
    delete Tile.tiles[this.id];
  }
}
