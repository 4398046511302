import { Graphics, TextStyle } from 'pixi.js';

export abstract class Gizmo {
  readonly container: Graphics;

  constructor() {
    this.container = new Graphics();
  }

  abstract onInit(container: Graphics): void;
  abstract onUpdate(): void;
  abstract onDestroy(): void;

  init() {
    this.onInit(this.container);
  }

  destroy() {
    this.onDestroy();
  }

  update() {
    this.onUpdate();
  }
}

export const gizmoTextStyle = new TextStyle({
  fontFamily: 'Courier New',
  fontSize: 50,
  fill: ['#fff', '#eee'], // gradient
  stroke: '#333',
  strokeThickness: 2,
  // dropShadow: true,
  // dropShadowColor: '#000000',
  // dropShadowBlur: 4,
  // dropShadowAngle: Math.PI / 6,
  // dropShadowDistance: 6,
  // wordWrap: true,
  // wordWrapWidth: 440,
  // lineJoin: 'round'
});
