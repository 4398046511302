import { Bodies, Body } from 'matter-js';
import { BLEND_MODES, Graphics } from 'pixi.js';
import { π } from 'src/app/pi';
import { rand } from 'src/app/rand';
import { PointLike, Vector, Point } from '../../point';
import { Game } from '../game';
import { DirectionalGizmo, DirectionWeigher } from './directional-gizmo';
import { Entity } from './entity';

export class Blob extends Entity {
  readonly graphic: Graphics;
  readonly body: Body;
  readonly directions: DirectionWeigher;

  private blob: Graphics;
  private shadow: Graphics;
  readonly timeOffset = rand(1000);

  constructor(position: PointLike, readonly radius = 1) {
    super();
    this.graphic = new Graphics();

    const shadow = new Graphics();
    shadow.beginFill(0xccccee);
    shadow.drawCircle(0, 0, radius);
    shadow.endFill();
    shadow.scale.y = 0.5;
    shadow.blendMode = BLEND_MODES.MULTIPLY;
    this.shadow = shadow;
    this.graphic.addChild(shadow);

    const blob = new Graphics();
    blob.beginFill(0x009900);
    blob.drawCircle(0, 0, radius);
    blob.endFill();
    blob.pivot.copyFrom({
      x: 0,
      y: radius,
    });
    this.graphic.addChild(blob);
    this.blob = blob;

    // physics
    this.body = Bodies.circle(position.x, position.y, radius * 1.5);
    this.body.frictionAir = 0.9;

    // directions
    this.directions = new DirectionWeigher(this.body.position);
  }

  onInit(game: Game) {
    this.addGraphic(this.graphic);
    this.addBody(this.body);

    const directionGizmo = new DirectionalGizmo(this.directions);
    directionGizmo.addToGame(game);
  }

  onUpdate(game: Game) {
    // movement
    const vector = this.directions.final.clone().multiply(0.001);
    Body.applyForce(this.body, this.body.position, vector);

    // update graphics
    this.graphic.position.copyFrom(this.body.position);

    this.directions.position.set(this.body.position);

    // visual wobble (body)
    const time = new Date().getTime() / 200 + this.timeOffset;
    this.blob.scale.y = 1 + Math.sin(time) / 20;
    this.blob.scale.x = 1 + Math.sin(time + π) / 20;

    // visual wobble (shadow)
    this.shadow.scale.x = this.blob.scale.x * 0.8;
    this.shadow.scale.y = this.shadow.scale.x * 0.5;

    // z sorting
    this.graphic.zIndex = this.graphic.position.y;
  }

  onDestroy(game: Game) {
    game.pixiWorld.removeChild(this.graphic);
    game.removePhysicsBody(this.body);
  }
}
