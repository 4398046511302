import { Howl } from 'howler';
import { Bodies, Body } from 'matter-js';
import { Graphics } from 'pixi.js';
import { π } from 'src/app/pi';
import { rand } from 'src/app/rand';
import { rgb } from 'src/app/rgb';
import { Point, PointLike, Vector } from '../point';
import { Asteroid } from './asteroid';
import { Entity, Tag, UpdateData } from './entity';
import { Universe } from './universe.interface';

function randomFireColor() {
  const r = rand(0.75, 1);
  return rgb(r, rand(r, 1), 0);
}

export class Explosion extends Entity {
  readonly pixiBody: Graphics;
  radius = 20;
  age = 0;
  lifespan = 20;

  readonly physBody: Body;

  get position() {
    return {
      x: this.pixiBody.position.x,
      y: this.pixiBody.position.y,
    };
  }

  constructor(
    universe: Universe,
    position: PointLike = Point.center,
    private scale = 1,
    color: number = randomFireColor(),
  ) {
    super(universe);
    console.log(`Boom!!`, position);

    this.tags.add(Tag.wall);

    // render
    this.pixiBody = new Graphics();
    this.pixiBody.position.copyFrom(position);
    this.pixiBody.beginFill(color);
    this.pixiBody.drawCircle(0, 0, this.radius * scale);
    this.pixiBody.endFill();
    this.pixiBody.position.copyFrom(position);
    this.registerPixiBody(this.pixiBody);

    // Physics
    this.physBody = Bodies.circle(position.x, position.y, this.radius * scale, {
      isStatic: true,
      isSensor: true,
    });
    this.registerPhysBody(this.physBody);

    this.playSound('explosion', 0.2);
  }

  onUpdate(data: UpdateData) {
    super.onUpdate(data);
    this.age++;
    if (this.age > this.lifespan) {
      this.destroy();
    }
  }

  handleCollision(entity: Entity) {
    if (entity.hasTag(Tag.asteroid) || entity.hasTag(Tag.ship)) {
      const asteroid = entity as Asteroid;
      const force = new Vector(this.position, asteroid.position);
      force.magnitude = (100 * this.scale) / Math.pow(force.magnitude, 1.2);
      // asteroid.push(force);
      Body.setVelocity(asteroid.physBody, new Vector(asteroid.physBody.velocity).add(force));
    }
  }

  onDraw() {
    const x = (π * this.age) / this.lifespan;
    const a = 0.5 + Math.sin(x) / 2;
    this.pixiBody.alpha = a;
    this.pixiBody.scale.copyFrom({
      x: a,
      y: a,
    });
  }
}
