import { BLEND_MODES, Graphics } from 'pixi.js';
import { π2 } from 'src/app/pi';
import { Point, PointLike, Vector } from '../../point';
import { Game } from '../game';
import { Entity } from './entity';

export class DirectionWeigher {
  readonly vectors: Vector[] = [];
  readonly position: Point;
  readonly final = new Vector(0, 0);

  constructor(position: PointLike, readonly count = 16) {
    this.position = new Point(position);

    for (let i = 0; i < count; i++) {
      const vector = new Vector((π2 * i) / count);
      this.vectors.push(vector);
    }
  }

  consider(point: PointLike, weight = 1) {
    this.final.set(0, 0);
    for (let i = 0; i < this.count; i++) {
      const vector = new Vector((π2 * i) / this.count);
      const dp = Math.max(0, vector.dotProduct(new Vector(point).subtract(this.position)) * weight);
      vector.normalize(dp);
      this.vectors[i].set(vector);
      this.final.add(vector);
    }
    // this.final.normalize(Math.abs(weight));
    return this;
  }

  addConsideration(point: PointLike, weight = 1) {
    for (let i = 0; i < this.count; i++) {
      const vector = new Vector((π2 * i) / this.count);
      const dp = Math.max(0.00001, vector.dotProduct(new Vector(point).subtract(this.position)) * weight);
      vector.normalize(dp);
      this.vectors[i].add(vector);
      this.final.add(vector);
    }
    // this.final.normalize(Math.abs(weight));
    return this;
  }

  reset() {
    this.final.set(0, 0);
    this.vectors.forEach(v => v.normalize(0));
  }
}

export class DirectionalGizmo extends Entity {
  readonly graphic: Graphics;

  readonly pixiVectors: PixiVector[] = [];
  finalVector: PixiVector;

  constructor(public directionWeigher: DirectionWeigher, public innerRadius = 16, public spokeLength = 5) {
    super();

    this.finalVector = new PixiVector(directionWeigher.final);

    const center = new Graphics();
    center.lineStyle(1, 0x999999, 1, 0);
    center.drawCircle(0, 0, innerRadius);
    // center.endFill();
    center.blendMode = BLEND_MODES.ADD;
    center.position.copyFrom(directionWeigher.position);
    this.graphic = center;

    center.addChild(this.finalVector.graphic);

    directionWeigher.vectors.forEach(vector => {
      const pv = new PixiVector(vector);
      this.pixiVectors.push(pv);
      const pvPosition = vector.clone().normalize(innerRadius);
      pv.graphic.position.copyFrom(pvPosition);
      center.addChild(pv.graphic);
    });

    center.addChild(this.finalVector.graphic);
  }

  onInit(game: Game) {
    game.pixiWorld.addChild(this.graphic);
  }

  onUpdate() {
    this.finalVector.update((1 / this.finalVector.vector.magnitude) * this.innerRadius * 0.9);
    this.directionWeigher.vectors.forEach((vector, i) => this.pixiVectors[i].update(this.spokeLength));
    this.graphic.position.copyFrom(this.directionWeigher.position);
  }

  onDestroy(game: Game) {
    game.pixiWorld.removeChild(this.graphic);
  }
}

export class PixiVector {
  graphic: Graphics;

  constructor(readonly vector = new Vector().normalize(0)) {
    this.graphic = new Graphics();

    this.graphic.lineStyle(1, 0x333333);
    this.graphic.moveTo(0, 0);
    this.graphic.lineTo(1, 0);
    this.graphic.rotation = this.vector.angle;

    this.graphic.alpha = 0.75;
    this.update(1);
  }

  update(spokeLength: number) {
    this.graphic.scale.copyFrom({
      x: this.vector.magnitude * spokeLength,
      y: 1,
    });

    this.graphic.rotation = this.vector.angle;
  }
}
