import * as SimplexNoise from 'simplex-noise';
import { Application, Graphics, Sprite } from 'pixi.js';
import { PointLike } from '../point';

export interface HexGlobals {
  waterLevel: number;
  masterScale: number;
  octaveAWavelength: number;
  octaveAAmplitude: number;
  octaveBWavelength: number;
  octaveBAmplitude: number;
  octaveCWavelength: number;
  octaveCAmplitude: number;
  edge: number;
  offset: PointLike;
  center: PointLike;
  elevationScale: number;
}

export class Hexagon {
  readonly id: string;
  x: number;
  y: number;

  static noise = new SimplexNoise('josh');

  sprite: Graphics;

  elevation(globals: HexGlobals) {
    const masterScale = globals.masterScale;
    const o = globals.offset;
    const c = globals.center;
    const x = (this.x / masterScale - o.x) * masterScale - c.x;
    const y = (this.y / masterScale - o.y) * masterScale - c.y;

    // Ocatave A
    const octaveAWavelength = globals.octaveAWavelength * masterScale;
    const octaveAAmplitude = globals.octaveAAmplitude;
    let elevationA = ((1 + Hexagon.noise.noise2D(x / octaveAWavelength, y / octaveAWavelength)) / 2) * octaveAAmplitude;

    // let elevation = elevationA;

    // elevationA *= 1 / 0.8;
    // elevationA = Math.min(1, Math.max(0, elevationA));
    // elevationA = Math.pow(elevationA, 10);
    elevationA = convertToPlainsAndMountains(elevationA);

    // Ocatave B
    const octaveBWavelength = globals.octaveBWavelength * masterScale;
    const octaveBAmplitude = globals.octaveBAmplitude;
    const elevationB =
      ((1 + Hexagon.noise.noise2D(x / octaveBWavelength, y / octaveBWavelength)) / 2) * octaveBAmplitude;

    // Ocatave C
    const octaveCWavelength = globals.octaveCWavelength * masterScale;
    const octaveCAmplitude = globals.octaveCAmplitude;
    const elevationC =
      ((1 + Hexagon.noise.noise2D(x / octaveCWavelength, y / octaveCWavelength)) / 2) * octaveCAmplitude;

    let elevation = (elevationA + elevationB + elevationC) / (octaveAAmplitude + octaveBAmplitude + octaveCAmplitude);

    if (globals.edge) {
      const nearestBorder = Math.min(
        this.x,
        Math.min(this.y, Math.min(this.app.view.width - this.x, this.app.view.height - this.y)),
      );
      const edge = globals.edge * Math.min(this.app.view.width, this.app.view.height);
      // const edgeMultiplier = 0.5 + Math.atan((nearestBorder - edge) / edge) / Math.PI;
      const edgeMultiplier = Math.min(1, 2 * (-1 / (nearestBorder / edge + 1)) + 2);
      elevation *= edgeMultiplier;
    }

    return elevation;
    return Math.min(1, Math.max(0, elevation));

    // // const round = 1 / 16;
    // // elevation = Math.floor(elevation / round) * round;

    // elevation = elevation * (1 / 0.8);

    // elevation = (0.5 - Math.abs(elevation - 0.5)) * 2;

    // elevation = Math.pow(elevation, 2);

    // return Math.min(1, Math.max(0, elevation));
  }

  constructor(
    private app: Application,
    options: {
      id: string;
      x: number;
      y: number;
    },
  ) {
    this.id = options.id;
    this.x = options.x;
    this.y = options.y;

    const graphics = new Graphics();

    // const sprite = new Sprite(app.loader.resources.isoHex!.texture);
    // sprite.anchor.x = 0.5;
    // sprite.anchor.y = 0.5;
    // graphics.addChild(sprite);

    const height = 100;
    const width = 14;
    graphics.beginFill(0xcccccc);
    graphics.drawRect(-width, 0, width, height);
    graphics.endFill();
    graphics.beginFill(0xaaaaaa);
    graphics.drawRect(0, 0, width, height);
    graphics.endFill();

    // graphics.beginFill(0xffffff);
    // graphics.drawRect(-1, -16, 2, 32);
    // graphics.drawRect(-3, -15, 6, 30);
    // graphics.drawRect(-5, -14, 10, 28);
    // graphics.drawRect(-7, -13, 14, 26);
    // graphics.drawRect(-9, -12, 18, 24);
    // graphics.drawRect(-11, -11, 22, 22);
    // graphics.drawRect(-13, -10, 26, 20);
    // graphics.drawRect(-14, -9, 28, 18);
    // graphics.endFill();

    graphics.beginFill(0xffffff);
    graphics.drawRect(-1, -11, 2, 22);
    graphics.drawRect(-4, -10, 8, 20);
    graphics.drawRect(-7, -9, 14, 18);
    graphics.drawRect(-10, -8, 20, 16);
    graphics.drawRect(-13, -7, 26, 14);
    graphics.drawRect(-14, -6, 28, 12);

    graphics.endFill();

    // graphics.drawStar(0, 0, 6, 16, (16 * Math.sqrt(3)) / 2);
    // graphics.scale = new PIXI.Point(1, 2 / 3);

    const treeSprite = new Sprite(app.loader.resources.tree!.texture);
    treeSprite.anchor.x = 0.5;
    treeSprite.anchor.y = 1;
    treeSprite.visible = false;
    treeSprite.x = -5 + Math.floor(Math.random() * 10) + 0.5;
    treeSprite.y = -5 + Math.floor(Math.random() * 10);
    graphics.addChild(treeSprite);

    const rockSprite = new Sprite(app.loader.resources[`rock-${Math.floor(Math.random() * 4) + 1}`]!.texture);
    rockSprite.anchor.x = 0.5;
    rockSprite.anchor.y = 1;
    rockSprite.visible = false;
    rockSprite.x = -5 + Math.floor(Math.random() * 10);
    rockSprite.y = -5 + Math.floor(Math.random() * 10);
    graphics.addChild(rockSprite);

    this.sprite = graphics;
    app.stage.addChild(this.sprite);
  }

  update(globals: HexGlobals) {
    const sprite = this.sprite;
    sprite.zIndex = this.y;
    const elevation = this.elevation(globals);
    sprite.x = Math.round(this.x);
    sprite.y = Math.round(this.y - Math.max(0, (elevation - globals.waterLevel) * globals.elevationScale));
    // sprite.tint = parseInt(`00${(elevation * 255).toString(16).padStart(2, '0')}00`, 16);
    // sprite.tint = Math.round(0x100 * elevation) * 0x100;

    if (elevation < globals.waterLevel) {
      // water
      sprite.tint = rgbTint(elevation * 0.5, elevation * 0.5, 0.5 + elevation);
    } else {
      // ground
      sprite.tint = rgbTint(0, Math.pow(elevation, 0.5), 0);
    }

    const showTree = elevation > 0.7 && elevation < 0.8;
    sprite.children[0].visible = showTree;

    const showRock = elevation > 0.8;
    sprite.children[1].visible = showRock;

    // if (elevation < globals.waterLevel) {
    //   sprite.alpha = elevation * 2;
    //   // sprite.texture = this.app.loader.resources.water!.texture;
    // } else {
    //   sprite.alpha = elevation;
    //   // sprite.texture = this.app.loader.resources.ground!.texture;
    // }
  }
}

function rgbTint(r: number, g: number, b: number) {
  r = Math.max(0, Math.min(1, r));
  g = Math.max(0, Math.min(1, g));
  b = Math.max(0, Math.min(1, b));
  const rc = Math.round(0xff * r) * 0x010000;
  const gc = Math.round(0xff * g) * 0x0100;
  const bc = Math.round(0xff * b);
  return rc + gc + bc;
}

const π = Math.PI;
function convertToPlainsAndMountains(e: number): number {
  const s = (Math.cos(π * (e / 2 - 1)) + 1) / 2;
  const m = Math.pow((0.5 - Math.abs(s - 0.5)) * 2, 10);
  const n = 4;
  const t = (π * n * e + Math.sin(π * n * e)) / (n * π);
  const w = 0.5;
  const y = (m + w * t) / (w + 1);
  return y;
}

// @ts-ignore
window.convertToPlainsAndMountains = convertToPlainsAndMountains;
