<header>
  <h1>Flowchart</h1>
</header>
<main>
  <div class="controls">controls</div>
  <nav>
    nav
  </nav>
  <section class="content" #content (mousemove)="updateMouse($event)">
    <canvas class="canvas" #canvas></canvas>
  </section>
  <aside class="details">Details</aside>
</main>
<footer>Footer</footer>
