<div class="controls" (mousedown)="$event.stopPropagation()" (mouseup)="$event.stopPropagation()">
  <h2>Controls</h2>

  <div class="values">
    <label>
      <span>Water Level</span>
      <span>{{ waterLevel | number:'0.2-2' }}</span>
      <input type="range" step="0.01" min="0" max="1" [(ngModel)]="waterLevel">
    </label>

    <label>
      <span>Master Scale</span>
      <span>{{ masterScale | number:'1.2-2' }}</span>
      <input type="range" step="0.01" min="0.1" max="10" [(ngModel)]="masterScale">
    </label>

    <label>
      <span>Edge</span>
      <span>{{ edge }}</span>
      <input type="range" step="0.01" min="0" max="1" [(ngModel)]="edge">
    </label>

    <label>
      <span>Elevation</span>
      <span>{{ elevationScale }}</span>
      <input type="range" step="1" min="0" max="1500" [(ngModel)]="elevationScale">
    </label>

    <h3>Octave A</h3>
    <label>
      <span>Amplitude</span>
      <span>{{ octaveAAmplitude }}</span>
      <input type="range" step="0.01" min="0" max="1" [(ngModel)]="octaveAAmplitude">
    </label>

    <label>
      <span>Wavelength</span>
      <span>{{ octaveAWavelength }}</span>
      <input type="range" step="1" min="100" max="2000" [(ngModel)]="octaveAWavelength">
    </label>

    <h3>Octave B</h3>
    <label>
      <span>Amplitude</span>
      <span>{{ octaveBAmplitude }}</span>
      <input type="range" step="0.01" min="0" max="1" [(ngModel)]="octaveBAmplitude">
    </label>

    <label>
      <span>Wavelength</span>
      <span>{{ octaveBWavelength }}</span>
      <input type="range" step="0.01" min="10" max="500" [(ngModel)]="octaveBWavelength">
    </label>

    <h3>Octave C</h3>
    <label>
      <span>Amplitude</span>
      <span>{{ octaveCAmplitude }}</span>
      <input type="range" step="0.01" min="0" max="1" [(ngModel)]="octaveCAmplitude">
    </label>

    <label>
      <span>Wavelength</span>
      <span>{{ octaveCWavelength }}</span>
      <input type="range" step="0.001" min="1" max="50" [(ngModel)]="octaveCWavelength">
    </label>

    <label>
      <span>Offset</span>
      <span></span>
      <span>{{ offset.x | number:'1.2-2' }}, {{ offset.y | number:'1.2-2' }}</span>
    </label>
  </div>
</div>
