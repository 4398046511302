import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class CropperComponent implements OnInit {
  // @ts-ignore
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  // @ts-ignore
  private ctx: CanvasRenderingContext2D | null;

  private img?: HTMLImageElement;

  name = 'photo';

  constructor() {}

  ngOnInit(): void {
    this.canvas.nativeElement.setAttribute('width', `${this.canvas.nativeElement.clientWidth}px`);
    this.canvas.nativeElement.setAttribute('height', `${this.canvas.nativeElement.clientHeight}px`);
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.ctx?.fillRect(10, 10, 160, 90);

    this.bindDropper(this.canvas.nativeElement, img => {
      this.img = img;
      console.log({ img });

      const x = (3840 - img.naturalWidth) / 2;
      const y = (2160 - img.naturalHeight) / 2;

      this.ctx?.drawImage(img, x, y);
    });
  }

  bindDropper(el: HTMLElement, cb: (image: HTMLImageElement) => void) {
    function doNothing(e: DragEvent) {
      e.stopPropagation();
      e.preventDefault();
    }

    el.addEventListener('dragenter', doNothing, false);
    el.addEventListener('dragover', doNothing, false);
    el.addEventListener('dragleave', doNothing, false);
    el.addEventListener(
      'drop',
      (e: DragEvent) => {
        const ctx = this.ctx;
        if (!ctx) {
          return;
        }

        function handleFiles(files: FileList) {
          if (!ctx) {
            return;
          }

          for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var imageType = /image.*/;
            if (!file.type.match(imageType)) {
              continue;
            }
            const img = document.createElement('img');
            img.classList.add('obj');
            // @ts-ignore
            img.file = file;
            var reader = new FileReader();
            reader.onload = (function(aImg) {
              return function(e: any) {
                aImg.onload = function() {
                  cb(aImg);
                  // ctx.drawImage(aImg, 0, 0);
                };
                // e.target.result is a dataURL for the image
                aImg.src = e.target.result;
              };
            })(img);
            reader.readAsDataURL(file);
          } // end for
        } // end handleFiles

        doNothing(e);

        if (e.dataTransfer) {
          const url = e.dataTransfer.getData('text/plain');
          if (url) {
            var img = new Image();
            img.onload = () => {
              cb(img);
              // ctx.drawImage(img, 0, 0);
            };
            img.src = url;
          } else {
            handleFiles(e.dataTransfer.files);
          }
        }
      },
      false,
    );
  }

  download() {
    const link = document.createElement('a');
    link.download = `${this.name}.png`;
    link.href = this.canvas.nativeElement.toDataURL();
    link.click();
  }
}
