import { Component, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { ContentLoadedService } from 'src/app/services/content-loaded.service';
import { IntroTimingService } from 'src/app/services/intro-timing.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  msg = '...';

  visible = false;

  introPhase = this.introTimingService.phase;

  constructor(
    private readonly messageService: MessageService,
    private readonly contentLoadedService: ContentLoadedService,
    private readonly introTimingService: IntroTimingService,
  ) {}

  ngOnInit(): void {
    this.animateIn();
  }

  async animateIn() {
    console.log(`loading message`);
    this.msg = await this.messageService.getMessage();
    console.log(`loaded`);

    console.log(`claiming loaded`);
    this.contentLoadedService.claimLoaded();

    console.log(`waiting for intro timing service`);
    await this.introTimingService.complete
      .pipe(
        filter(x => !!x),
        take(1),
      )
      .toPromise();
    console.log(`timing done.. making visible`);
    this.visible = true;
  }
}
