import { Graphics } from 'pixi.js';

import { PointLike } from '../point';

export class Drawing {
  graphics: Graphics;
  points: PointLike[];

  constructor(
    readonly key: string,
    // readonly points: PointLike[] = [],
    private lineWidth: number,
    private lineColor: number,
    private fill: number,
  ) {
    this.points = JSON.parse(window.localStorage.getItem(`drawing-${this.key}`) || '[]');
    this.graphics = new Graphics();
    this.draw();
  }

  private lineTo(x: number, y: number, move = false) {
    this.graphics[move ? 'moveTo' : 'lineTo'](x, y);
  }

  draw() {
    this.graphics.clear();
    const count = this.points.length;

    if (count < 2) {
      return this;
    }

    this.graphics.lineStyle(this.lineWidth, this.lineColor);
    this.graphics.beginFill(this.fill);

    let i = 0;
    this.lineTo(this.points[i].x, this.points[i].y, true);
    for (let i = 1; i < count; i++) {
      this.lineTo(this.points[i].x, this.points[i].y);
    }
    this.graphics.endFill();

    // this.graphics.lineStyle(this.lineWidth, this.lineColor,  0.5);
    // this.lineTo(this.points[i].x, this.points[i].y, true);
    // this.lineTo(this.points[0].x, this.points[0].y);

    return this;
  }

  addPoint(point: PointLike) {
    this.points.push(point);
    return this;
  }

  removeLastPoint() {
    this.points.pop();
    return this;
  }

  save() {
    window.localStorage.setItem(`drawing-${this.key}`, JSON.stringify(this.points));
    return this;
  }
}
