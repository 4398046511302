import { Graphics } from 'pixi.js';

export enum CellType {
  water,
  land,
}

export class Cell {
  elevation: number;
  private graphics?: Graphics;

  static cells: Record<string, Cell> = {};

  static getCell(col: number, row: number) {
    return this.cells[Cell.id(col, row)];
  }

  constructor(
    readonly col: number,
    readonly row: number,
    data: {
      elevation: number;
    },
  ) {
    Cell.cells[this.id] = this;
    this.elevation = data.elevation;
  }

  get type(): CellType {
    return this.elevation < 0 ? CellType.water : CellType.land;
  }

  static id(col: number, row: number) {
    return `${col}_${row}`;
  }

  get id() {
    return Cell.id(this.col, this.row);
  }

  get nbrTop() {
    return Cell.getCell(this.col, this.row - 1);
  }

  get nbrRight() {
    return Cell.getCell(this.col + 1, this.row);
  }

  get nbrBottom() {
    return Cell.getCell(this.col, this.row + 1);
  }

  get nbrLeft() {
    return Cell.getCell(this.col - 1, this.row);
  }

  get nbrs() {
    return [this.nbrTop, this.nbrRight, this.nbrBottom, this.nbrLeft];
  }
}
