import { Howl } from 'howler';
import { Bodies, Body, Composite, World } from 'matter-js';
import { Graphics } from 'pixi.js';
import { PointLike, Point, Vector } from '../point';
import { Entity, Tag } from './entity';
import { Explosion } from './explosion';
import { Universe } from './universe.interface';

export class Bullet extends Entity {
  readonly pixiBody: Graphics;
  readonly physBody: Body;
  age = 0;

  static width = 10;
  static height = 2;
  static lifespan = 200;

  get position() {
    return {
      x: this.physBody.position.x,
      y: this.physBody.position.y,
    };
  }

  constructor(private universe: Universe, position: PointLike = Point.center, velocity: PointLike = Point.center) {
    super(universe);

    this.tags.add(Tag.bullet);

    // Rendering
    this.pixiBody = new Graphics();
    this.pixiBody.position.copyFrom(position);
    this.pixiBody.beginFill(0xffffff);
    this.pixiBody.drawRect(-Bullet.width / 2, -Bullet.height / 2, Bullet.width, Bullet.height);
    this.pixiBody.endFill();
    this.registerPixiBody(this.pixiBody);

    // Physics
    this.physBody = Bodies.rectangle(position.x, position.y, Bullet.width, Bullet.height, {
      frictionAir: 0,
      frictionStatic: 0,
      angle: new Vector(velocity).angle,
      isSensor: true,
      label: 'bullet',
      // restitution: 1,
      // mass: 10,
    });
    Body.setVelocity(this.physBody, velocity);
    this.registerPhysBody(this.physBody);

    // const width = this.app.view.width;
    // const x = (this.position.x - width / 2) / (width / 2);
    // // const stereo = (Math.pow(2 * Math.sin(x * πh), 0.2) + x) / 3;
    // const stereo = Math.cbrt(x);
    // console.log(stereo);
    // new Howl({
    //   src: ['./assets/sounds/laser-alt.wav'],
    //   volume: 0.1,
    //   // @ts-ignore
    //   stereo,
    // }).play();

    this.playSound('lazer', 0.1);
  }

  onUpdate() {
    this.age++;
    if (this.age > Bullet.lifespan) {
      this.delete = true;
    }
  }

  handleCollision(entity: Entity) {
    if (!(entity.hasTag(Tag.bullet) || entity.hasTag(Tag.wall))) {
      this.destroy();
    }

    if (entity.hasTag(Tag.ship)) {
      // new Explosion(this.universe, this.physBody.position, 0.5, 0x55ff55);
    } else if (this.delete) {
      new Explosion(this.universe, this.physBody.position);
    }
  }

  onDraw() {
    this.pixiBody.alpha = 1 - this.age / Bullet.lifespan;
    this.pixiBody.rotation = this.physBody.angle;
    this.pixiBody.position.copyFrom(this.physBody.position);
  }
}
