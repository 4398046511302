import { AfterViewInit, Component, ElementRef, NgZone, OnInit } from '@angular/core';

@Component({
  selector: 'app-pixi-game-engine-test-page',
  templateUrl: './pixi-game-engine-test-page.component.html',
  styleUrls: ['./pixi-game-engine-test-page.component.scss'],
})
export class PixiGameEngineTestPageComponent implements AfterViewInit {
  constructor(readonly elementRef: ElementRef<HTMLDivElement>, protected ngZone: NgZone) {}

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.init();
    });
  }

  init() {
    // const game = new Game({
    //   container: this.elementRef.nativeElement,
    //   pixiOptions: {
    //     // antialias: true,
    //     backgroundColor: 0xffffff,
    //   },
    // });
  }
}
