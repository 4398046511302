import { PointLike } from '../point';

const sqrt3 = Math.sqrt(3);
const hexRatio = sqrt3 / 2;

export interface CoordLike {
  col: number;
  row: number;
}

export class GridMath {
  constructor(readonly size: number) {}

  pointToNearestCoord(point: PointLike): CoordLike {
    const col = Math.round(point.x / (this.size * hexRatio));
    const row = Math.round((point.y - (col % 2) * (this.size * 0.5)) / this.size);

    return {
      col,
      row,
    };
  }

  coordToPoint(coord: CoordLike): PointLike {
    const x = (coord.col * this.size * sqrt3) / 2;
    const y = coord.row * this.size + ((coord.col % 2) * this.size) / 2;
    return {
      x,
      y,
    };
  }
}
