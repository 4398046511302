import { MapPointType } from './map-point';

export interface CellData {
  type: MapPointType;
  name?: string;
}

export const cells: Record<string, CellData> = {
  '1_13': {
    type: MapPointType.dot,
  },
  '1_15': {
    type: MapPointType.dot,
  },
  '10_0': {
    type: MapPointType.dot,
  },
  '10_1': {
    type: MapPointType.mountain,
  },
  '10_10': {
    type: MapPointType.mountain,
  },
  '10_11': {
    type: MapPointType.mountain,
  },
  '10_12': {
    type: MapPointType.mountain,
  },
  '10_13': {
    type: MapPointType.dot,
  },
  '10_14': {
    type: MapPointType.mountain,
  },
  '10_15': {
    type: MapPointType.mountain,
  },
  '10_16': {
    type: MapPointType.mountain,
  },
  '10_17': {
    type: MapPointType.mountain,
  },
  '10_18': {
    type: MapPointType.dot,
  },
  '10_19': {
    type: MapPointType.mountain,
  },
  '10_2': {
    type: MapPointType.mountain,
  },
  '10_20': {
    type: MapPointType.mountain,
  },
  '10_21': {
    type: MapPointType.circleCity,
    name: 'Las Vegas',
  },
  '10_22': {
    type: MapPointType.mountain,
  },
  '10_23': {
    type: MapPointType.dot,
  },
  '10_24': {
    type: MapPointType.dot,
  },
  '10_25': {
    type: MapPointType.mountain,
  },
  '10_26': {
    type: MapPointType.dot,
  },
  '10_27': {
    type: MapPointType.dot,
  },
  '10_3': {
    type: MapPointType.mountain,
  },
  '10_31': {
    type: MapPointType.dot,
  },
  '10_33': {
    type: MapPointType.dot,
  },
  '10_4': {
    type: MapPointType.dot,
  },
  '10_5': {
    type: MapPointType.mountain,
  },
  '10_6': {
    type: MapPointType.dot,
  },
  '10_7': {
    type: MapPointType.dot,
  },
  '10_8': {
    type: MapPointType.dot,
  },
  '10_9': {
    type: MapPointType.mountain,
  },
  '11_0': {
    type: MapPointType.mountain,
  },
  '11_1': {
    type: MapPointType.mountain,
  },
  '11_10': {
    type: MapPointType.mountain,
  },
  '11_11': {
    type: MapPointType.mountain,
  },
  '11_12': {
    type: MapPointType.mountain,
  },
  '11_13': {
    type: MapPointType.mountain,
  },
  '11_14': {
    type: MapPointType.dot,
  },
  '11_15': {
    type: MapPointType.mountain,
  },
  '11_16': {
    type: MapPointType.dot,
  },
  '11_17': {
    type: MapPointType.mountain,
  },
  '11_18': {
    type: MapPointType.dot,
  },
  '11_19': {
    type: MapPointType.mountain,
  },
  '11_2': {
    type: MapPointType.mountain,
  },
  '11_20': {
    type: MapPointType.mountain,
  },
  '11_21': {
    type: MapPointType.mountain,
  },
  '11_22': {
    type: MapPointType.mountain,
  },
  '11_23': {
    type: MapPointType.dot,
  },
  '11_24': {
    type: MapPointType.dot,
  },
  '11_25': {
    type: MapPointType.dot,
  },
  '11_26': {
    type: MapPointType.dot,
  },
  '11_27': {
    type: MapPointType.dot,
  },
  '11_3': {
    type: MapPointType.mountain,
  },
  '11_31': {
    type: MapPointType.dot,
  },
  '11_32': {
    type: MapPointType.dot,
  },
  '11_33': {
    type: MapPointType.dot,
  },
  '11_4': {
    type: MapPointType.mountain,
  },
  '11_5': {
    type: MapPointType.mountain,
  },
  '11_6': {
    type: MapPointType.mountain,
  },
  '11_7': {
    type: MapPointType.dot,
  },
  '11_8': {
    type: MapPointType.dot,
  },
  '11_9': {
    type: MapPointType.mountain,
  },
  '12_0': {
    type: MapPointType.mountain,
  },
  '12_1': {
    type: MapPointType.dot,
  },
  '12_10': {
    type: MapPointType.mountain,
  },
  '12_11': {
    type: MapPointType.circleCity,
    name: 'Boise',
  },
  '12_12': {
    type: MapPointType.mountain,
  },
  '12_13': {
    type: MapPointType.mountain,
  },
  '12_14': {
    type: MapPointType.dot,
  },
  '12_15': {
    type: MapPointType.mountain,
  },
  '12_16': {
    type: MapPointType.mountain,
  },
  '12_17': {
    type: MapPointType.dot,
  },
  '12_18': {
    type: MapPointType.mountain,
  },
  '12_19': {
    type: MapPointType.mountain,
  },
  '12_2': {
    type: MapPointType.dot,
  },
  '12_20': {
    type: MapPointType.mountain,
  },
  '12_21': {
    type: MapPointType.mountain,
  },
  '12_22': {
    type: MapPointType.mountain,
  },
  '12_23': {
    type: MapPointType.dot,
  },
  '12_24': {
    type: MapPointType.dot,
  },
  '12_25': {
    type: MapPointType.mountain,
  },
  '12_26': {
    type: MapPointType.mountain,
  },
  '12_27': {
    type: MapPointType.dot,
  },
  '12_28': {
    type: MapPointType.dot,
  },
  '12_29': {
    type: MapPointType.dot,
  },
  '12_3': {
    type: MapPointType.mountain,
  },
  '12_34': {
    type: MapPointType.dot,
  },
  '12_35': {
    type: MapPointType.dot,
  },
  '12_4': {
    type: MapPointType.dot,
  },
  '12_5': {
    type: MapPointType.mountain,
  },
  '12_6': {
    type: MapPointType.dot,
  },
  '12_7': {
    type: MapPointType.mountain,
  },
  '12_8': {
    type: MapPointType.mountain,
  },
  '12_9': {
    type: MapPointType.dot,
  },
  '13_0': {
    type: MapPointType.mountain,
  },
  '13_1': {
    type: MapPointType.mountain,
  },
  '13_10': {
    type: MapPointType.mountain,
  },
  '13_11': {
    type: MapPointType.dot,
  },
  '13_12': {
    type: MapPointType.mountain,
  },
  '13_13': {
    type: MapPointType.mountain,
  },
  '13_14': {
    type: MapPointType.mountain,
  },
  '13_15': {
    type: MapPointType.mountain,
  },
  '13_16': {
    type: MapPointType.mountain,
  },
  '13_17': {
    type: MapPointType.mountain,
  },
  '13_18': {
    type: MapPointType.dot,
  },
  '13_19': {
    type: MapPointType.dot,
  },
  '13_2': {
    type: MapPointType.mountain,
  },
  '13_20': {
    type: MapPointType.mountain,
  },
  '13_21': {
    type: MapPointType.mountain,
  },
  '13_22': {
    type: MapPointType.mountain,
    name: 'Grand Canyon',
  },
  '13_23': {
    type: MapPointType.dot,
  },
  '13_24': {
    type: MapPointType.dot,
  },
  '13_25': {
    type: MapPointType.mountain,
  },
  '13_26': {
    type: MapPointType.dot,
  },
  '13_27': {
    type: MapPointType.mountain,
  },
  '13_28': {
    type: MapPointType.dot,
  },
  '13_29': {
    type: MapPointType.dot,
  },
  '13_3': {
    type: MapPointType.mountain,
  },
  '13_30': {
    type: MapPointType.dot,
  },
  '13_31': {
    type: MapPointType.dot,
  },
  '13_35': {
    type: MapPointType.dot,
  },
  '13_36': {
    type: MapPointType.dot,
  },
  '13_4': {
    type: MapPointType.mountain,
  },
  '13_5': {
    type: MapPointType.mountain,
  },
  '13_6': {
    type: MapPointType.dot,
  },
  '13_7': {
    type: MapPointType.mountain,
  },
  '13_8': {
    type: MapPointType.mountain,
  },
  '13_9': {
    type: MapPointType.mountain,
  },
  '14_0': {
    type: MapPointType.mountain,
  },
  '14_1': {
    type: MapPointType.dot,
  },
  '14_10': {
    type: MapPointType.mountain,
  },
  '14_11': {
    type: MapPointType.dot,
  },
  '14_12': {
    type: MapPointType.dot,
  },
  '14_13': {
    type: MapPointType.mountain,
  },
  '14_14': {
    type: MapPointType.dot,
  },
  '14_16': {
    type: MapPointType.dot,
  },
  '14_17': {
    type: MapPointType.dot,
  },
  '14_18': {
    type: MapPointType.mountain,
  },
  '14_19': {
    type: MapPointType.dot,
  },
  '14_2': {
    type: MapPointType.mountain,
  },
  '14_20': {
    type: MapPointType.mountain,
  },
  '14_21': {
    type: MapPointType.mountain,
  },
  '14_22': {
    type: MapPointType.mountain,
  },
  '14_23': {
    type: MapPointType.mountain,
  },
  '14_24': {
    type: MapPointType.mountain,
  },
  '14_25': {
    type: MapPointType.mountain,
  },
  '14_26': {
    type: MapPointType.circleCity,
    name: 'Phoenix',
  },
  '14_27': {
    type: MapPointType.dot,
  },
  '14_28': {
    type: MapPointType.mountain,
  },
  '14_29': {
    type: MapPointType.mountain,
  },
  '14_3': {
    type: MapPointType.dot,
  },
  '14_30': {
    type: MapPointType.dot,
  },
  '14_31': {
    type: MapPointType.circleCity,
    name: 'Hermosillo',
  },
  '14_32': {
    type: MapPointType.dot,
  },
  '14_37': {
    type: MapPointType.dot,
  },
  '14_38': {
    type: MapPointType.dot,
  },
  '14_4': {
    type: MapPointType.dot,
  },
  '14_5': {
    type: MapPointType.mountain,
  },
  '14_6': {
    type: MapPointType.mountain,
  },
  '14_7': {
    type: MapPointType.dot,
  },
  '14_8': {
    type: MapPointType.dot,
  },
  '14_9': {
    type: MapPointType.mountain,
  },
  '15_0': {
    type: MapPointType.mountain,
  },
  '15_1': {
    type: MapPointType.dot,
  },
  '15_10': {
    type: MapPointType.dot,
  },
  '15_11': {
    type: MapPointType.mountain,
  },
  '15_12': {
    type: MapPointType.dot,
  },
  '15_13': {
    type: MapPointType.mountain,
  },
  '15_14': {
    type: MapPointType.dot,
  },
  '15_15': {
    type: MapPointType.circleCity,
    name: 'Salt Lake\nCity',
  },
  '15_16': {
    type: MapPointType.dot,
  },
  '15_17': {
    type: MapPointType.mountain,
  },
  '15_18': {
    type: MapPointType.mountain,
  },
  '15_19': {
    type: MapPointType.mountain,
  },
  '15_2': {
    type: MapPointType.mountain,
  },
  '15_20': {
    type: MapPointType.mountain,
  },
  '15_21': {
    type: MapPointType.mountain,
  },
  '15_22': {
    type: MapPointType.dot,
  },
  '15_23': {
    type: MapPointType.mountain,
  },
  '15_24': {
    type: MapPointType.dot,
  },
  '15_25': {
    type: MapPointType.dot,
  },
  '15_26': {
    type: MapPointType.dot,
  },
  '15_27': {
    type: MapPointType.dot,
  },
  '15_28': {
    type: MapPointType.mountain,
  },
  '15_29': {
    type: MapPointType.mountain,
  },
  '15_3': {
    type: MapPointType.mountain,
  },
  '15_30': {
    type: MapPointType.mountain,
  },
  '15_31': {
    type: MapPointType.mountain,
  },
  '15_32': {
    type: MapPointType.dot,
  },
  '15_33': {
    type: MapPointType.dot,
  },
  '15_38': {
    type: MapPointType.dot,
  },
  '15_4': {
    type: MapPointType.mountain,
  },
  '15_5': {
    type: MapPointType.mountain,
  },
  '15_6': {
    type: MapPointType.mountain,
  },
  '15_7': {
    type: MapPointType.mountain,
  },
  '15_8': {
    type: MapPointType.dot,
  },
  '15_9': {
    type: MapPointType.mountain,
  },
  '16_0': {
    type: MapPointType.dot,
  },
  '16_1': {
    type: MapPointType.mountain,
  },
  '16_10': {
    type: MapPointType.mountain,
  },
  '16_11': {
    type: MapPointType.mountain,
  },
  '16_12': {
    type: MapPointType.mountain,
  },
  '16_13': {
    type: MapPointType.mountain,
  },
  '16_14': {
    type: MapPointType.mountain,
  },
  '16_15': {
    type: MapPointType.mountain,
  },
  '16_16': {
    type: MapPointType.mountain,
  },
  '16_17': {
    type: MapPointType.mountain,
  },
  '16_18': {
    type: MapPointType.mountain,
  },
  '16_19': {
    type: MapPointType.dot,
  },
  '16_2': {
    type: MapPointType.mountain,
  },
  '16_20': {
    type: MapPointType.dot,
  },
  '16_21': {
    type: MapPointType.dot,
  },
  '16_22': {
    type: MapPointType.dot,
  },
  '16_23': {
    type: MapPointType.mountain,
  },
  '16_24': {
    type: MapPointType.mountain,
  },
  '16_25': {
    type: MapPointType.mountain,
  },
  '16_26': {
    type: MapPointType.dot,
  },
  '16_27': {
    type: MapPointType.mountain,
  },
  '16_28': {
    type: MapPointType.dot,
  },
  '16_29': {
    type: MapPointType.mountain,
  },
  '16_3': {
    type: MapPointType.mountain,
  },
  '16_30': {
    type: MapPointType.mountain,
  },
  '16_31': {
    type: MapPointType.mountain,
  },
  '16_32': {
    type: MapPointType.mountain,
  },
  '16_33': {
    type: MapPointType.mountain,
  },
  '16_34': {
    type: MapPointType.dot,
  },
  '16_39': {
    type: MapPointType.dot,
  },
  '16_4': {
    type: MapPointType.mountain,
  },
  '16_5': {
    type: MapPointType.mountain,
  },
  '16_6': {
    type: MapPointType.mountain,
  },
  '16_7': {
    type: MapPointType.dot,
  },
  '16_8': {
    type: MapPointType.dot,
  },
  '16_9': {
    type: MapPointType.mountain,
  },
  '17_0': {
    type: MapPointType.dot,
  },
  '17_1': {
    type: MapPointType.circleCity,
    name: 'Calgary',
  },
  '17_10': {
    type: MapPointType.mountain,
  },
  '17_11': {
    type: MapPointType.mountain,
  },
  '17_12': {
    type: MapPointType.mountain,
  },
  '17_13': {
    type: MapPointType.mountain,
  },
  '17_14': {
    type: MapPointType.mountain,
  },
  '17_15': {
    type: MapPointType.mountain,
  },
  '17_16': {
    type: MapPointType.mountain,
  },
  '17_17': {
    type: MapPointType.mountain,
  },
  '17_18': {
    type: MapPointType.mountain,
  },
  '17_19': {
    type: MapPointType.mountain,
  },
  '17_2': {
    type: MapPointType.mountain,
  },
  '17_20': {
    type: MapPointType.dot,
  },
  '17_21': {
    type: MapPointType.mountain,
  },
  '17_22': {
    type: MapPointType.mountain,
  },
  '17_23': {
    type: MapPointType.dot,
  },
  '17_24': {
    type: MapPointType.mountain,
  },
  '17_25': {
    type: MapPointType.mountain,
  },
  '17_26': {
    type: MapPointType.dot,
  },
  '17_27': {
    type: MapPointType.mountain,
  },
  '17_28': {
    type: MapPointType.dot,
  },
  '17_29': {
    type: MapPointType.mountain,
  },
  '17_3': {
    type: MapPointType.mountain,
  },
  '17_30': {
    type: MapPointType.mountain,
  },
  '17_31': {
    type: MapPointType.mountain,
  },
  '17_32': {
    type: MapPointType.mountain,
  },
  '17_33': {
    type: MapPointType.mountain,
  },
  '17_34': {
    type: MapPointType.mountain,
  },
  '17_35': {
    type: MapPointType.dot,
  },
  '17_4': {
    type: MapPointType.mountain,
  },
  '17_5': {
    type: MapPointType.mountain,
  },
  '17_6': {
    type: MapPointType.mountain,
  },
  '17_7': {
    type: MapPointType.mountain,
  },
  '17_8': {
    type: MapPointType.mountain,
  },
  '17_9': {
    type: MapPointType.mountain,
  },
  '18_0': {
    type: MapPointType.dot,
  },
  '18_1': {
    type: MapPointType.dot,
  },
  '18_10': {
    type: MapPointType.dot,
  },
  '18_11': {
    type: MapPointType.dot,
  },
  '18_12': {
    type: MapPointType.dot,
  },
  '18_13': {
    type: MapPointType.mountain,
  },
  '18_14': {
    type: MapPointType.mountain,
  },
  '18_15': {
    type: MapPointType.mountain,
  },
  '18_16': {
    type: MapPointType.mountain,
  },
  '18_17': {
    type: MapPointType.mountain,
  },
  '18_18': {
    type: MapPointType.mountain,
  },
  '18_19': {
    type: MapPointType.dot,
  },
  '18_2': {
    type: MapPointType.dot,
  },
  '18_20': {
    type: MapPointType.mountain,
  },
  '18_21': {
    type: MapPointType.mountain,
  },
  '18_22': {
    type: MapPointType.dot,
  },
  '18_23': {
    type: MapPointType.mountain,
  },
  '18_24': {
    type: MapPointType.dot,
  },
  '18_25': {
    type: MapPointType.mountain,
  },
  '18_26': {
    type: MapPointType.mountain,
  },
  '18_27': {
    type: MapPointType.mountain,
  },
  '18_28': {
    type: MapPointType.dot,
  },
  '18_29': {
    type: MapPointType.dot,
  },
  '18_3': {
    type: MapPointType.dot,
  },
  '18_30': {
    type: MapPointType.mountain,
  },
  '18_31': {
    type: MapPointType.mountain,
  },
  '18_32': {
    type: MapPointType.mountain,
  },
  '18_33': {
    type: MapPointType.mountain,
  },
  '18_34': {
    type: MapPointType.mountain,
  },
  '18_35': {
    type: MapPointType.mountain,
  },
  '18_36': {
    type: MapPointType.dot,
  },
  '18_4': {
    type: MapPointType.mountain,
  },
  '18_5': {
    type: MapPointType.mountain,
  },
  '18_6': {
    type: MapPointType.mountain,
  },
  '18_7': {
    type: MapPointType.mountain,
  },
  '18_8': {
    type: MapPointType.dot,
  },
  '18_9': {
    type: MapPointType.mountain,
  },
  '19_0': {
    type: MapPointType.dot,
  },
  '19_1': {
    type: MapPointType.dot,
  },
  '19_10': {
    type: MapPointType.mountain,
  },
  '19_11': {
    type: MapPointType.mountain,
  },
  '19_12': {
    type: MapPointType.mountain,
  },
  '19_13': {
    type: MapPointType.dot,
  },
  '19_14': {
    type: MapPointType.mountain,
  },
  '19_15': {
    type: MapPointType.dot,
  },
  '19_16': {
    type: MapPointType.dot,
  },
  '19_17': {
    type: MapPointType.dot,
  },
  '19_18': {
    type: MapPointType.mountain,
  },
  '19_19': {
    type: MapPointType.mountain,
  },
  '19_2': {
    type: MapPointType.dot,
  },
  '19_20': {
    type: MapPointType.mountain,
  },
  '19_21': {
    type: MapPointType.mountain,
  },
  '19_22': {
    type: MapPointType.mountain,
  },
  '19_23': {
    type: MapPointType.dot,
  },
  '19_24': {
    type: MapPointType.mountain,
  },
  '19_25': {
    type: MapPointType.mountain,
  },
  '19_26': {
    type: MapPointType.dot,
  },
  '19_27': {
    type: MapPointType.dot,
  },
  '19_28': {
    type: MapPointType.dot,
  },
  '19_29': {
    type: MapPointType.dot,
  },
  '19_3': {
    type: MapPointType.dot,
  },
  '19_30': {
    type: MapPointType.mountain,
  },
  '19_31': {
    type: MapPointType.mountain,
  },
  '19_32': {
    type: MapPointType.mountain,
  },
  '19_33': {
    type: MapPointType.mountain,
  },
  '19_34': {
    type: MapPointType.mountain,
  },
  '19_35': {
    type: MapPointType.mountain,
  },
  '19_36': {
    type: MapPointType.dot,
  },
  '19_37': {
    type: MapPointType.circleCity,
    name: 'Culicán',
  },
  '19_4': {
    type: MapPointType.mountain,
  },
  '19_5': {
    type: MapPointType.dot,
  },
  '19_6': {
    type: MapPointType.dot,
  },
  '19_7': {
    type: MapPointType.dot,
  },
  '19_8': {
    type: MapPointType.dot,
  },
  '19_9': {
    type: MapPointType.mountain,
  },
  '2_10': {
    type: MapPointType.mountain,
  },
  '2_11': {
    type: MapPointType.mountain,
  },
  '2_12': {
    type: MapPointType.mountain,
  },
  '2_13': {
    type: MapPointType.dot,
  },
  '2_14': {
    type: MapPointType.mountain,
  },
  '2_15': {
    type: MapPointType.dot,
  },
  '2_16': {
    type: MapPointType.mountain,
  },
  '2_17': {
    type: MapPointType.mountain,
  },
  '2_18': {
    type: MapPointType.mountain,
  },
  '20_0': {
    type: MapPointType.dot,
  },
  '20_1': {
    type: MapPointType.dot,
  },
  '20_10': {
    type: MapPointType.mountain,
  },
  '20_11': {
    type: MapPointType.mountain,
  },
  '20_12': {
    type: MapPointType.mountain,
  },
  '20_13': {
    type: MapPointType.mountain,
  },
  '20_14': {
    type: MapPointType.mountain,
  },
  '20_15': {
    type: MapPointType.mountain,
  },
  '20_16': {
    type: MapPointType.mountain,
  },
  '20_17': {
    type: MapPointType.mountain,
  },
  '20_18': {
    type: MapPointType.dot,
  },
  '20_19': {
    type: MapPointType.mountain,
  },
  '20_2': {
    type: MapPointType.dot,
  },
  '20_20': {
    type: MapPointType.mountain,
  },
  '20_21': {
    type: MapPointType.mountain,
  },
  '20_22': {
    type: MapPointType.mountain,
  },
  '20_23': {
    type: MapPointType.mountain,
  },
  '20_24': {
    type: MapPointType.dot,
  },
  '20_25': {
    type: MapPointType.mountain,
  },
  '20_26': {
    type: MapPointType.mountain,
  },
  '20_27': {
    type: MapPointType.mountain,
  },
  '20_28': {
    type: MapPointType.dot,
  },
  '20_29': {
    type: MapPointType.circleCity,
    name: 'Juárez',
  },
  '20_3': {
    type: MapPointType.dot,
  },
  '20_30': {
    type: MapPointType.dot,
  },
  '20_31': {
    type: MapPointType.mountain,
  },
  '20_32': {
    type: MapPointType.mountain,
  },
  '20_33': {
    type: MapPointType.mountain,
  },
  '20_34': {
    type: MapPointType.mountain,
  },
  '20_35': {
    type: MapPointType.mountain,
  },
  '20_36': {
    type: MapPointType.mountain,
  },
  '20_37': {
    type: MapPointType.mountain,
  },
  '20_38': {
    type: MapPointType.dot,
  },
  '20_39': {
    type: MapPointType.dot,
  },
  '20_4': {
    type: MapPointType.dot,
  },
  '20_5': {
    type: MapPointType.dot,
  },
  '20_6': {
    type: MapPointType.mountain,
  },
  '20_7': {
    type: MapPointType.mountain,
  },
  '20_8': {
    type: MapPointType.dot,
  },
  '20_9': {
    type: MapPointType.dot,
  },
  '21_0': {
    type: MapPointType.dot,
  },
  '21_1': {
    type: MapPointType.dot,
  },
  '21_10': {
    type: MapPointType.dot,
  },
  '21_11': {
    type: MapPointType.mountain,
  },
  '21_12': {
    type: MapPointType.dot,
  },
  '21_13': {
    type: MapPointType.dot,
  },
  '21_14': {
    type: MapPointType.mountain,
  },
  '21_15': {
    type: MapPointType.mountain,
  },
  '21_16': {
    type: MapPointType.dot,
  },
  '21_17': {
    type: MapPointType.mountain,
  },
  '21_18': {
    type: MapPointType.mountain,
  },
  '21_19': {
    type: MapPointType.dot,
  },
  '21_2': {
    type: MapPointType.dot,
  },
  '21_20': {
    type: MapPointType.mountain,
  },
  '21_21': {
    type: MapPointType.mountain,
  },
  '21_22': {
    type: MapPointType.mountain,
  },
  '21_23': {
    type: MapPointType.circleCity,
    name: 'Satna Fe',
  },
  '21_24': {
    type: MapPointType.mountain,
  },
  '21_25': {
    type: MapPointType.mountain,
  },
  '21_26': {
    type: MapPointType.dot,
  },
  '21_27': {
    type: MapPointType.mountain,
  },
  '21_28': {
    type: MapPointType.dot,
  },
  '21_29': {
    type: MapPointType.dot,
  },
  '21_3': {
    type: MapPointType.dot,
  },
  '21_30': {
    type: MapPointType.dot,
  },
  '21_31': {
    type: MapPointType.dot,
  },
  '21_32': {
    type: MapPointType.circleCity,
    name: 'Chihuahua',
  },
  '21_33': {
    type: MapPointType.dot,
  },
  '21_34': {
    type: MapPointType.dot,
  },
  '21_35': {
    type: MapPointType.mountain,
  },
  '21_36': {
    type: MapPointType.mountain,
  },
  '21_37': {
    type: MapPointType.mountain,
  },
  '21_38': {
    type: MapPointType.mountain,
  },
  '21_39': {
    type: MapPointType.dot,
  },
  '21_4': {
    type: MapPointType.mountain,
  },
  '21_5': {
    type: MapPointType.dot,
  },
  '21_6': {
    type: MapPointType.mountain,
  },
  '21_7': {
    type: MapPointType.dot,
  },
  '21_8': {
    type: MapPointType.dot,
  },
  '21_9': {
    type: MapPointType.circleCity,
    name: 'Billings',
  },
  '22_0': {
    type: MapPointType.dot,
  },
  '22_1': {
    type: MapPointType.dot,
  },
  '22_10': {
    type: MapPointType.dot,
  },
  '22_11': {
    type: MapPointType.dot,
  },
  '22_12': {
    type: MapPointType.mountain,
  },
  '22_13': {
    type: MapPointType.mountain,
  },
  '22_14': {
    type: MapPointType.mountain,
  },
  '22_15': {
    type: MapPointType.mountain,
  },
  '22_16': {
    type: MapPointType.mountain,
  },
  '22_17': {
    type: MapPointType.mountain,
  },
  '22_18': {
    type: MapPointType.mountain,
  },
  '22_19': {
    type: MapPointType.mountain,
  },
  '22_2': {
    type: MapPointType.dot,
  },
  '22_20': {
    type: MapPointType.mountain,
  },
  '22_21': {
    type: MapPointType.mountain,
  },
  '22_22': {
    type: MapPointType.mountain,
  },
  '22_23': {
    type: MapPointType.dot,
  },
  '22_24': {
    type: MapPointType.mountain,
  },
  '22_25': {
    type: MapPointType.mountain,
  },
  '22_26': {
    type: MapPointType.mountain,
  },
  '22_27': {
    type: MapPointType.mountain,
  },
  '22_28': {
    type: MapPointType.dot,
  },
  '22_29': {
    type: MapPointType.mountain,
  },
  '22_3': {
    type: MapPointType.dot,
  },
  '22_30': {
    type: MapPointType.dot,
  },
  '22_31': {
    type: MapPointType.dot,
  },
  '22_32': {
    type: MapPointType.dot,
  },
  '22_33': {
    type: MapPointType.dot,
  },
  '22_34': {
    type: MapPointType.dot,
  },
  '22_35': {
    type: MapPointType.dot,
  },
  '22_36': {
    type: MapPointType.mountain,
  },
  '22_37': {
    type: MapPointType.circleCity,
    name: 'Durango',
  },
  '22_38': {
    type: MapPointType.mountain,
  },
  '22_39': {
    type: MapPointType.mountain,
  },
  '22_4': {
    type: MapPointType.mountain,
  },
  '22_40': {
    type: MapPointType.mountain,
  },
  '22_41': {
    type: MapPointType.dot,
  },
  '22_44': {
    type: MapPointType.mountain,
  },
  '22_5': {
    type: MapPointType.mountain,
  },
  '22_6': {
    type: MapPointType.dot,
  },
  '22_7': {
    type: MapPointType.dot,
  },
  '22_8': {
    type: MapPointType.dot,
  },
  '22_9': {
    type: MapPointType.dot,
  },
  '23_0': {
    type: MapPointType.dot,
  },
  '23_1': {
    type: MapPointType.dot,
  },
  '23_10': {
    type: MapPointType.mountain,
  },
  '23_11': {
    type: MapPointType.mountain,
  },
  '23_12': {
    type: MapPointType.dot,
  },
  '23_13': {
    type: MapPointType.dot,
  },
  '23_14': {
    type: MapPointType.circleCity,
    name: 'Wyoming',
  },
  '23_15': {
    type: MapPointType.dot,
  },
  '23_16': {
    type: MapPointType.squareCity,
    name: 'Denver',
  },
  '23_17': {
    type: MapPointType.dot,
  },
  '23_18': {
    type: MapPointType.dot,
  },
  '23_19': {
    type: MapPointType.dot,
  },
  '23_2': {
    type: MapPointType.dot,
  },
  '23_20': {
    type: MapPointType.mountain,
  },
  '23_21': {
    type: MapPointType.mountain,
  },
  '23_22': {
    type: MapPointType.mountain,
  },
  '23_23': {
    type: MapPointType.dot,
  },
  '23_24': {
    type: MapPointType.mountain,
  },
  '23_25': {
    type: MapPointType.dot,
  },
  '23_26': {
    type: MapPointType.mountain,
  },
  '23_27': {
    type: MapPointType.dot,
  },
  '23_28': {
    type: MapPointType.mountain,
  },
  '23_29': {
    type: MapPointType.mountain,
  },
  '23_3': {
    type: MapPointType.dot,
  },
  '23_30': {
    type: MapPointType.mountain,
  },
  '23_31': {
    type: MapPointType.dot,
  },
  '23_32': {
    type: MapPointType.dot,
  },
  '23_33': {
    type: MapPointType.dot,
  },
  '23_34': {
    type: MapPointType.dot,
  },
  '23_35': {
    type: MapPointType.dot,
  },
  '23_36': {
    type: MapPointType.mountain,
  },
  '23_37': {
    type: MapPointType.mountain,
  },
  '23_38': {
    type: MapPointType.mountain,
  },
  '23_39': {
    type: MapPointType.mountain,
  },
  '23_4': {
    type: MapPointType.mountain,
  },
  '23_40': {
    type: MapPointType.mountain,
  },
  '23_41': {
    type: MapPointType.dot,
  },
  '23_42': {
    type: MapPointType.dot,
  },
  '23_43': {
    type: MapPointType.dot,
  },
  '23_44': {
    type: MapPointType.mountain,
  },
  '23_5': {
    type: MapPointType.dot,
  },
  '23_6': {
    type: MapPointType.dot,
  },
  '23_7': {
    type: MapPointType.dot,
  },
  '23_8': {
    type: MapPointType.dot,
  },
  '23_9': {
    type: MapPointType.dot,
  },
  '24_0': {
    type: MapPointType.dot,
  },
  '24_1': {
    type: MapPointType.dot,
  },
  '24_10': {
    type: MapPointType.dot,
  },
  '24_11': {
    type: MapPointType.dot,
  },
  '24_12': {
    type: MapPointType.dot,
  },
  '24_13': {
    type: MapPointType.mountain,
  },
  '24_14': {
    type: MapPointType.dot,
  },
  '24_15': {
    type: MapPointType.dot,
  },
  '24_16': {
    type: MapPointType.dot,
  },
  '24_17': {
    type: MapPointType.dot,
  },
  '24_18': {
    type: MapPointType.dot,
  },
  '24_19': {
    type: MapPointType.dot,
  },
  '24_2': {
    type: MapPointType.dot,
  },
  '24_20': {
    type: MapPointType.dot,
  },
  '24_21': {
    type: MapPointType.dot,
  },
  '24_22': {
    type: MapPointType.dot,
  },
  '24_23': {
    type: MapPointType.dot,
  },
  '24_24': {
    type: MapPointType.dot,
  },
  '24_25': {
    type: MapPointType.dot,
  },
  '24_26': {
    type: MapPointType.dot,
  },
  '24_27': {
    type: MapPointType.mountain,
  },
  '24_28': {
    type: MapPointType.mountain,
  },
  '24_29': {
    type: MapPointType.dot,
  },
  '24_3': {
    type: MapPointType.circleCity,
    name: 'Regina',
  },
  '24_30': {
    type: MapPointType.mountain,
  },
  '24_31': {
    type: MapPointType.mountain,
  },
  '24_32': {
    type: MapPointType.dot,
  },
  '24_33': {
    type: MapPointType.dot,
  },
  '24_34': {
    type: MapPointType.dot,
  },
  '24_35': {
    type: MapPointType.dot,
  },
  '24_36': {
    type: MapPointType.dot,
  },
  '24_37': {
    type: MapPointType.mountain,
  },
  '24_38': {
    type: MapPointType.mountain,
  },
  '24_39': {
    type: MapPointType.mountain,
  },
  '24_4': {
    type: MapPointType.dot,
  },
  '24_40': {
    type: MapPointType.mountain,
  },
  '24_41': {
    type: MapPointType.mountain,
  },
  '24_42': {
    type: MapPointType.mountain,
  },
  '24_43': {
    type: MapPointType.squareCity,
    name: 'Guadalajara',
  },
  '24_44': {
    type: MapPointType.mountain,
  },
  '24_45': {
    type: MapPointType.dot,
  },
  '24_5': {
    type: MapPointType.dot,
  },
  '24_6': {
    type: MapPointType.dot,
  },
  '24_7': {
    type: MapPointType.dot,
  },
  '24_8': {
    type: MapPointType.dot,
  },
  '24_9': {
    type: MapPointType.dot,
  },
  '25_0': {
    type: MapPointType.dot,
  },
  '25_1': {
    type: MapPointType.dot,
  },
  '25_10': {
    type: MapPointType.dot,
  },
  '25_11': {
    type: MapPointType.dot,
  },
  '25_12': {
    type: MapPointType.dot,
  },
  '25_13': {
    type: MapPointType.dot,
  },
  '25_14': {
    type: MapPointType.dot,
  },
  '25_15': {
    type: MapPointType.dot,
  },
  '25_16': {
    type: MapPointType.dot,
  },
  '25_17': {
    type: MapPointType.dot,
  },
  '25_18': {
    type: MapPointType.dot,
  },
  '25_19': {
    type: MapPointType.dot,
  },
  '25_2': {
    type: MapPointType.dot,
  },
  '25_20': {
    type: MapPointType.dot,
  },
  '25_21': {
    type: MapPointType.dot,
  },
  '25_22': {
    type: MapPointType.dot,
  },
  '25_23': {
    type: MapPointType.dot,
  },
  '25_24': {
    type: MapPointType.dot,
  },
  '25_25': {
    type: MapPointType.dot,
  },
  '25_26': {
    type: MapPointType.dot,
  },
  '25_27': {
    type: MapPointType.mountain,
  },
  '25_28': {
    type: MapPointType.mountain,
  },
  '25_29': {
    type: MapPointType.mountain,
  },
  '25_3': {
    type: MapPointType.dot,
  },
  '25_30': {
    type: MapPointType.dot,
  },
  '25_31': {
    type: MapPointType.dot,
  },
  '25_32': {
    type: MapPointType.dot,
  },
  '25_33': {
    type: MapPointType.dot,
  },
  '25_34': {
    type: MapPointType.dot,
  },
  '25_35': {
    type: MapPointType.circleCity,
    name: 'Torreón',
  },
  '25_36': {
    type: MapPointType.dot,
  },
  '25_37': {
    type: MapPointType.dot,
  },
  '25_38': {
    type: MapPointType.mountain,
  },
  '25_39': {
    type: MapPointType.mountain,
  },
  '25_4': {
    type: MapPointType.dot,
  },
  '25_40': {
    type: MapPointType.mountain,
  },
  '25_41': {
    type: MapPointType.mountain,
  },
  '25_42': {
    type: MapPointType.dot,
  },
  '25_43': {
    type: MapPointType.mountain,
  },
  '25_44': {
    type: MapPointType.dot,
  },
  '25_45': {
    type: MapPointType.mountain,
  },
  '25_5': {
    type: MapPointType.dot,
  },
  '25_6': {
    type: MapPointType.dot,
  },
  '25_7': {
    type: MapPointType.mountain,
  },
  '25_8': {
    type: MapPointType.dot,
  },
  '25_9': {
    type: MapPointType.dot,
  },
  '26_0': {
    type: MapPointType.dot,
  },
  '26_1': {
    type: MapPointType.dot,
  },
  '26_10': {
    type: MapPointType.dot,
  },
  '26_11': {
    type: MapPointType.dot,
  },
  '26_12': {
    type: MapPointType.dot,
  },
  '26_13': {
    type: MapPointType.dot,
  },
  '26_14': {
    type: MapPointType.dot,
  },
  '26_15': {
    type: MapPointType.dot,
  },
  '26_16': {
    type: MapPointType.dot,
  },
  '26_17': {
    type: MapPointType.dot,
  },
  '26_18': {
    type: MapPointType.dot,
  },
  '26_19': {
    type: MapPointType.dot,
  },
  '26_2': {
    type: MapPointType.dot,
  },
  '26_20': {
    type: MapPointType.dot,
  },
  '26_21': {
    type: MapPointType.dot,
  },
  '26_22': {
    type: MapPointType.dot,
  },
  '26_23': {
    type: MapPointType.dot,
  },
  '26_24': {
    type: MapPointType.dot,
  },
  '26_25': {
    type: MapPointType.dot,
  },
  '26_26': {
    type: MapPointType.dot,
  },
  '26_27': {
    type: MapPointType.dot,
  },
  '26_28': {
    type: MapPointType.dot,
  },
  '26_29': {
    type: MapPointType.mountain,
  },
  '26_3': {
    type: MapPointType.dot,
  },
  '26_30': {
    type: MapPointType.dot,
  },
  '26_31': {
    type: MapPointType.dot,
  },
  '26_32': {
    type: MapPointType.mountain,
  },
  '26_33': {
    type: MapPointType.dot,
  },
  '26_34': {
    type: MapPointType.dot,
  },
  '26_35': {
    type: MapPointType.dot,
  },
  '26_36': {
    type: MapPointType.dot,
  },
  '26_37': {
    type: MapPointType.dot,
  },
  '26_38': {
    type: MapPointType.dot,
  },
  '26_39': {
    type: MapPointType.mountain,
  },
  '26_4': {
    type: MapPointType.dot,
  },
  '26_40': {
    type: MapPointType.mountain,
  },
  '26_41': {
    type: MapPointType.mountain,
  },
  '26_42': {
    type: MapPointType.mountain,
  },
  '26_43': {
    type: MapPointType.dot,
  },
  '26_44': {
    type: MapPointType.mountain,
  },
  '26_45': {
    type: MapPointType.mountain,
  },
  '26_46': {
    type: MapPointType.mountain,
  },
  '26_5': {
    type: MapPointType.dot,
  },
  '26_6': {
    type: MapPointType.dot,
  },
  '26_7': {
    type: MapPointType.dot,
  },
  '26_8': {
    type: MapPointType.dot,
  },
  '26_9': {
    type: MapPointType.dot,
  },
  '27_0': {
    type: MapPointType.dot,
  },
  '27_1': {
    type: MapPointType.dot,
  },
  '27_10': {
    type: MapPointType.dot,
  },
  '27_11': {
    type: MapPointType.dot,
  },
  '27_12': {
    type: MapPointType.dot,
  },
  '27_13': {
    type: MapPointType.dot,
  },
  '27_14': {
    type: MapPointType.dot,
  },
  '27_15': {
    type: MapPointType.dot,
  },
  '27_16': {
    type: MapPointType.dot,
  },
  '27_17': {
    type: MapPointType.dot,
  },
  '27_18': {
    type: MapPointType.dot,
  },
  '27_19': {
    type: MapPointType.dot,
  },
  '27_2': {
    type: MapPointType.dot,
  },
  '27_20': {
    type: MapPointType.dot,
  },
  '27_21': {
    type: MapPointType.dot,
  },
  '27_22': {
    type: MapPointType.dot,
  },
  '27_23': {
    type: MapPointType.dot,
  },
  '27_24': {
    type: MapPointType.dot,
  },
  '27_25': {
    type: MapPointType.dot,
  },
  '27_26': {
    type: MapPointType.dot,
  },
  '27_27': {
    type: MapPointType.dot,
  },
  '27_28': {
    type: MapPointType.dot,
  },
  '27_29': {
    type: MapPointType.dot,
  },
  '27_3': {
    type: MapPointType.dot,
  },
  '27_30': {
    type: MapPointType.dot,
  },
  '27_31': {
    type: MapPointType.dot,
  },
  '27_32': {
    type: MapPointType.dot,
  },
  '27_33': {
    type: MapPointType.mountain,
  },
  '27_34': {
    type: MapPointType.dot,
  },
  '27_35': {
    type: MapPointType.dot,
  },
  '27_36': {
    type: MapPointType.dot,
  },
  '27_37': {
    type: MapPointType.dot,
  },
  '27_38': {
    type: MapPointType.dot,
  },
  '27_39': {
    type: MapPointType.dot,
  },
  '27_4': {
    type: MapPointType.dot,
  },
  '27_40': {
    type: MapPointType.dot,
  },
  '27_41': {
    type: MapPointType.mountain,
  },
  '27_42': {
    type: MapPointType.mountain,
  },
  '27_43': {
    type: MapPointType.dot,
  },
  '27_44': {
    type: MapPointType.mountain,
  },
  '27_45': {
    type: MapPointType.dot,
  },
  '27_5': {
    type: MapPointType.dot,
  },
  '27_6': {
    type: MapPointType.dot,
  },
  '27_7': {
    type: MapPointType.dot,
  },
  '27_8': {
    type: MapPointType.dot,
  },
  '27_9': {
    type: MapPointType.dot,
  },
  '28_0': {
    type: MapPointType.dot,
  },
  '28_1': {
    type: MapPointType.dot,
  },
  '28_10': {
    type: MapPointType.dot,
  },
  '28_11': {
    type: MapPointType.dot,
  },
  '28_12': {
    type: MapPointType.dot,
  },
  '28_13': {
    type: MapPointType.dot,
  },
  '28_14': {
    type: MapPointType.dot,
  },
  '28_15': {
    type: MapPointType.dot,
  },
  '28_16': {
    type: MapPointType.dot,
  },
  '28_17': {
    type: MapPointType.dot,
  },
  '28_18': {
    type: MapPointType.dot,
  },
  '28_19': {
    type: MapPointType.dot,
  },
  '28_2': {
    type: MapPointType.dot,
  },
  '28_20': {
    type: MapPointType.dot,
  },
  '28_21': {
    type: MapPointType.dot,
  },
  '28_22': {
    type: MapPointType.dot,
  },
  '28_23': {
    type: MapPointType.dot,
  },
  '28_24': {
    type: MapPointType.dot,
  },
  '28_25': {
    type: MapPointType.dot,
  },
  '28_26': {
    type: MapPointType.dot,
  },
  '28_27': {
    type: MapPointType.dot,
  },
  '28_28': {
    type: MapPointType.dot,
  },
  '28_29': {
    type: MapPointType.dot,
  },
  '28_3': {
    type: MapPointType.dot,
  },
  '28_30': {
    type: MapPointType.dot,
  },
  '28_31': {
    type: MapPointType.dot,
  },
  '28_32': {
    type: MapPointType.dot,
  },
  '28_33': {
    type: MapPointType.dot,
  },
  '28_34': {
    type: MapPointType.dot,
  },
  '28_35': {
    type: MapPointType.dot,
  },
  '28_36': {
    type: MapPointType.mountain,
  },
  '28_37': {
    type: MapPointType.mountain,
  },
  '28_38': {
    type: MapPointType.dot,
  },
  '28_39': {
    type: MapPointType.dot,
  },
  '28_4': {
    type: MapPointType.dot,
  },
  '28_40': {
    type: MapPointType.dot,
  },
  '28_41': {
    type: MapPointType.dot,
  },
  '28_42': {
    type: MapPointType.mountain,
  },
  '28_43': {
    type: MapPointType.mountain,
  },
  '28_44': {
    type: MapPointType.mountain,
  },
  '28_45': {
    type: MapPointType.mountain,
  },
  '28_46': {
    type: MapPointType.mountain,
  },
  '28_5': {
    type: MapPointType.dot,
  },
  '28_6': {
    type: MapPointType.dot,
  },
  '28_7': {
    type: MapPointType.dot,
  },
  '28_8': {
    type: MapPointType.dot,
  },
  '28_9': {
    type: MapPointType.dot,
  },
  '29_1': {
    type: MapPointType.dot,
  },
  '29_10': {
    type: MapPointType.dot,
  },
  '29_11': {
    type: MapPointType.dot,
  },
  '29_12': {
    type: MapPointType.dot,
  },
  '29_13': {
    type: MapPointType.dot,
  },
  '29_14': {
    type: MapPointType.dot,
  },
  '29_15': {
    type: MapPointType.dot,
  },
  '29_16': {
    type: MapPointType.dot,
  },
  '29_17': {
    type: MapPointType.dot,
  },
  '29_18': {
    type: MapPointType.dot,
  },
  '29_19': {
    type: MapPointType.dot,
  },
  '29_2': {
    type: MapPointType.dot,
  },
  '29_20': {
    type: MapPointType.dot,
  },
  '29_21': {
    type: MapPointType.dot,
  },
  '29_22': {
    type: MapPointType.dot,
  },
  '29_23': {
    type: MapPointType.dot,
  },
  '29_24': {
    type: MapPointType.dot,
  },
  '29_25': {
    type: MapPointType.dot,
  },
  '29_26': {
    type: MapPointType.dot,
  },
  '29_27': {
    type: MapPointType.dot,
  },
  '29_28': {
    type: MapPointType.dot,
  },
  '29_29': {
    type: MapPointType.dot,
  },
  '29_3': {
    type: MapPointType.dot,
  },
  '29_30': {
    type: MapPointType.dot,
  },
  '29_31': {
    type: MapPointType.dot,
  },
  '29_32': {
    type: MapPointType.dot,
  },
  '29_33': {
    type: MapPointType.dot,
  },
  '29_34': {
    type: MapPointType.dot,
  },
  '29_35': {
    type: MapPointType.squareCity,
    name: 'Monterrey',
  },
  '29_36': {
    type: MapPointType.mountain,
  },
  '29_37': {
    type: MapPointType.mountain,
  },
  '29_38': {
    type: MapPointType.mountain,
  },
  '29_39': {
    type: MapPointType.mountain,
  },
  '29_4': {
    type: MapPointType.dot,
  },
  '29_40': {
    type: MapPointType.dot,
  },
  '29_41': {
    type: MapPointType.dot,
  },
  '29_42': {
    type: MapPointType.dot,
  },
  '29_43': {
    type: MapPointType.dot,
  },
  '29_44': {
    type: MapPointType.mountain,
  },
  '29_45': {
    type: MapPointType.mountain,
  },
  '29_46': {
    type: MapPointType.mountain,
  },
  '29_5': {
    type: MapPointType.dot,
  },
  '29_6': {
    type: MapPointType.dot,
  },
  '29_7': {
    type: MapPointType.dot,
  },
  '29_8': {
    type: MapPointType.dot,
  },
  '29_9': {
    type: MapPointType.dot,
  },
  '3_10': {
    type: MapPointType.dot,
  },
  '3_11': {
    type: MapPointType.mountain,
  },
  '3_12': {
    type: MapPointType.mountain,
  },
  '3_13': {
    type: MapPointType.mountain,
  },
  '3_14': {
    type: MapPointType.mountain,
  },
  '3_15': {
    type: MapPointType.mountain,
  },
  '3_16': {
    type: MapPointType.dot,
  },
  '3_17': {
    type: MapPointType.squareCity,
    name: 'San Fransisco',
  },
  '3_18': {
    type: MapPointType.mountain,
  },
  '3_19': {
    type: MapPointType.mountain,
  },
  '3_20': {
    type: MapPointType.dot,
  },
  '3_21': {
    type: MapPointType.mountain,
  },
  '3_8': {
    type: MapPointType.dot,
  },
  '3_9': {
    type: MapPointType.mountain,
  },
  '30_10': {
    type: MapPointType.dot,
  },
  '30_11': {
    type: MapPointType.dot,
  },
  '30_12': {
    type: MapPointType.dot,
  },
  '30_13': {
    type: MapPointType.dot,
  },
  '30_14': {
    type: MapPointType.dot,
  },
  '30_15': {
    type: MapPointType.dot,
  },
  '30_16': {
    type: MapPointType.dot,
  },
  '30_17': {
    type: MapPointType.dot,
  },
  '30_18': {
    type: MapPointType.dot,
  },
  '30_19': {
    type: MapPointType.dot,
  },
  '30_20': {
    type: MapPointType.dot,
  },
  '30_21': {
    type: MapPointType.dot,
  },
  '30_22': {
    type: MapPointType.dot,
  },
  '30_23': {
    type: MapPointType.dot,
  },
  '30_24': {
    type: MapPointType.dot,
  },
  '30_25': {
    type: MapPointType.dot,
  },
  '30_26': {
    type: MapPointType.dot,
  },
  '30_27': {
    type: MapPointType.dot,
  },
  '30_28': {
    type: MapPointType.dot,
  },
  '30_29': {
    type: MapPointType.dot,
  },
  '30_3': {
    type: MapPointType.dot,
  },
  '30_30': {
    type: MapPointType.dot,
  },
  '30_31': {
    type: MapPointType.dot,
  },
  '30_32': {
    type: MapPointType.dot,
  },
  '30_33': {
    type: MapPointType.dot,
  },
  '30_34': {
    type: MapPointType.dot,
  },
  '30_35': {
    type: MapPointType.dot,
  },
  '30_36': {
    type: MapPointType.dot,
  },
  '30_37': {
    type: MapPointType.mountain,
  },
  '30_38': {
    type: MapPointType.mountain,
  },
  '30_39': {
    type: MapPointType.mountain,
  },
  '30_4': {
    type: MapPointType.dot,
  },
  '30_40': {
    type: MapPointType.mountain,
  },
  '30_41': {
    type: MapPointType.mountain,
  },
  '30_42': {
    type: MapPointType.dot,
  },
  '30_43': {
    type: MapPointType.dot,
  },
  '30_44': {
    type: MapPointType.dot,
  },
  '30_45': {
    type: MapPointType.dot,
  },
  '30_46': {
    type: MapPointType.mountain,
  },
  '30_47': {
    type: MapPointType.mountain,
  },
  '30_5': {
    type: MapPointType.dot,
  },
  '30_6': {
    type: MapPointType.dot,
  },
  '30_7': {
    type: MapPointType.dot,
  },
  '30_8': {
    type: MapPointType.dot,
  },
  '30_9': {
    type: MapPointType.dot,
  },
  '31_10': {
    type: MapPointType.dot,
  },
  '31_11': {
    type: MapPointType.dot,
  },
  '31_12': {
    type: MapPointType.dot,
  },
  '31_13': {
    type: MapPointType.dot,
  },
  '31_14': {
    type: MapPointType.dot,
  },
  '31_15': {
    type: MapPointType.dot,
  },
  '31_16': {
    type: MapPointType.dot,
  },
  '31_17': {
    type: MapPointType.dot,
  },
  '31_18': {
    type: MapPointType.dot,
  },
  '31_19': {
    type: MapPointType.dot,
  },
  '31_2': {
    type: MapPointType.dot,
  },
  '31_20': {
    type: MapPointType.dot,
  },
  '31_21': {
    type: MapPointType.dot,
  },
  '31_22': {
    type: MapPointType.dot,
  },
  '31_23': {
    type: MapPointType.dot,
  },
  '31_24': {
    type: MapPointType.dot,
  },
  '31_25': {
    type: MapPointType.dot,
  },
  '31_26': {
    type: MapPointType.dot,
  },
  '31_27': {
    type: MapPointType.dot,
  },
  '31_28': {
    type: MapPointType.dot,
  },
  '31_29': {
    type: MapPointType.dot,
  },
  '31_3': {
    type: MapPointType.dot,
  },
  '31_30': {
    type: MapPointType.dot,
  },
  '31_31': {
    type: MapPointType.dot,
  },
  '31_32': {
    type: MapPointType.dot,
  },
  '31_33': {
    type: MapPointType.dot,
  },
  '31_34': {
    type: MapPointType.dot,
  },
  '31_35': {
    type: MapPointType.dot,
  },
  '31_36': {
    type: MapPointType.dot,
  },
  '31_37': {
    type: MapPointType.dot,
  },
  '31_38': {
    type: MapPointType.mountain,
  },
  '31_39': {
    type: MapPointType.mountain,
  },
  '31_4': {
    type: MapPointType.circleCity,
    name: 'Winnipeg',
  },
  '31_40': {
    type: MapPointType.mountain,
  },
  '31_41': {
    type: MapPointType.mountain,
  },
  '31_42': {
    type: MapPointType.mountain,
  },
  '31_43': {
    type: MapPointType.dot,
  },
  '31_44': {
    type: MapPointType.hexCity,
    name: 'Mexico\nCity',
  },
  '31_45': {
    type: MapPointType.dot,
  },
  '31_46': {
    type: MapPointType.mountain,
  },
  '31_47': {
    type: MapPointType.mountain,
  },
  '31_5': {
    type: MapPointType.dot,
  },
  '31_6': {
    type: MapPointType.dot,
  },
  '31_7': {
    type: MapPointType.dot,
  },
  '31_8': {
    type: MapPointType.dot,
  },
  '31_9': {
    type: MapPointType.dot,
  },
  '32_1': {
    type: MapPointType.dot,
  },
  '32_10': {
    type: MapPointType.dot,
  },
  '32_11': {
    type: MapPointType.dot,
  },
  '32_12': {
    type: MapPointType.dot,
  },
  '32_13': {
    type: MapPointType.dot,
  },
  '32_14': {
    type: MapPointType.dot,
  },
  '32_15': {
    type: MapPointType.dot,
  },
  '32_16': {
    type: MapPointType.dot,
  },
  '32_17': {
    type: MapPointType.dot,
  },
  '32_18': {
    type: MapPointType.dot,
  },
  '32_19': {
    type: MapPointType.dot,
  },
  '32_2': {
    type: MapPointType.dot,
  },
  '32_20': {
    type: MapPointType.dot,
  },
  '32_21': {
    type: MapPointType.dot,
  },
  '32_22': {
    type: MapPointType.dot,
  },
  '32_23': {
    type: MapPointType.dot,
  },
  '32_24': {
    type: MapPointType.circleCity,
    name: 'Oklahoma City',
  },
  '32_25': {
    type: MapPointType.dot,
  },
  '32_26': {
    type: MapPointType.dot,
  },
  '32_27': {
    type: MapPointType.dot,
  },
  '32_28': {
    type: MapPointType.dot,
  },
  '32_29': {
    type: MapPointType.dot,
  },
  '32_30': {
    type: MapPointType.dot,
  },
  '32_31': {
    type: MapPointType.dot,
  },
  '32_32': {
    type: MapPointType.dot,
  },
  '32_33': {
    type: MapPointType.dot,
  },
  '32_34': {
    type: MapPointType.dot,
  },
  '32_35': {
    type: MapPointType.dot,
  },
  '32_36': {
    type: MapPointType.dot,
  },
  '32_37': {
    type: MapPointType.dot,
  },
  '32_38': {
    type: MapPointType.dot,
  },
  '32_39': {
    type: MapPointType.dot,
  },
  '32_4': {
    type: MapPointType.dot,
  },
  '32_40': {
    type: MapPointType.circleCity,
    name: 'Tampico',
  },
  '32_41': {
    type: MapPointType.dot,
  },
  '32_42': {
    type: MapPointType.dot,
  },
  '32_43': {
    type: MapPointType.mountain,
  },
  '32_44': {
    type: MapPointType.dot,
  },
  '32_45': {
    type: MapPointType.dot,
  },
  '32_46': {
    type: MapPointType.mountain,
  },
  '32_47': {
    type: MapPointType.mountain,
  },
  '32_5': {
    type: MapPointType.dot,
  },
  '32_6': {
    type: MapPointType.dot,
  },
  '32_7': {
    type: MapPointType.dot,
  },
  '32_8': {
    type: MapPointType.circleCity,
    name: 'Fargo',
  },
  '32_9': {
    type: MapPointType.dot,
  },
  '33_0': {
    type: MapPointType.dot,
  },
  '33_1': {
    type: MapPointType.dot,
  },
  '33_10': {
    type: MapPointType.dot,
  },
  '33_11': {
    type: MapPointType.dot,
  },
  '33_12': {
    type: MapPointType.dot,
  },
  '33_13': {
    type: MapPointType.dot,
  },
  '33_14': {
    type: MapPointType.dot,
  },
  '33_15': {
    type: MapPointType.circleCity,
    name: 'Omaha',
  },
  '33_16': {
    type: MapPointType.dot,
  },
  '33_17': {
    type: MapPointType.dot,
  },
  '33_18': {
    type: MapPointType.dot,
  },
  '33_19': {
    type: MapPointType.dot,
  },
  '33_2': {
    type: MapPointType.dot,
  },
  '33_20': {
    type: MapPointType.dot,
  },
  '33_21': {
    type: MapPointType.dot,
  },
  '33_22': {
    type: MapPointType.dot,
  },
  '33_23': {
    type: MapPointType.dot,
  },
  '33_24': {
    type: MapPointType.dot,
  },
  '33_25': {
    type: MapPointType.dot,
  },
  '33_26': {
    type: MapPointType.dot,
  },
  '33_27': {
    type: MapPointType.squareCity,
    name: 'Dallas',
  },
  '33_28': {
    type: MapPointType.dot,
  },
  '33_29': {
    type: MapPointType.dot,
  },
  '33_3': {
    type: MapPointType.dot,
  },
  '33_30': {
    type: MapPointType.dot,
  },
  '33_31': {
    type: MapPointType.dot,
  },
  '33_32': {
    type: MapPointType.dot,
  },
  '33_4': {
    type: MapPointType.dot,
  },
  '33_41': {
    type: MapPointType.dot,
  },
  '33_42': {
    type: MapPointType.dot,
  },
  '33_43': {
    type: MapPointType.mountain,
  },
  '33_44': {
    type: MapPointType.mountain,
  },
  '33_45': {
    type: MapPointType.mountain,
  },
  '33_46': {
    type: MapPointType.mountain,
  },
  '33_47': {
    type: MapPointType.mountain,
  },
  '33_5': {
    type: MapPointType.dot,
  },
  '33_6': {
    type: MapPointType.dot,
  },
  '33_7': {
    type: MapPointType.dot,
  },
  '33_8': {
    type: MapPointType.dot,
  },
  '33_9': {
    type: MapPointType.dot,
  },
  '34_1': {
    type: MapPointType.dot,
  },
  '34_10': {
    type: MapPointType.dot,
  },
  '34_11': {
    type: MapPointType.dot,
  },
  '34_12': {
    type: MapPointType.dot,
  },
  '34_13': {
    type: MapPointType.dot,
  },
  '34_14': {
    type: MapPointType.dot,
  },
  '34_15': {
    type: MapPointType.dot,
  },
  '34_16': {
    type: MapPointType.dot,
  },
  '34_17': {
    type: MapPointType.dot,
  },
  '34_18': {
    type: MapPointType.dot,
  },
  '34_19': {
    type: MapPointType.dot,
  },
  '34_2': {
    type: MapPointType.dot,
  },
  '34_20': {
    type: MapPointType.dot,
  },
  '34_21': {
    type: MapPointType.dot,
  },
  '34_22': {
    type: MapPointType.dot,
  },
  '34_23': {
    type: MapPointType.dot,
  },
  '34_24': {
    type: MapPointType.dot,
  },
  '34_25': {
    type: MapPointType.dot,
  },
  '34_26': {
    type: MapPointType.dot,
  },
  '34_27': {
    type: MapPointType.dot,
  },
  '34_28': {
    type: MapPointType.dot,
  },
  '34_29': {
    type: MapPointType.dot,
  },
  '34_3': {
    type: MapPointType.dot,
  },
  '34_30': {
    type: MapPointType.dot,
  },
  '34_31': {
    type: MapPointType.dot,
  },
  '34_4': {
    type: MapPointType.dot,
  },
  '34_43': {
    type: MapPointType.mountain,
  },
  '34_44': {
    type: MapPointType.mountain,
  },
  '34_45': {
    type: MapPointType.dot,
  },
  '34_46': {
    type: MapPointType.mountain,
  },
  '34_47': {
    type: MapPointType.mountain,
  },
  '34_48': {
    type: MapPointType.mountain,
  },
  '34_5': {
    type: MapPointType.dot,
  },
  '34_6': {
    type: MapPointType.dot,
  },
  '34_7': {
    type: MapPointType.dot,
  },
  '34_8': {
    type: MapPointType.dot,
  },
  '34_9': {
    type: MapPointType.dot,
  },
  '35_0': {
    type: MapPointType.dot,
  },
  '35_1': {
    type: MapPointType.dot,
  },
  '35_10': {
    type: MapPointType.dot,
  },
  '35_11': {
    type: MapPointType.dot,
  },
  '35_12': {
    type: MapPointType.dot,
  },
  '35_13': {
    type: MapPointType.dot,
  },
  '35_14': {
    type: MapPointType.dot,
  },
  '35_15': {
    type: MapPointType.dot,
  },
  '35_16': {
    type: MapPointType.dot,
  },
  '35_17': {
    type: MapPointType.dot,
  },
  '35_18': {
    type: MapPointType.hexCity,
    name: 'Kansas\nCity',
  },
  '35_19': {
    type: MapPointType.dot,
  },
  '35_2': {
    type: MapPointType.dot,
  },
  '35_20': {
    type: MapPointType.dot,
  },
  '35_21': {
    type: MapPointType.mountain,
  },
  '35_22': {
    type: MapPointType.mountain,
  },
  '35_23': {
    type: MapPointType.mountain,
  },
  '35_24': {
    type: MapPointType.dot,
  },
  '35_25': {
    type: MapPointType.dot,
  },
  '35_26': {
    type: MapPointType.dot,
  },
  '35_27': {
    type: MapPointType.dot,
  },
  '35_28': {
    type: MapPointType.dot,
  },
  '35_29': {
    type: MapPointType.dot,
  },
  '35_3': {
    type: MapPointType.dot,
  },
  '35_30': {
    type: MapPointType.squareCity,
    name: 'Houston',
  },
  '35_31': {
    type: MapPointType.dot,
  },
  '35_4': {
    type: MapPointType.dot,
  },
  '35_44': {
    type: MapPointType.circleCity,
    name: 'Veracruz',
  },
  '35_45': {
    type: MapPointType.dot,
  },
  '35_46': {
    type: MapPointType.dot,
  },
  '35_47': {
    type: MapPointType.dot,
  },
  '35_48': {
    type: MapPointType.dot,
  },
  '35_5': {
    type: MapPointType.dot,
  },
  '35_6': {
    type: MapPointType.mountain,
  },
  '35_7': {
    type: MapPointType.dot,
  },
  '35_8': {
    type: MapPointType.dot,
  },
  '35_9': {
    type: MapPointType.dot,
  },
  '36_1': {
    type: MapPointType.dot,
  },
  '36_10': {
    type: MapPointType.dot,
  },
  '36_11': {
    type: MapPointType.squareCity,
    name: 'Minneapolis',
  },
  '36_12': {
    type: MapPointType.dot,
  },
  '36_13': {
    type: MapPointType.dot,
  },
  '36_14': {
    type: MapPointType.dot,
  },
  '36_15': {
    type: MapPointType.circleCity,
    name: 'Des Moines',
  },
  '36_16': {
    type: MapPointType.dot,
  },
  '36_17': {
    type: MapPointType.dot,
  },
  '36_18': {
    type: MapPointType.dot,
  },
  '36_19': {
    type: MapPointType.dot,
  },
  '36_2': {
    type: MapPointType.dot,
  },
  '36_20': {
    type: MapPointType.dot,
  },
  '36_21': {
    type: MapPointType.mountain,
  },
  '36_22': {
    type: MapPointType.mountain,
  },
  '36_23': {
    type: MapPointType.mountain,
  },
  '36_24': {
    type: MapPointType.mountain,
  },
  '36_25': {
    type: MapPointType.dot,
  },
  '36_26': {
    type: MapPointType.dot,
  },
  '36_27': {
    type: MapPointType.dot,
  },
  '36_28': {
    type: MapPointType.dot,
  },
  '36_29': {
    type: MapPointType.dot,
  },
  '36_3': {
    type: MapPointType.dot,
  },
  '36_30': {
    type: MapPointType.dot,
  },
  '36_4': {
    type: MapPointType.dot,
  },
  '36_46': {
    type: MapPointType.dot,
  },
  '36_47': {
    type: MapPointType.mountain,
  },
  '36_48': {
    type: MapPointType.mountain,
  },
  '36_5': {
    type: MapPointType.dot,
  },
  '36_6': {
    type: MapPointType.mountain,
  },
  '36_7': {
    type: MapPointType.mountain,
  },
  '36_8': {
    type: MapPointType.dot,
  },
  '36_9': {
    type: MapPointType.dot,
  },
  '37_0': {
    type: MapPointType.dot,
  },
  '37_1': {
    type: MapPointType.dot,
  },
  '37_10': {
    type: MapPointType.dot,
  },
  '37_11': {
    type: MapPointType.dot,
  },
  '37_12': {
    type: MapPointType.dot,
  },
  '37_13': {
    type: MapPointType.dot,
  },
  '37_14': {
    type: MapPointType.dot,
  },
  '37_15': {
    type: MapPointType.dot,
  },
  '37_16': {
    type: MapPointType.dot,
  },
  '37_17': {
    type: MapPointType.dot,
  },
  '37_18': {
    type: MapPointType.dot,
  },
  '37_19': {
    type: MapPointType.dot,
  },
  '37_2': {
    type: MapPointType.dot,
  },
  '37_20': {
    type: MapPointType.dot,
  },
  '37_21': {
    type: MapPointType.mountain,
  },
  '37_22': {
    type: MapPointType.mountain,
  },
  '37_23': {
    type: MapPointType.dot,
  },
  '37_24': {
    type: MapPointType.dot,
  },
  '37_25': {
    type: MapPointType.dot,
  },
  '37_26': {
    type: MapPointType.dot,
  },
  '37_27': {
    type: MapPointType.dot,
  },
  '37_28': {
    type: MapPointType.dot,
  },
  '37_29': {
    type: MapPointType.dot,
  },
  '37_3': {
    type: MapPointType.dot,
  },
  '37_4': {
    type: MapPointType.dot,
  },
  '37_45': {
    type: MapPointType.dot,
  },
  '37_46': {
    type: MapPointType.dot,
  },
  '37_47': {
    type: MapPointType.dot,
  },
  '37_5': {
    type: MapPointType.mountain,
  },
  '37_6': {
    type: MapPointType.mountain,
  },
  '37_7': {
    type: MapPointType.dot,
  },
  '37_8': {
    type: MapPointType.circleCity,
    name: 'Deluth',
  },
  '37_9': {
    type: MapPointType.dot,
  },
  '38_1': {
    type: MapPointType.dot,
  },
  '38_10': {
    type: MapPointType.dot,
  },
  '38_11': {
    type: MapPointType.dot,
  },
  '38_12': {
    type: MapPointType.dot,
  },
  '38_13': {
    type: MapPointType.dot,
  },
  '38_14': {
    type: MapPointType.dot,
  },
  '38_15': {
    type: MapPointType.dot,
  },
  '38_16': {
    type: MapPointType.dot,
  },
  '38_17': {
    type: MapPointType.dot,
  },
  '38_18': {
    type: MapPointType.dot,
  },
  '38_19': {
    type: MapPointType.dot,
  },
  '38_2': {
    type: MapPointType.dot,
  },
  '38_20': {
    type: MapPointType.dot,
  },
  '38_21': {
    type: MapPointType.dot,
  },
  '38_22': {
    type: MapPointType.mountain,
  },
  '38_23': {
    type: MapPointType.dot,
  },
  '38_24': {
    type: MapPointType.dot,
  },
  '38_25': {
    type: MapPointType.dot,
  },
  '38_26': {
    type: MapPointType.dot,
  },
  '38_27': {
    type: MapPointType.dot,
  },
  '38_28': {
    type: MapPointType.dot,
  },
  '38_29': {
    type: MapPointType.dot,
  },
  '38_3': {
    type: MapPointType.dot,
  },
  '38_30': {
    type: MapPointType.dot,
  },
  '38_4': {
    type: MapPointType.dot,
  },
  '38_46': {
    type: MapPointType.dot,
  },
  '38_47': {
    type: MapPointType.mountain,
  },
  '38_5': {
    type: MapPointType.dot,
  },
  '38_6': {
    type: MapPointType.mountain,
  },
  '38_7': {
    type: MapPointType.dot,
  },
  '38_9': {
    type: MapPointType.mountain,
  },
  '39_0': {
    type: MapPointType.dot,
  },
  '39_1': {
    type: MapPointType.dot,
  },
  '39_10': {
    type: MapPointType.dot,
  },
  '39_11': {
    type: MapPointType.dot,
  },
  '39_12': {
    type: MapPointType.dot,
  },
  '39_13': {
    type: MapPointType.dot,
  },
  '39_14': {
    type: MapPointType.dot,
  },
  '39_15': {
    type: MapPointType.dot,
  },
  '39_16': {
    type: MapPointType.dot,
  },
  '39_17': {
    type: MapPointType.dot,
  },
  '39_18': {
    type: MapPointType.dot,
  },
  '39_19': {
    type: MapPointType.dot,
  },
  '39_2': {
    type: MapPointType.dot,
  },
  '39_20': {
    type: MapPointType.dot,
  },
  '39_21': {
    type: MapPointType.dot,
  },
  '39_22': {
    type: MapPointType.dot,
  },
  '39_23': {
    type: MapPointType.dot,
  },
  '39_24': {
    type: MapPointType.dot,
  },
  '39_25': {
    type: MapPointType.dot,
  },
  '39_26': {
    type: MapPointType.dot,
  },
  '39_27': {
    type: MapPointType.dot,
  },
  '39_28': {
    type: MapPointType.dot,
  },
  '39_29': {
    type: MapPointType.dot,
  },
  '39_3': {
    type: MapPointType.dot,
  },
  '39_4': {
    type: MapPointType.dot,
  },
  '39_45': {
    type: MapPointType.dot,
  },
  '39_46': {
    type: MapPointType.mountain,
  },
  '39_47': {
    type: MapPointType.mountain,
  },
  '39_5': {
    type: MapPointType.dot,
  },
  '39_8': {
    type: MapPointType.mountain,
  },
  '39_9': {
    type: MapPointType.mountain,
  },
  '4_10': {
    type: MapPointType.mountain,
  },
  '4_11': {
    type: MapPointType.mountain,
  },
  '4_12': {
    type: MapPointType.dot,
  },
  '4_13': {
    type: MapPointType.mountain,
  },
  '4_14': {
    type: MapPointType.mountain,
  },
  '4_15': {
    type: MapPointType.dot,
  },
  '4_16': {
    type: MapPointType.dot,
  },
  '4_17': {
    type: MapPointType.dot,
  },
  '4_18': {
    type: MapPointType.dot,
  },
  '4_19': {
    type: MapPointType.dot,
  },
  '4_20': {
    type: MapPointType.mountain,
  },
  '4_21': {
    type: MapPointType.mountain,
  },
  '4_22': {
    type: MapPointType.dot,
  },
  '4_3': {
    type: MapPointType.dot,
  },
  '4_4': {
    type: MapPointType.dot,
  },
  '4_5': {
    type: MapPointType.dot,
  },
  '4_6': {
    type: MapPointType.mountain,
  },
  '4_7': {
    type: MapPointType.dot,
  },
  '4_8': {
    type: MapPointType.dot,
  },
  '4_9': {
    type: MapPointType.dot,
  },
  '40_1': {
    type: MapPointType.dot,
  },
  '40_10': {
    type: MapPointType.dot,
  },
  '40_11': {
    type: MapPointType.dot,
  },
  '40_12': {
    type: MapPointType.dot,
  },
  '40_13': {
    type: MapPointType.dot,
  },
  '40_14': {
    type: MapPointType.dot,
  },
  '40_15': {
    type: MapPointType.dot,
  },
  '40_16': {
    type: MapPointType.dot,
  },
  '40_17': {
    type: MapPointType.dot,
  },
  '40_18': {
    type: MapPointType.dot,
  },
  '40_19': {
    type: MapPointType.circleCity,
    name: 'St. Louis',
  },
  '40_2': {
    type: MapPointType.dot,
  },
  '40_20': {
    type: MapPointType.dot,
  },
  '40_21': {
    type: MapPointType.dot,
  },
  '40_22': {
    type: MapPointType.dot,
  },
  '40_23': {
    type: MapPointType.dot,
  },
  '40_24': {
    type: MapPointType.dot,
  },
  '40_25': {
    type: MapPointType.dot,
  },
  '40_26': {
    type: MapPointType.dot,
  },
  '40_27': {
    type: MapPointType.dot,
  },
  '40_28': {
    type: MapPointType.dot,
  },
  '40_29': {
    type: MapPointType.dot,
  },
  '40_3': {
    type: MapPointType.dot,
  },
  '40_30': {
    type: MapPointType.dot,
  },
  '40_45': {
    type: MapPointType.dot,
  },
  '40_46': {
    type: MapPointType.dot,
  },
  '40_47': {
    type: MapPointType.mountain,
  },
  '40_48': {
    type: MapPointType.dot,
  },
  '40_5': {
    type: MapPointType.dot,
  },
  '40_8': {
    type: MapPointType.mountain,
  },
  '40_9': {
    type: MapPointType.mountain,
  },
  '41_0': {
    type: MapPointType.dot,
  },
  '41_1': {
    type: MapPointType.dot,
  },
  '41_10': {
    type: MapPointType.dot,
  },
  '41_11': {
    type: MapPointType.dot,
  },
  '41_12': {
    type: MapPointType.dot,
  },
  '41_13': {
    type: MapPointType.dot,
  },
  '41_14': {
    type: MapPointType.hexCity,
    name: 'Chicago',
  },
  '41_15': {
    type: MapPointType.dot,
  },
  '41_16': {
    type: MapPointType.dot,
  },
  '41_17': {
    type: MapPointType.dot,
  },
  '41_18': {
    type: MapPointType.dot,
  },
  '41_19': {
    type: MapPointType.dot,
  },
  '41_2': {
    type: MapPointType.mountain,
  },
  '41_20': {
    type: MapPointType.dot,
  },
  '41_21': {
    type: MapPointType.dot,
  },
  '41_22': {
    type: MapPointType.dot,
  },
  '41_23': {
    type: MapPointType.circleCity,
    name: 'Memphis',
  },
  '41_24': {
    type: MapPointType.dot,
  },
  '41_25': {
    type: MapPointType.dot,
  },
  '41_26': {
    type: MapPointType.dot,
  },
  '41_27': {
    type: MapPointType.dot,
  },
  '41_28': {
    type: MapPointType.dot,
  },
  '41_29': {
    type: MapPointType.squareCity,
    name: 'New Orleans',
  },
  '41_3': {
    type: MapPointType.mountain,
  },
  '41_30': {
    type: MapPointType.dot,
  },
  '41_4': {
    type: MapPointType.dot,
  },
  '41_44': {
    type: MapPointType.dot,
  },
  '41_45': {
    type: MapPointType.dot,
  },
  '41_46': {
    type: MapPointType.mountain,
  },
  '41_47': {
    type: MapPointType.mountain,
  },
  '41_48': {
    type: MapPointType.dot,
  },
  '41_8': {
    type: MapPointType.dot,
  },
  '41_9': {
    type: MapPointType.dot,
  },
  '42_1': {
    type: MapPointType.dot,
  },
  '42_13': {
    type: MapPointType.dot,
  },
  '42_14': {
    type: MapPointType.dot,
  },
  '42_15': {
    type: MapPointType.dot,
  },
  '42_16': {
    type: MapPointType.dot,
  },
  '42_17': {
    type: MapPointType.dot,
  },
  '42_18': {
    type: MapPointType.dot,
  },
  '42_19': {
    type: MapPointType.dot,
  },
  '42_2': {
    type: MapPointType.dot,
  },
  '42_20': {
    type: MapPointType.dot,
  },
  '42_21': {
    type: MapPointType.dot,
  },
  '42_22': {
    type: MapPointType.dot,
  },
  '42_23': {
    type: MapPointType.dot,
  },
  '42_24': {
    type: MapPointType.dot,
  },
  '42_25': {
    type: MapPointType.dot,
  },
  '42_26': {
    type: MapPointType.dot,
  },
  '42_27': {
    type: MapPointType.dot,
  },
  '42_28': {
    type: MapPointType.dot,
  },
  '42_29': {
    type: MapPointType.dot,
  },
  '42_3': {
    type: MapPointType.dot,
  },
  '42_30': {
    type: MapPointType.dot,
  },
  '42_4': {
    type: MapPointType.mountain,
  },
  '42_45': {
    type: MapPointType.dot,
  },
  '42_46': {
    type: MapPointType.dot,
  },
  '42_47': {
    type: MapPointType.mountain,
  },
  '42_5': {
    type: MapPointType.dot,
  },
  '42_9': {
    type: MapPointType.dot,
  },
  '43_0': {
    type: MapPointType.dot,
  },
  '43_1': {
    type: MapPointType.dot,
  },
  '43_14': {
    type: MapPointType.dot,
  },
  '43_15': {
    type: MapPointType.dot,
  },
  '43_16': {
    type: MapPointType.dot,
  },
  '43_17': {
    type: MapPointType.dot,
  },
  '43_18': {
    type: MapPointType.dot,
  },
  '43_19': {
    type: MapPointType.dot,
  },
  '43_2': {
    type: MapPointType.dot,
  },
  '43_20': {
    type: MapPointType.dot,
  },
  '43_21': {
    type: MapPointType.dot,
  },
  '43_22': {
    type: MapPointType.dot,
  },
  '43_23': {
    type: MapPointType.dot,
  },
  '43_24': {
    type: MapPointType.dot,
  },
  '43_25': {
    type: MapPointType.dot,
  },
  '43_26': {
    type: MapPointType.dot,
  },
  '43_27': {
    type: MapPointType.dot,
  },
  '43_28': {
    type: MapPointType.dot,
  },
  '43_3': {
    type: MapPointType.dot,
  },
  '43_4': {
    type: MapPointType.mountain,
  },
  '43_43': {
    type: MapPointType.dot,
  },
  '43_44': {
    type: MapPointType.dot,
  },
  '43_5': {
    type: MapPointType.dot,
  },
  '43_8': {
    type: MapPointType.dot,
  },
  '44_0': {
    type: MapPointType.dot,
  },
  '44_1': {
    type: MapPointType.dot,
  },
  '44_11': {
    type: MapPointType.dot,
  },
  '44_12': {
    type: MapPointType.dot,
  },
  '44_13': {
    type: MapPointType.dot,
  },
  '44_14': {
    type: MapPointType.dot,
  },
  '44_15': {
    type: MapPointType.dot,
  },
  '44_16': {
    type: MapPointType.dot,
  },
  '44_17': {
    type: MapPointType.dot,
  },
  '44_18': {
    type: MapPointType.dot,
  },
  '44_19': {
    type: MapPointType.dot,
  },
  '44_2': {
    type: MapPointType.dot,
  },
  '44_20': {
    type: MapPointType.dot,
  },
  '44_21': {
    type: MapPointType.mountain,
  },
  '44_22': {
    type: MapPointType.mountain,
  },
  '44_23': {
    type: MapPointType.dot,
  },
  '44_24': {
    type: MapPointType.dot,
  },
  '44_25': {
    type: MapPointType.dot,
  },
  '44_26': {
    type: MapPointType.dot,
  },
  '44_27': {
    type: MapPointType.dot,
  },
  '44_28': {
    type: MapPointType.dot,
  },
  '44_3': {
    type: MapPointType.dot,
  },
  '44_4': {
    type: MapPointType.dot,
  },
  '44_41': {
    type: MapPointType.dot,
  },
  '44_42': {
    type: MapPointType.dot,
  },
  '44_43': {
    type: MapPointType.dot,
  },
  '44_44': {
    type: MapPointType.dot,
  },
  '44_45': {
    type: MapPointType.dot,
  },
  '44_5': {
    type: MapPointType.dot,
  },
  '44_6': {
    type: MapPointType.dot,
  },
  '44_8': {
    type: MapPointType.dot,
  },
  '45_0': {
    type: MapPointType.dot,
  },
  '45_1': {
    type: MapPointType.dot,
  },
  '45_10': {
    type: MapPointType.mountain,
  },
  '45_11': {
    type: MapPointType.dot,
  },
  '45_12': {
    type: MapPointType.dot,
  },
  '45_13': {
    type: MapPointType.dot,
  },
  '45_14': {
    type: MapPointType.dot,
  },
  '45_15': {
    type: MapPointType.dot,
  },
  '45_16': {
    type: MapPointType.dot,
  },
  '45_17': {
    type: MapPointType.dot,
  },
  '45_18': {
    type: MapPointType.dot,
  },
  '45_19': {
    type: MapPointType.mountain,
  },
  '45_2': {
    type: MapPointType.dot,
  },
  '45_20': {
    type: MapPointType.mountain,
  },
  '45_21': {
    type: MapPointType.mountain,
  },
  '45_22': {
    type: MapPointType.mountain,
  },
  '45_23': {
    type: MapPointType.dot,
  },
  '45_24': {
    type: MapPointType.dot,
  },
  '45_25': {
    type: MapPointType.dot,
  },
  '45_26': {
    type: MapPointType.dot,
  },
  '45_27': {
    type: MapPointType.dot,
  },
  '45_28': {
    type: MapPointType.dot,
  },
  '45_3': {
    type: MapPointType.dot,
  },
  '45_4': {
    type: MapPointType.dot,
  },
  '45_40': {
    type: MapPointType.dot,
  },
  '45_41': {
    type: MapPointType.dot,
  },
  '45_42': {
    type: MapPointType.dot,
  },
  '45_43': {
    type: MapPointType.dot,
  },
  '45_44': {
    type: MapPointType.dot,
  },
  '45_5': {
    type: MapPointType.mountain,
  },
  '45_6': {
    type: MapPointType.mountain,
  },
  '45_7': {
    type: MapPointType.dot,
  },
  '45_8': {
    type: MapPointType.dot,
  },
  '45_9': {
    type: MapPointType.dot,
  },
  '46_1': {
    type: MapPointType.dot,
  },
  '46_10': {
    type: MapPointType.dot,
  },
  '46_11': {
    type: MapPointType.dot,
  },
  '46_12': {
    type: MapPointType.dot,
  },
  '46_13': {
    type: MapPointType.dot,
  },
  '46_14': {
    type: MapPointType.dot,
  },
  '46_15': {
    type: MapPointType.dot,
  },
  '46_16': {
    type: MapPointType.dot,
  },
  '46_17': {
    type: MapPointType.dot,
  },
  '46_18': {
    type: MapPointType.dot,
  },
  '46_19': {
    type: MapPointType.mountain,
  },
  '46_2': {
    type: MapPointType.dot,
  },
  '46_20': {
    type: MapPointType.dot,
  },
  '46_21': {
    type: MapPointType.mountain,
  },
  '46_22': {
    type: MapPointType.mountain,
  },
  '46_23': {
    type: MapPointType.dot,
  },
  '46_24': {
    type: MapPointType.circleCity,
    name: 'Birmingham',
  },
  '46_25': {
    type: MapPointType.dot,
  },
  '46_26': {
    type: MapPointType.dot,
  },
  '46_27': {
    type: MapPointType.dot,
  },
  '46_28': {
    type: MapPointType.dot,
  },
  '46_3': {
    type: MapPointType.dot,
  },
  '46_4': {
    type: MapPointType.dot,
  },
  '46_41': {
    type: MapPointType.dot,
  },
  '46_42': {
    type: MapPointType.dot,
  },
  '46_43': {
    type: MapPointType.dot,
  },
  '46_44': {
    type: MapPointType.dot,
  },
  '46_5': {
    type: MapPointType.mountain,
  },
  '46_6': {
    type: MapPointType.dot,
  },
  '46_7': {
    type: MapPointType.dot,
  },
  '46_8': {
    type: MapPointType.dot,
  },
  '47_11': {
    type: MapPointType.dot,
  },
  '47_12': {
    type: MapPointType.dot,
  },
  '47_13': {
    type: MapPointType.squareCity,
    name: 'Detroit',
  },
  '47_14': {
    type: MapPointType.dot,
  },
  '47_15': {
    type: MapPointType.dot,
  },
  '47_16': {
    type: MapPointType.dot,
  },
  '47_17': {
    type: MapPointType.circleCity,
    name: 'Cincinnati',
  },
  '47_18': {
    type: MapPointType.dot,
  },
  '47_19': {
    type: MapPointType.mountain,
  },
  '47_2': {
    type: MapPointType.dot,
  },
  '47_20': {
    type: MapPointType.mountain,
  },
  '47_21': {
    type: MapPointType.dot,
  },
  '47_22': {
    type: MapPointType.mountain,
  },
  '47_23': {
    type: MapPointType.dot,
  },
  '47_24': {
    type: MapPointType.dot,
  },
  '47_25': {
    type: MapPointType.dot,
  },
  '47_26': {
    type: MapPointType.dot,
  },
  '47_27': {
    type: MapPointType.dot,
  },
  '47_3': {
    type: MapPointType.dot,
  },
  '47_4': {
    type: MapPointType.dot,
  },
  '47_40': {
    type: MapPointType.dot,
  },
  '47_41': {
    type: MapPointType.dot,
  },
  '47_42': {
    type: MapPointType.dot,
  },
  '47_43': {
    type: MapPointType.dot,
  },
  '47_5': {
    type: MapPointType.dot,
  },
  '47_6': {
    type: MapPointType.dot,
  },
  '47_7': {
    type: MapPointType.dot,
  },
  '48_12': {
    type: MapPointType.dot,
  },
  '48_13': {
    type: MapPointType.dot,
  },
  '48_14': {
    type: MapPointType.dot,
  },
  '48_15': {
    type: MapPointType.dot,
  },
  '48_16': {
    type: MapPointType.dot,
  },
  '48_17': {
    type: MapPointType.dot,
  },
  '48_18': {
    type: MapPointType.mountain,
  },
  '48_19': {
    type: MapPointType.mountain,
  },
  '48_2': {
    type: MapPointType.dot,
  },
  '48_20': {
    type: MapPointType.mountain,
  },
  '48_21': {
    type: MapPointType.circleCity,
    name: 'Knoxville',
  },
  '48_22': {
    type: MapPointType.mountain,
  },
  '48_23': {
    type: MapPointType.dot,
  },
  '48_24': {
    type: MapPointType.dot,
  },
  '48_25': {
    type: MapPointType.dot,
  },
  '48_26': {
    type: MapPointType.dot,
  },
  '48_27': {
    type: MapPointType.dot,
  },
  '48_28': {
    type: MapPointType.dot,
  },
  '48_3': {
    type: MapPointType.dot,
  },
  '48_4': {
    type: MapPointType.dot,
  },
  '48_5': {
    type: MapPointType.mountain,
  },
  '48_6': {
    type: MapPointType.mountain,
  },
  '48_7': {
    type: MapPointType.circleCity,
    name: 'Sudbury',
  },
  '49_0': {
    type: MapPointType.dot,
  },
  '49_1': {
    type: MapPointType.dot,
  },
  '49_10': {
    type: MapPointType.dot,
  },
  '49_11': {
    type: MapPointType.dot,
  },
  '49_12': {
    type: MapPointType.dot,
  },
  '49_13': {
    type: MapPointType.dot,
  },
  '49_14': {
    type: MapPointType.dot,
  },
  '49_15': {
    type: MapPointType.dot,
  },
  '49_16': {
    type: MapPointType.dot,
  },
  '49_17': {
    type: MapPointType.mountain,
  },
  '49_18': {
    type: MapPointType.mountain,
  },
  '49_19': {
    type: MapPointType.dot,
  },
  '49_2': {
    type: MapPointType.dot,
  },
  '49_20': {
    type: MapPointType.mountain,
  },
  '49_21': {
    type: MapPointType.mountain,
  },
  '49_22': {
    type: MapPointType.dot,
  },
  '49_23': {
    type: MapPointType.hexCity,
    name: 'Atlanta',
  },
  '49_24': {
    type: MapPointType.dot,
  },
  '49_25': {
    type: MapPointType.dot,
  },
  '49_26': {
    type: MapPointType.dot,
  },
  '49_27': {
    type: MapPointType.dot,
  },
  '49_28': {
    type: MapPointType.dot,
  },
  '49_3': {
    type: MapPointType.dot,
  },
  '49_4': {
    type: MapPointType.mountain,
  },
  '49_5': {
    type: MapPointType.dot,
  },
  '49_6': {
    type: MapPointType.dot,
  },
  '49_7': {
    type: MapPointType.dot,
  },
  '5_10': {
    type: MapPointType.mountain,
  },
  '5_11': {
    type: MapPointType.mountain,
  },
  '5_12': {
    type: MapPointType.mountain,
  },
  '5_13': {
    type: MapPointType.dot,
  },
  '5_14': {
    type: MapPointType.dot,
  },
  '5_15': {
    type: MapPointType.dot,
  },
  '5_16': {
    type: MapPointType.dot,
  },
  '5_17': {
    type: MapPointType.dot,
  },
  '5_18': {
    type: MapPointType.dot,
  },
  '5_19': {
    type: MapPointType.dot,
  },
  '5_20': {
    type: MapPointType.dot,
  },
  '5_21': {
    type: MapPointType.mountain,
  },
  '5_22': {
    type: MapPointType.dot,
  },
  '5_23': {
    type: MapPointType.dot,
  },
  '5_3': {
    type: MapPointType.dot,
  },
  '5_4': {
    type: MapPointType.dot,
  },
  '5_5': {
    type: MapPointType.dot,
  },
  '5_6': {
    type: MapPointType.dot,
  },
  '5_7': {
    type: MapPointType.circleCity,
    name: 'Portland, OR',
  },
  '5_8': {
    type: MapPointType.mountain,
  },
  '5_9': {
    type: MapPointType.mountain,
  },
  '50_0': {
    type: MapPointType.dot,
  },
  '50_1': {
    type: MapPointType.dot,
  },
  '50_10': {
    type: MapPointType.dot,
  },
  '50_11': {
    type: MapPointType.dot,
  },
  '50_12': {
    type: MapPointType.dot,
  },
  '50_13': {
    type: MapPointType.dot,
  },
  '50_14': {
    type: MapPointType.dot,
  },
  '50_15': {
    type: MapPointType.dot,
  },
  '50_16': {
    type: MapPointType.dot,
  },
  '50_17': {
    type: MapPointType.dot,
  },
  '50_18': {
    type: MapPointType.mountain,
  },
  '50_19': {
    type: MapPointType.mountain,
  },
  '50_2': {
    type: MapPointType.dot,
  },
  '50_20': {
    type: MapPointType.mountain,
  },
  '50_21': {
    type: MapPointType.mountain,
  },
  '50_22': {
    type: MapPointType.dot,
  },
  '50_23': {
    type: MapPointType.dot,
  },
  '50_24': {
    type: MapPointType.dot,
  },
  '50_25': {
    type: MapPointType.dot,
  },
  '50_26': {
    type: MapPointType.dot,
  },
  '50_27': {
    type: MapPointType.dot,
  },
  '50_28': {
    type: MapPointType.dot,
  },
  '50_3': {
    type: MapPointType.dot,
  },
  '50_4': {
    type: MapPointType.dot,
  },
  '50_5': {
    type: MapPointType.dot,
  },
  '50_6': {
    type: MapPointType.dot,
  },
  '50_7': {
    type: MapPointType.dot,
  },
  '50_8': {
    type: MapPointType.dot,
  },
  '50_9': {
    type: MapPointType.dot,
  },
  '51_0': {
    type: MapPointType.dot,
  },
  '51_1': {
    type: MapPointType.dot,
  },
  '51_10': {
    type: MapPointType.squareCity,
    name: 'Toronto',
  },
  '51_11': {
    type: MapPointType.dot,
  },
  '51_12': {
    type: MapPointType.dot,
  },
  '51_13': {
    type: MapPointType.mountain,
  },
  '51_14': {
    type: MapPointType.dot,
  },
  '51_15': {
    type: MapPointType.mountain,
  },
  '51_16': {
    type: MapPointType.mountain,
  },
  '51_17': {
    type: MapPointType.mountain,
  },
  '51_18': {
    type: MapPointType.dot,
  },
  '51_19': {
    type: MapPointType.mountain,
  },
  '51_2': {
    type: MapPointType.dot,
  },
  '51_20': {
    type: MapPointType.mountain,
  },
  '51_21': {
    type: MapPointType.dot,
  },
  '51_22': {
    type: MapPointType.dot,
  },
  '51_23': {
    type: MapPointType.dot,
  },
  '51_24': {
    type: MapPointType.dot,
  },
  '51_25': {
    type: MapPointType.dot,
  },
  '51_26': {
    type: MapPointType.dot,
  },
  '51_27': {
    type: MapPointType.dot,
  },
  '51_28': {
    type: MapPointType.dot,
  },
  '51_3': {
    type: MapPointType.dot,
  },
  '51_4': {
    type: MapPointType.dot,
  },
  '51_5': {
    type: MapPointType.dot,
  },
  '51_6': {
    type: MapPointType.mountain,
  },
  '51_7': {
    type: MapPointType.dot,
  },
  '51_8': {
    type: MapPointType.dot,
  },
  '51_9': {
    type: MapPointType.dot,
  },
  '52_0': {
    type: MapPointType.dot,
  },
  '52_1': {
    type: MapPointType.dot,
  },
  '52_11': {
    type: MapPointType.dot,
  },
  '52_12': {
    type: MapPointType.circleCity,
    name: 'Buffalo',
  },
  '52_13': {
    type: MapPointType.mountain,
  },
  '52_14': {
    type: MapPointType.mountain,
  },
  '52_15': {
    type: MapPointType.circleCity,
    name: 'Pittsburgh',
  },
  '52_16': {
    type: MapPointType.mountain,
  },
  '52_17': {
    type: MapPointType.mountain,
  },
  '52_18': {
    type: MapPointType.mountain,
  },
  '52_19': {
    type: MapPointType.mountain,
  },
  '52_2': {
    type: MapPointType.dot,
  },
  '52_20': {
    type: MapPointType.dot,
  },
  '52_21': {
    type: MapPointType.dot,
  },
  '52_22': {
    type: MapPointType.dot,
  },
  '52_23': {
    type: MapPointType.dot,
  },
  '52_24': {
    type: MapPointType.dot,
  },
  '52_25': {
    type: MapPointType.dot,
  },
  '52_26': {
    type: MapPointType.dot,
  },
  '52_27': {
    type: MapPointType.dot,
  },
  '52_28': {
    type: MapPointType.dot,
  },
  '52_29': {
    type: MapPointType.dot,
  },
  '52_3': {
    type: MapPointType.dot,
  },
  '52_30': {
    type: MapPointType.dot,
  },
  '52_31': {
    type: MapPointType.circleCity,
    name: 'Tampa',
  },
  '52_4': {
    type: MapPointType.mountain,
  },
  '52_5': {
    type: MapPointType.mountain,
  },
  '52_6': {
    type: MapPointType.mountain,
  },
  '52_7': {
    type: MapPointType.mountain,
  },
  '52_8': {
    type: MapPointType.mountain,
  },
  '52_9': {
    type: MapPointType.dot,
  },
  '53_0': {
    type: MapPointType.dot,
  },
  '53_1': {
    type: MapPointType.dot,
  },
  '53_10': {
    type: MapPointType.dot,
  },
  '53_11': {
    type: MapPointType.mountain,
  },
  '53_12': {
    type: MapPointType.mountain,
  },
  '53_13': {
    type: MapPointType.mountain,
  },
  '53_14': {
    type: MapPointType.mountain,
  },
  '53_15': {
    type: MapPointType.mountain,
  },
  '53_16': {
    type: MapPointType.mountain,
  },
  '53_17': {
    type: MapPointType.dot,
  },
  '53_18': {
    type: MapPointType.dot,
  },
  '53_19': {
    type: MapPointType.dot,
  },
  '53_2': {
    type: MapPointType.dot,
  },
  '53_20': {
    type: MapPointType.dot,
  },
  '53_21': {
    type: MapPointType.dot,
  },
  '53_22': {
    type: MapPointType.dot,
  },
  '53_23': {
    type: MapPointType.dot,
  },
  '53_24': {
    type: MapPointType.dot,
  },
  '53_25': {
    type: MapPointType.squareCity,
    name: 'Savannah',
  },
  '53_26': {
    type: MapPointType.dot,
  },
  '53_27': {
    type: MapPointType.circleCity,
    name: 'Jacksonville',
  },
  '53_28': {
    type: MapPointType.dot,
  },
  '53_29': {
    type: MapPointType.dot,
  },
  '53_3': {
    type: MapPointType.dot,
  },
  '53_30': {
    type: MapPointType.dot,
  },
  '53_31': {
    type: MapPointType.dot,
  },
  '53_4': {
    type: MapPointType.dot,
  },
  '53_5': {
    type: MapPointType.dot,
  },
  '53_6': {
    type: MapPointType.mountain,
  },
  '53_7': {
    type: MapPointType.mountain,
  },
  '53_8': {
    type: MapPointType.dot,
  },
  '54_0': {
    type: MapPointType.dot,
  },
  '54_1': {
    type: MapPointType.dot,
  },
  '54_11': {
    type: MapPointType.dot,
  },
  '54_12': {
    type: MapPointType.mountain,
  },
  '54_13': {
    type: MapPointType.mountain,
  },
  '54_14': {
    type: MapPointType.mountain,
  },
  '54_15': {
    type: MapPointType.dot,
  },
  '54_16': {
    type: MapPointType.dot,
  },
  '54_17': {
    type: MapPointType.dot,
  },
  '54_18': {
    type: MapPointType.dot,
  },
  '54_19': {
    type: MapPointType.dot,
  },
  '54_2': {
    type: MapPointType.dot,
  },
  '54_20': {
    type: MapPointType.circleCity,
    name: 'Raleigh',
  },
  '54_21': {
    type: MapPointType.dot,
  },
  '54_22': {
    type: MapPointType.dot,
  },
  '54_23': {
    type: MapPointType.dot,
  },
  '54_24': {
    type: MapPointType.dot,
  },
  '54_29': {
    type: MapPointType.dot,
  },
  '54_3': {
    type: MapPointType.dot,
  },
  '54_30': {
    type: MapPointType.dot,
  },
  '54_31': {
    type: MapPointType.dot,
  },
  '54_32': {
    type: MapPointType.dot,
  },
  '54_33': {
    type: MapPointType.dot,
  },
  '54_4': {
    type: MapPointType.dot,
  },
  '54_5': {
    type: MapPointType.dot,
  },
  '54_6': {
    type: MapPointType.dot,
  },
  '54_7': {
    type: MapPointType.dot,
  },
  '54_8': {
    type: MapPointType.dot,
  },
  '55_0': {
    type: MapPointType.dot,
  },
  '55_1': {
    type: MapPointType.dot,
  },
  '55_10': {
    type: MapPointType.dot,
  },
  '55_11': {
    type: MapPointType.mountain,
  },
  '55_12': {
    type: MapPointType.mountain,
  },
  '55_13': {
    type: MapPointType.dot,
  },
  '55_14': {
    type: MapPointType.dot,
  },
  '55_15': {
    type: MapPointType.circleCity,
    name: 'Washington D.C.',
  },
  '55_16': {
    type: MapPointType.dot,
  },
  '55_17': {
    type: MapPointType.dot,
  },
  '55_18': {
    type: MapPointType.dot,
  },
  '55_19': {
    type: MapPointType.dot,
  },
  '55_2': {
    type: MapPointType.dot,
  },
  '55_20': {
    type: MapPointType.dot,
  },
  '55_21': {
    type: MapPointType.dot,
  },
  '55_22': {
    type: MapPointType.dot,
  },
  '55_3': {
    type: MapPointType.dot,
  },
  '55_30': {
    type: MapPointType.dot,
  },
  '55_31': {
    type: MapPointType.dot,
  },
  '55_32': {
    type: MapPointType.dot,
  },
  '55_33': {
    type: MapPointType.dot,
  },
  '55_4': {
    type: MapPointType.mountain,
  },
  '55_5': {
    type: MapPointType.mountain,
  },
  '55_6': {
    type: MapPointType.dot,
  },
  '55_7': {
    type: MapPointType.mountain,
  },
  '55_8': {
    type: MapPointType.dot,
  },
  '55_9': {
    type: MapPointType.mountain,
  },
  '56_0': {
    type: MapPointType.dot,
  },
  '56_1': {
    type: MapPointType.dot,
  },
  '56_10': {
    type: MapPointType.mountain,
  },
  '56_11': {
    type: MapPointType.dot,
  },
  '56_12': {
    type: MapPointType.dot,
  },
  '56_13': {
    type: MapPointType.mountain,
  },
  '56_14': {
    type: MapPointType.dot,
  },
  '56_15': {
    type: MapPointType.dot,
  },
  '56_17': {
    type: MapPointType.dot,
  },
  '56_18': {
    type: MapPointType.dot,
  },
  '56_19': {
    type: MapPointType.dot,
  },
  '56_2': {
    type: MapPointType.dot,
  },
  '56_20': {
    type: MapPointType.dot,
  },
  '56_21': {
    type: MapPointType.dot,
  },
  '56_22': {
    type: MapPointType.dot,
  },
  '56_3': {
    type: MapPointType.mountain,
  },
  '56_32': {
    type: MapPointType.dot,
  },
  '56_33': {
    type: MapPointType.circleCity,
    name: 'Miami',
  },
  '56_4': {
    type: MapPointType.dot,
  },
  '56_5': {
    type: MapPointType.mountain,
  },
  '56_6': {
    type: MapPointType.squareCity,
    name: 'Montreal',
  },
  '56_7': {
    type: MapPointType.dot,
  },
  '56_8': {
    type: MapPointType.dot,
  },
  '56_9': {
    type: MapPointType.mountain,
  },
  '57_0': {
    type: MapPointType.dot,
  },
  '57_1': {
    type: MapPointType.dot,
  },
  '57_10': {
    type: MapPointType.dot,
  },
  '57_11': {
    type: MapPointType.hexCity,
    name: 'New York',
  },
  '57_12': {
    type: MapPointType.dot,
  },
  '57_13': {
    type: MapPointType.squareCity,
    name: 'Philadelphia',
  },
  '57_14': {
    type: MapPointType.dot,
  },
  '57_15': {
    type: MapPointType.dot,
  },
  '57_18': {
    type: MapPointType.circleCity,
    name: 'Norfolk',
  },
  '57_19': {
    type: MapPointType.dot,
  },
  '57_2': {
    type: MapPointType.mountain,
  },
  '57_20': {
    type: MapPointType.dot,
  },
  '57_3': {
    type: MapPointType.mountain,
  },
  '57_4': {
    type: MapPointType.dot,
  },
  '57_5': {
    type: MapPointType.dot,
  },
  '57_6': {
    type: MapPointType.dot,
  },
  '57_7': {
    type: MapPointType.mountain,
  },
  '57_8': {
    type: MapPointType.mountain,
  },
  '57_9': {
    type: MapPointType.dot,
  },
  '58_0': {
    type: MapPointType.dot,
  },
  '58_1': {
    type: MapPointType.dot,
  },
  '58_10': {
    type: MapPointType.mountain,
  },
  '58_11': {
    type: MapPointType.dot,
  },
  '58_12': {
    type: MapPointType.dot,
  },
  '58_13': {
    type: MapPointType.dot,
  },
  '58_14': {
    type: MapPointType.dot,
  },
  '58_2': {
    type: MapPointType.mountain,
  },
  '58_3': {
    type: MapPointType.mountain,
  },
  '58_5': {
    type: MapPointType.dot,
  },
  '58_6': {
    type: MapPointType.mountain,
  },
  '58_7': {
    type: MapPointType.mountain,
  },
  '58_8': {
    type: MapPointType.mountain,
  },
  '58_9': {
    type: MapPointType.mountain,
  },
  '59_10': {
    type: MapPointType.dot,
  },
  '59_11': {
    type: MapPointType.dot,
  },
  '59_2': {
    type: MapPointType.mountain,
  },
  '59_3': {
    type: MapPointType.mountain,
  },
  '59_4': {
    type: MapPointType.mountain,
  },
  '59_5': {
    type: MapPointType.mountain,
  },
  '59_6': {
    type: MapPointType.mountain,
  },
  '59_7': {
    type: MapPointType.dot,
  },
  '59_8': {
    type: MapPointType.mountain,
  },
  '59_9': {
    type: MapPointType.dot,
  },
  '6_0': {
    type: MapPointType.mountain,
  },
  '6_1': {
    type: MapPointType.mountain,
  },
  '6_10': {
    type: MapPointType.mountain,
  },
  '6_11': {
    type: MapPointType.mountain,
  },
  '6_12': {
    type: MapPointType.mountain,
  },
  '6_13': {
    type: MapPointType.mountain,
  },
  '6_14': {
    type: MapPointType.mountain,
  },
  '6_15': {
    type: MapPointType.mountain,
  },
  '6_16': {
    type: MapPointType.mountain,
  },
  '6_17': {
    type: MapPointType.mountain,
  },
  '6_18': {
    type: MapPointType.mountain,
  },
  '6_19': {
    type: MapPointType.mountain,
  },
  '6_2': {
    type: MapPointType.dot,
  },
  '6_20': {
    type: MapPointType.mountain,
  },
  '6_21': {
    type: MapPointType.dot,
  },
  '6_22': {
    type: MapPointType.dot,
  },
  '6_23': {
    type: MapPointType.hexCity,
    name: 'Los\nAngeles',
  },
  '6_24': {
    type: MapPointType.dot,
  },
  '6_4': {
    type: MapPointType.dot,
  },
  '6_5': {
    type: MapPointType.hexCity,
    name: 'Seatle',
  },
  '6_6': {
    type: MapPointType.dot,
  },
  '6_7': {
    type: MapPointType.dot,
  },
  '6_8': {
    type: MapPointType.mountain,
  },
  '6_9': {
    type: MapPointType.mountain,
  },
  '60_1': {
    type: MapPointType.mountain,
  },
  '60_10': {
    type: MapPointType.squareCity,
    name: 'Boston',
  },
  '60_11': {
    type: MapPointType.dot,
  },
  '60_2': {
    type: MapPointType.mountain,
  },
  '60_3': {
    type: MapPointType.mountain,
  },
  '60_4': {
    type: MapPointType.mountain,
  },
  '60_5': {
    type: MapPointType.mountain,
  },
  '60_6': {
    type: MapPointType.dot,
  },
  '60_7': {
    type: MapPointType.circleCity,
    name: 'Portland, ME',
  },
  '60_8': {
    type: MapPointType.dot,
  },
  '60_9': {
    type: MapPointType.dot,
  },
  '61_0': {
    type: MapPointType.dot,
  },
  '61_1': {
    type: MapPointType.mountain,
  },
  '61_2': {
    type: MapPointType.mountain,
  },
  '61_3': {
    type: MapPointType.mountain,
  },
  '61_4': {
    type: MapPointType.mountain,
  },
  '61_5': {
    type: MapPointType.mountain,
  },
  '61_6': {
    type: MapPointType.dot,
  },
  '62_0': {
    type: MapPointType.dot,
  },
  '62_2': {
    type: MapPointType.mountain,
  },
  '62_3': {
    type: MapPointType.dot,
  },
  '62_4': {
    type: MapPointType.dot,
  },
  '62_5': {
    type: MapPointType.dot,
  },
  '63_1': {
    type: MapPointType.mountain,
  },
  '63_2': {
    type: MapPointType.dot,
  },
  '63_3': {
    type: MapPointType.dot,
  },
  '63_4': {
    type: MapPointType.dot,
  },
  '64_3': {
    type: MapPointType.dot,
  },
  '64_4': {
    type: MapPointType.dot,
  },
  '65_2': {
    type: MapPointType.dot,
  },
  '65_3': {
    type: MapPointType.dot,
  },
  '65_4': {
    type: MapPointType.dot,
  },
  '65_5': {
    type: MapPointType.dot,
  },
  '66_3': {
    type: MapPointType.dot,
  },
  '66_4': {
    type: MapPointType.dot,
  },
  '67_2': {
    type: MapPointType.dot,
  },
  '67_3': {
    type: MapPointType.dot,
  },
  '68_1': {
    type: MapPointType.dot,
  },
  '68_2': {
    type: MapPointType.dot,
  },
  '7_0': {
    type: MapPointType.mountain,
  },
  '7_1': {
    type: MapPointType.mountain,
  },
  '7_10': {
    type: MapPointType.dot,
  },
  '7_11': {
    type: MapPointType.mountain,
  },
  '7_12': {
    type: MapPointType.dot,
  },
  '7_13': {
    type: MapPointType.mountain,
  },
  '7_14': {
    type: MapPointType.dot,
  },
  '7_15': {
    type: MapPointType.mountain,
  },
  '7_16': {
    type: MapPointType.mountain,
  },
  '7_17': {
    type: MapPointType.mountain,
  },
  '7_18': {
    type: MapPointType.mountain,
  },
  '7_19': {
    type: MapPointType.mountain,
  },
  '7_2': {
    type: MapPointType.circleCity,
    name: 'Vancouver',
  },
  '7_20': {
    type: MapPointType.mountain,
  },
  '7_21': {
    type: MapPointType.mountain,
  },
  '7_22': {
    type: MapPointType.dot,
  },
  '7_23': {
    type: MapPointType.dot,
  },
  '7_24': {
    type: MapPointType.dot,
  },
  '7_25': {
    type: MapPointType.circleCity,
    name: 'San Diego',
  },
  '7_3': {
    type: MapPointType.mountain,
  },
  '7_4': {
    type: MapPointType.dot,
  },
  '7_5': {
    type: MapPointType.dot,
  },
  '7_6': {
    type: MapPointType.dot,
  },
  '7_7': {
    type: MapPointType.dot,
  },
  '7_8': {
    type: MapPointType.mountain,
  },
  '7_9': {
    type: MapPointType.mountain,
  },
  '8_0': {
    type: MapPointType.mountain,
  },
  '8_1': {
    type: MapPointType.dot,
  },
  '8_10': {
    type: MapPointType.mountain,
  },
  '8_11': {
    type: MapPointType.mountain,
  },
  '8_12': {
    type: MapPointType.mountain,
  },
  '8_13': {
    type: MapPointType.mountain,
  },
  '8_14': {
    type: MapPointType.dot,
  },
  '8_15': {
    type: MapPointType.mountain,
  },
  '8_16': {
    type: MapPointType.dot,
  },
  '8_17': {
    type: MapPointType.mountain,
  },
  '8_18': {
    type: MapPointType.mountain,
  },
  '8_19': {
    type: MapPointType.mountain,
  },
  '8_2': {
    type: MapPointType.mountain,
  },
  '8_20': {
    type: MapPointType.mountain,
  },
  '8_21': {
    type: MapPointType.dot,
  },
  '8_22': {
    type: MapPointType.mountain,
  },
  '8_23': {
    type: MapPointType.mountain,
  },
  '8_24': {
    type: MapPointType.mountain,
  },
  '8_25': {
    type: MapPointType.mountain,
  },
  '8_26': {
    type: MapPointType.mountain,
  },
  '8_27': {
    type: MapPointType.mountain,
  },
  '8_28': {
    type: MapPointType.dot,
  },
  '8_29': {
    type: MapPointType.dot,
  },
  '8_3': {
    type: MapPointType.dot,
  },
  '8_4': {
    type: MapPointType.mountain,
  },
  '8_5': {
    type: MapPointType.mountain,
  },
  '8_6': {
    type: MapPointType.dot,
  },
  '8_7': {
    type: MapPointType.mountain,
  },
  '8_8': {
    type: MapPointType.dot,
  },
  '8_9': {
    type: MapPointType.mountain,
  },
  '9_0': {
    type: MapPointType.dot,
  },
  '9_1': {
    type: MapPointType.mountain,
  },
  '9_10': {
    type: MapPointType.dot,
  },
  '9_11': {
    type: MapPointType.dot,
  },
  '9_12': {
    type: MapPointType.mountain,
  },
  '9_13': {
    type: MapPointType.dot,
  },
  '9_14': {
    type: MapPointType.mountain,
  },
  '9_15': {
    type: MapPointType.dot,
  },
  '9_16': {
    type: MapPointType.mountain,
  },
  '9_17': {
    type: MapPointType.dot,
  },
  '9_18': {
    type: MapPointType.mountain,
  },
  '9_19': {
    type: MapPointType.dot,
  },
  '9_2': {
    type: MapPointType.mountain,
  },
  '9_20': {
    type: MapPointType.dot,
  },
  '9_21': {
    type: MapPointType.mountain,
  },
  '9_22': {
    type: MapPointType.dot,
  },
  '9_23': {
    type: MapPointType.mountain,
  },
  '9_24': {
    type: MapPointType.dot,
  },
  '9_25': {
    type: MapPointType.dot,
  },
  '9_26': {
    type: MapPointType.mountain,
  },
  '9_27': {
    type: MapPointType.mountain,
  },
  '9_28': {
    type: MapPointType.dot,
  },
  '9_29': {
    type: MapPointType.dot,
  },
  '9_3': {
    type: MapPointType.mountain,
  },
  '9_30': {
    type: MapPointType.dot,
  },
  '9_4': {
    type: MapPointType.mountain,
  },
  '9_5': {
    type: MapPointType.dot,
  },
  '9_6': {
    type: MapPointType.dot,
  },
  '9_7': {
    type: MapPointType.dot,
  },
  '9_8': {
    type: MapPointType.mountain,
  },
  '9_9': {
    type: MapPointType.mountain,
  },
};
