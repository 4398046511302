import { Graphics } from 'pixi.js';
import { Gizmo } from './gizmo';
import { Tile } from './tile';
import { WorldView } from './world-view';

export class WorldViewGismo extends Gizmo {
  private cellsContainer = new Graphics();

  constructor(private worldView: WorldView, private scale = 0.1) {
    super();
    this.init();
  }

  onInit(container: Graphics) {
    const viewBox = new Graphics();
    viewBox.lineStyle(1 / this.scale, 0xff0000);
    viewBox.beginFill(0xffffff, 0.2);
    viewBox.drawRect(0, 0, this.worldView.width, this.worldView.height);
    viewBox.endFill();
    viewBox.addChild(this.cellsContainer);

    container.addChild(viewBox);

    container.scale.copyFrom({
      x: this.scale,
      y: this.scale,
    });
  }

  onUpdate() {
    const container = this.cellsContainer;
    container.clear();
    container.lineStyle(1 / this.scale, 0x777777);

    const cells = this.worldView.visibleCells();
    cells.forEach(cell => {
      const hasTile = !!Tile.getTile(cell.col, cell.row);
      const pos = this.worldView.cellToWorldPoint(cell);

      if (hasTile) {
        container.beginFill(0xffffff, 0.2);
      }

      container.drawRect(pos.x, pos.y, this.worldView.cellSize, this.worldView.cellSize);

      if (hasTile) {
        container.endFill();
      }
    });
  }

  onDestroy() {
    //
  }
}
