import { Application as PixiApplication, Container, Graphics } from 'pixi.js';
import { Subject } from 'rxjs';
import { Point, PointLike, Vector } from '../point';

export interface PixiApplicationOptions {
  autoStart?: boolean;
  width?: number;
  height?: number;
  view?: HTMLCanvasElement;
  transparent?: boolean;
  autoDensity?: boolean;
  antialias?: boolean;
  preserveDrawingBuffer?: boolean;
  resolution?: number;
  forceCanvas?: boolean;
  backgroundColor?: number;
  clearBeforeRender?: boolean;
  forceFXAA?: boolean;
  powerPreference?: string;
  sharedTicker?: boolean;
  sharedLoader?: boolean;
  resizeTo?: Window | HTMLElement;
}

export class GameMouse {
  lastUiPosition = new Point();
  uiPosition = new Point();

  // get uiPosition(): Point {
  //   return this._uiPosition.clone();
  // }

  // set uiPosition(point: Point) {
  //   this._uiPosition.set(point);
  // }

  get uiPositionDelta(): Vector {
    return new Vector(this.lastUiPosition, this.uiPosition);
  }

  get worldPositionDelta(): Vector {
    return new Vector(
      this.game.viewTarget.uiToWorldPosition(this.lastUiPosition),
      this.game.viewTarget.uiToWorldPosition(this.uiPosition),
    );
  }

  isPressed = false;
  wasJustPressed = false;
  wheel = 0;

  isPressedRight = false;
  wasJustPressedRight = false;

  constructor(private readonly game: Game) {}

  get worldPosition(): PointLike {
    return this.game.viewTarget.uiToWorldPosition(this.uiPosition);
  }
}

export class ViewTarget {
  constructor(private game: Game, readonly position = new Point(), public scale = 1, public rotation = 0) {}

  uiToWorldPosition(point: PointLike): PointLike {
    const uiVector = new Vector(this.position).multiply(this.scale);
    const worldPoint = new Vector(point);

    worldPoint.rotate(this.rotation);
    worldPoint.add(uiVector);
    worldPoint.multiply(1 / this.scale);
    return worldPoint.toJSON();
  }

  get uiWalls() {
    const topLeft = this.topLeft;
    const bottomRight = this.bottomRight;
    return {
      top: topLeft.y,
      right: bottomRight.x,
      bottom: bottomRight.y,
      left: topLeft.x,
    };
  }

  get uiSize() {
    const coords = this.uiWalls;
    return {
      width: coords.right - coords.left,
      height: coords.bottom - coords.top,
    };
  }

  get topLeft() {
    return new Point(
      this.uiToWorldPosition({
        x: -this.game.width / 2,
        y: -this.game.height / 2,
      }),
    );
  }

  get bottomRight() {
    return new Point(
      this.uiToWorldPosition({
        x: this.game.width / 2,
        y: this.game.height / 2,
      }),
    );
  }

  get topRight() {
    return new Point(
      this.uiToWorldPosition({
        x: this.game.width / 2,
        y: -this.game.height / 2,
      }),
    );
  }

  get bottomLeft() {
    return new Point(
      this.uiToWorldPosition({
        x: -this.game.width / 2,
        y: this.game.height / 2,
      }),
    );
  }

  followMouse() {
    this.position.move(this.game.mouse.uiPositionDelta.invert().multiply(1 / this.scale));
  }
}

export class Game {
  readonly mouse = new GameMouse(this);
  readonly keyPressed = new Set<string>();
  readonly pixiApp: PixiApplication;
  readonly devicePixelRatio = window.devicePixelRatio || 1;
  readonly pixiWorld: Container;
  readonly pixiUI: Container;

  private onUpdateSubject = new Subject();

  readonly viewTarget = new ViewTarget(this);

  private windowSize!: { width: number; height: number };
  private container: HTMLDivElement;

  get width() {
    return this.windowSize.width;
  }

  get height() {
    return this.windowSize.height;
  }

  constructor(options: { container: HTMLDivElement; pixiOptions?: PixiApplicationOptions }) {
    this.container = options.container;
    window.onresize = () => {
      this.resize();
    };
    this.pixiApp = new PixiApplication(options.pixiOptions || {});
    this.resize();
    this.container.appendChild(this.pixiApp.view);

    this.container.onmousemove = ev => {
      this.mouse.uiPosition.set({
        x: ev.offsetX - this.width / 2,
        y: ev.offsetY - this.height / 2,
      });
    };

    document.onmousedown = ev => {
      ev.preventDefault();
      const isRightClick = ev.button === 2 || ev.ctrlKey;
      if (isRightClick) {
        this.mouse.isPressedRight = true;
        this.mouse.wasJustPressedRight = true;
      } else {
        this.mouse.wasJustPressed = true;
        this.mouse.isPressed = true;
      }
    };
    document.onmouseup = ev => {
      const isRightClick = ev.button === 2 || ev.ctrlKey || (this.mouse.isPressedRight && !this.mouse.isPressed);
      if (isRightClick) {
        this.mouse.isPressedRight = false;
      } else {
        this.mouse.isPressed = false;
      }
    };

    document.body.style.overflow = `hidden`;

    this.container.onmouseleave = () => {
      this.mouse.isPressed = false;
      this.mouse.isPressedRight = false;
    };

    this.container.oncontextmenu = ev => {
      ev.preventDefault();
    };

    document.addEventListener('keydown', ev => this.keyPressed.add(ev.key));
    document.addEventListener('keyup', ev => this.keyPressed.delete(ev.key));
    document.addEventListener('wheel', ev => (this.mouse.wheel = ev.deltaY));

    // Pixi

    this.pixiWorld = new Container();
    this.pixiWorld.sortableChildren = true;
    this.pixiApp.stage.addChild(this.pixiWorld);

    this.pixiUI = new Container();
    this.pixiApp.stage.addChild(this.pixiUI);

    this.pixiApp.ticker.add(() => {
      this.pixiUI.position.copyFrom({
        x: this.width / 2,
        y: this.height / 2,
      });

      // target the center of screen
      this.pixiWorld.position.copyFrom({
        x: this.width / 2,
        y: this.height / 2,
      });

      // match the view position
      this.pixiWorld.pivot.copyFrom({
        x: this.viewTarget.position.x,
        y: this.viewTarget.position.y,
      });

      // match the view scale
      this.pixiWorld.scale.copyFrom({ x: this.viewTarget.scale, y: this.viewTarget.scale });

      // match the rotation
      this.pixiWorld.rotation = -this.viewTarget.rotation;

      this.onUpdateSubject.next();

      this.mouse.lastUiPosition.set(this.mouse.uiPosition);
      this.mouse.wasJustPressed = false;
      this.mouse.wasJustPressedRight = false;
      this.mouse.wheel = 0;
    });
  }

  onPixiUpdate(cb: Function) {
    return this.onUpdateSubject.subscribe(() => cb());
  }

  private resize() {
    this.windowSize = {
      width: this.container.offsetWidth * devicePixelRatio,
      height: this.container.offsetHeight * devicePixelRatio,
    };

    const viewportScale = 1 / this.devicePixelRatio;
    this.pixiApp.renderer.resize(this.width, this.height);
    this.pixiApp.view.style.transform = `scale(${viewportScale})`;
    this.pixiApp.view.style.transformOrigin = `top left`;
  }

  showMouseIndicator() {
    const mouseRadius = 25;
    const mousePad = 5;

    const mouseIndicator = new Graphics();
    this.pixiUI.addChild(mouseIndicator);
    mouseIndicator.lineStyle(3, 0x999999, 1, 1);
    mouseIndicator.drawRoundedRect(
      0,
      0,
      (mouseRadius + mousePad) * 2 * 2,
      (mouseRadius + mousePad) * 2,
      mouseRadius + mousePad,
    );

    const leftMouseIndicator = new Graphics();
    mouseIndicator.addChild(leftMouseIndicator);
    leftMouseIndicator.beginFill(0x555555);
    leftMouseIndicator.drawCircle(mouseRadius + mousePad, mouseRadius + mousePad, mouseRadius);
    leftMouseIndicator.endFill();

    const rightMouseIndicator = new Graphics();
    mouseIndicator.addChild(rightMouseIndicator);
    rightMouseIndicator.beginFill(0x555555);
    rightMouseIndicator.drawCircle((mouseRadius + mousePad) * 3, mouseRadius + mousePad, mouseRadius);
    rightMouseIndicator.endFill();

    this.onPixiUpdate(() => {
      mouseIndicator.position.copyFrom(this.viewTarget.topLeft.move(mousePad, mousePad));
      leftMouseIndicator.alpha = this.mouse.isPressed ? 1 : 0.25;
      rightMouseIndicator.alpha = this.mouse.isPressedRight ? 1 : 0.25;
    });
  }
}
