<h1>Jesus Tempted in the Desert</h1>

<ng-template #lk>
  <h2>Luke 4:1-13</h2>

  <p><sup>1</sup> Jesus, full of the Holy Spirit, left the Jordan and was led by the Spirit into the wilderness, <sup>2</sup> where for forty days he was tempted by the devil. He ate nothing during those days, and at the end of them he was hungry.</p>
  <p><sup>3</sup> The devil said to him, "If you are the Son of God, tell this stone to become bread."</p>
  <p><sup>4</sup> Jesus answered, "It is written: 'Man shall not live on bread alone.'"</p>
  <p><sup>5</sup> The devil led him up to a high place and showed him in an instant all the kingdoms of the world. 6 And he said to him, "I will give you all their authority and splendor; it has been given to me, and I can give it to anyone I want to. 7 If you worship me, it will all be yours."</p>
  <p><sup>8</sup> Jesus answered, "It is written: 'Worship the Lord your God and serve him only.'"</p>
  <p><sup>9</sup> The devil led him to Jerusalem and had him stand on the highest point of the temple. "If you are the Son of God," he said, "throw yourself down from here. <sup>10</sup> For it is written:</p>
  <blockquote>"'He will command his angels concerning you<br />
      to guard you carefully;<br />
      <sup>11</sup> they will lift you up in their hands,<br />
      so that you will not strike your foot against a stone.'"<br />
  </blockquote>
  <p><sup>12</sup> Jesus answered, "It is said: 'Do not put the Lord your God to the test.'"</p>
  <p><sup>13</sup> When the devil had finished all this tempting, he left him until an opportune time.</p>
</ng-template>

<ng-template #mk>
  <h2>Mark 1:12-13</h2>

  <p><sup>12</sup> At once the Spirit sent him out into the wilderness, <sup>13</sup> and he was in the wilderness forty days, being tempted by Satan. He was with the wild animals, and angels attended him.</p>
</ng-template>

<ng-template #mt>
  <h2>Matthew 4:1-11</h2>

  <p><sup>1</sup> Then Jesus was led by the Spirit into the wilderness to be tempted by the devil. <sup>2</sup> After fasting forty days and forty nights, he was hungry. <sup>3</sup> The tempter came to him and said, "If you are the Son of God, tell these stones to become bread."
  <p><sup>4</sup> Jesus answered, "It is written: 'Man shall not live on bread alone, but on every word that comes from the mouth of God.'"</p>
  <p><sup>5</sup> Then the devil took him to the holy city and had him stand on the highest point of the temple. <sup>6</sup> "If you are the Son of God," he said, "throw yourself down. For it is written:</p>
  <blockquote>"'He will command his angels concerning you,<br />
      and they will lift you up in their hands,<br />
      so that you will not strike your foot against a stone.'"
  </blockquote>
  <p><sup>7</sup> Jesus answered him, "It is also written: 'Do not put the Lord your God to the test.'"</p>
  <p><sup>8</sup> Again, the devil took him to a very high mountain and showed him all the kingdoms of the world and their splendor. 9 "All this I will give you," he said, "if you will bow down and worship me."</p>
  <p><sup>10</sup> Jesus said to him, "Away from me, Satan! For it is written: 'Worship the Lord your God, and serve him only.'"</p>
  <p><sup>11</sup> Then the devil left him, and angels came and attended him.</p>
</ng-template>

<section class="mini">
  <ng-template [ngTemplateOutlet]="mt"></ng-template>
</section>

<section class="mini">
  <ng-template [ngTemplateOutlet]="mk"></ng-template>
</section>

<section class="mini">
  <ng-template [ngTemplateOutlet]="lk"></ng-template>
</section>

<section page>
  <h2>More References</h2>

  <h3>#1: Lust of the Flesh</h3>
  <blockquote>
    <em>
      <p>Matthew 4:3-4</p>
      <p>Luke 4:3-4</p>
    </em>
  </blockquote>
  <h4>Deuteronomy 8:3</h4>
  <blockquote>
    <p>He humbled you, causing you to hunger and then feeding you with manna, which neither you nor your ancestors had known, to teach you that man does not live on bread alone but on every word that comes from the mouth of the Lord.</p>
  </blockquote>

  <h4>Hebrews 4:15-16</h4>
  <blockquote>
    <p>
      <sup>15</sup> we do not have a high priest who is unable to empathize with our weaknesses, but we have one who has been tempted in every way, just as we are—yet he did not sin. <sup>16</sup> Let us then approach God's throne of grace with confidence, so that we may receive mercy and find grace to help us in our time of need.
    </p>
  </blockquote>

  <h3>#2: Lust of the Eyes</h3>
  <blockquote><em>
    <p>Matthew 4:8-10</p>
    <p>Luke 4:5-8</p>
  </em></blockquote>
  <h4>Deuteronomy 6:13</h4>
  <blockquote>
    <p>Fear the Lord your God, serve him only and take your oaths in his name.</p>
  </blockquote>

  <h3>#3: Boastful Pride of Life</h3>
  <blockquote><em>
    <p>Matthew 4:5-7</p>
    <p>Luke 4:9-12</p>
  </em></blockquote>
  <h4>Psalm 91:11-12</h4>
  <blockquote>
      <p><sup>11</sup> For he will command his angels concerning you<br />
    to guard you in all your ways;<br />
    <sup>12</sup> they will lift you up in their hands,<br />
    so that you will not strike your foot against a stone.</p>
    </blockquote>

    <h4>Deuteronomy 6:16</h4>
    <blockquote>
      <p>Do not put the Lord your God to the test as you did at Massah.</p>
    </blockquote>
</section>

<section page print>
  <ng-template [ngTemplateOutlet]="mt"></ng-template>
</section>

<section page print>
  <ng-template [ngTemplateOutlet]="mk"></ng-template>
</section>

<section page print>
  <ng-template [ngTemplateOutlet]="lk"></ng-template>
</section>

<ng-template #questions>
  <ol>
    <li>Put yourself in Jesus' shoes (sandals?). Focusing on <em>this</em> temptation, what was Jesus going through? Why was it even a temptation? What reasons would Jesus have to follow through with Satan's offer?</li>
    <li>Have you have beaten one temptation, just to have another follow on its heals?</li>
    <li>Is this a parable? Or was he <em>actually</em> tempted in these literal ways.</li>
  </ol>
</ng-template>

<section page screen>
  <h2>Small Group Questions</h2>
  <ng-template [ngTemplateOutlet]="questions"></ng-template>
</section>

<section page print>
  <h2 class="split">
    <span>Small Group Questions</span>
    <span>1</span>
  </h2>
  <h3>Jesus is tempted to turn stone into bread</h3>
  <blockquote>
    <em>
      <p>Matthew 4:3-4</p>
      <p>Luke 4:3-4</p>
    </em>
  </blockquote>
  <ng-template [ngTemplateOutlet]="questions"></ng-template>
</section>

<section page print>
  <h2 class="split">
    <span>Small Group Questions</span>
    <span>2</span>
  </h2>
  <h3>Jesus is tempted to worship Satan</h3>
  <blockquote><em>
    <p>Matthew 4:8-10</p>
    <p>Luke 4:5-8</p>
  </em></blockquote>
  <ng-template [ngTemplateOutlet]="questions"></ng-template>
</section>

<section page print>
  <h2 class="split">
    <span>Small Group Questions</span>
    <span>3</span>
  </h2>
  <h3>Jesus is tempted to test God</h3>
  <blockquote><em>
    <p>Matthew 4:5-7</p>
    <p>Luke 4:9-12</p>
  </em></blockquote>
  <ng-template [ngTemplateOutlet]="questions"></ng-template>
</section>

<section page>
  <h2>Large Group Questions <em>(optional)</em></h2>
  <ol>
    <li>Have any of you fasted for an extended amount of time? How did that feel?</li>
    <li>How can you tell the difference between external temptation and your own faults? Does temptations always come from Satan?</li>
    <li>If I'm being tempted, does that mean I am weak? Living in sin? Is it a sin to be tempted?</li>
    <li>Was Jesus actually tempted? If Jesus was 100% God, was he able to simply brush these off?</li>
    <li>Is it possible to have a span of time in your life totally free from all temptations?</li>
    <li>Have you ever had so <em>many</em> temptations at once that you find yourself proud of being so righteous for resisting them?</li>
    <li>Is it a sin to do something you genuinely don't recognize as wrong.</li>
  </ol>
</section>
