export function rand(max = 1, min = 0) {
  return Math.random() * (max - min) + min;
}

export function randInt(max = 1, min = 0) {
  return Math.round(rand(max, min));
}

export function randItem<T>(items: T[]): T {
  return items[randInt(items.length - 1)];
}
