import { Graphics, Text } from 'pixi.js';
import { πh } from 'src/app/pi';
import { CoordLike, GridMath } from './grid-math';

const sqrt3 = Math.sqrt(3);

export enum MapPointType {
  dne = 'dne',
  dot = 'dot',
  mountain = 'mountain',
  circleCity = 'circleCity',
  squareCity = 'squareCity',
  hexCity = 'hexCity',
}

export class MapPoint {
  private readonly col: number;
  private readonly row: number;
  private _type: MapPointType;
  readonly graphics = new Graphics();
  readonly id: string;

  constructor(coord: CoordLike, readonly gridMath: GridMath, type = MapPointType.dot, public readonly name?: string) {
    this.col = coord.col;
    this.row = coord.row;
    this._type = type;
    this.id = MapPoint.id(coord);

    this.draw();
  }

  static id(coord: CoordLike): string {
    return `${coord.col}_${coord.row}`;
  }

  static coord(id: string): CoordLike {
    const [colString, rowString] = id.split('_');
    const col = parseInt(colString);
    const row = parseInt(rowString);
    return { col, row };
  }

  get coord(): CoordLike {
    return {
      col: this.col,
      row: this.row,
    };
  }

  get type() {
    return this._type;
  }

  set type(type: MapPointType) {
    this._type = type;
    this.draw();
  }

  private draw() {
    const dotRadius = 2;
    const mountainRadius = 4;
    const cityRadius = dotRadius * 4;
    const majorCityRadius = this.gridMath.size + dotRadius;
    const majorCityInnerRadius = (majorCityRadius * sqrt3) / 2;

    this.graphics.clear();
    this.graphics.zIndex = this.type === MapPointType.hexCity ? 1 : 2;

    switch (this._type) {
      case MapPointType.mountain:
        this.graphics.beginFill(0x333333);
        this.graphics.moveTo(0, -mountainRadius * 2);
        this.graphics.lineTo(mountainRadius * sqrt3, mountainRadius);
        this.graphics.lineTo(-mountainRadius * sqrt3, mountainRadius);
        this.graphics.endFill();
        break;

      case MapPointType.circleCity:
        // red circle
        this.graphics.beginFill(0xcc2200);
        this.graphics.drawCircle(0, 0, cityRadius);
        this.graphics.endFill();

        // dot
        this.graphics.beginFill(0x000000);
        this.graphics.drawCircle(0, 0, dotRadius);
        this.graphics.endFill();
        break;

      case MapPointType.squareCity:
        // red square
        this.graphics.beginFill(0xcc2200);
        this.graphics.drawRect(-cityRadius, -cityRadius, cityRadius * 2, cityRadius * 2);
        this.graphics.endFill();

        // dot
        this.graphics.beginFill(0x000000);
        this.graphics.drawCircle(0, 0, dotRadius);
        this.graphics.endFill();
        break;

      case MapPointType.hexCity:
        // hexagon
        this.graphics.beginFill(0xcc2200);
        this.graphics.drawStar(0, 0, 6, majorCityRadius, majorCityInnerRadius);
        this.graphics.endFill();

        // dot
        this.graphics.beginFill(0x000000);
        this.graphics.drawCircle(0, 0, dotRadius);
        this.graphics.endFill();
        break;

      case MapPointType.dot:
        this.graphics.beginFill(0x444444);
        this.graphics.drawCircle(0, 0, dotRadius);
        this.graphics.endFill();
        break;

      default:
        this.graphics.beginFill(0x000000, 0.25);
        this.graphics.drawStar(0, 0, 4, dotRadius, dotRadius / 5, πh / 2);
        this.graphics.endFill();
    }
  }
}
