import { Bodies, Body } from 'matter-js';
import { Sprite } from 'pixi.js';
import { πh } from 'src/app/pi';
import { Point, PointLike, Vector } from '../../point';
import { Game } from '../game';
import { Entity } from './entity';

export class Spaceship extends Entity {
  readonly body: Body;
  readonly graphics: Sprite;

  constructor(position: PointLike = Point.center) {
    super();
    // pixijs
    const graphics = Sprite.from('assets/spaceship-1.png');
    graphics.scale.copyFrom({ x: 0.2, y: 0.2 });
    graphics.anchor.copyFrom({ x: 0.5, y: 0.5 });
    this.graphics = graphics;

    // physics
    const radius = Math.max(128 * graphics.scale.x, 132 * graphics.scale.y) / 2;
    const body = Bodies.circle(position.x, position.y, radius, {
      frictionAir: 0.02,
    });
    this.body = body;
  }

  thrust(magnitude = 1) {
    const force = new Vector(this.body.angle).normalize(magnitude);
    Body.applyForce(this.body, this.body.position, force);
  }

  strafe(magnitude = 1) {
    const force = new Vector(this.body.angle + πh).normalize(magnitude);
    Body.applyForce(this.body, this.body.position, force);
  }

  turn(angle: number) {
    this.body.torque = angle / 100;
  }

  onInit(game: Game) {
    game.addPhysicsBody(this.body);
    game.pixiWorld.addChild(this.graphics);
  }

  onUpdate() {
    this.graphics.rotation = this.body.angle;
    this.graphics.position.copyFrom(this.body.position);
  }

  onDestroy(game: Game) {
    game.removePhysicsBody(this.body);
    game.pixiWorld.removeChild(this.graphics);
  }
}
