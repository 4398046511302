import { Bodies, Body } from 'matter-js';
import { Graphics } from 'pixi.js';
import { π } from 'src/app/pi';
import { rand } from 'src/app/rand';
import { rgb } from 'src/app/rgb';
import { Point, PointLike, Vector, Pointer } from '../point';
import { Entity, Tag, UpdateData } from './entity';
import { Spaceship } from './spaceship';
import { Universe } from './universe.interface';

export class Asteroid extends Entity {
  readonly physBody: Body;
  readonly pixiBody: Graphics;

  readonly gravity = new Vector();

  radius: number;
  health: number;

  targetee?: Entity;

  get position() {
    return {
      x: this.physBody.position.x,
      y: this.physBody.position.y,
    };
  }

  constructor(
    private universe: Universe,
    {
      position = Point.center,
      velocity = Point.center,
      angularVelocity = 0,
      radius = 1,
    }: {
      position: PointLike;
      radius: number;
      velocity?: PointLike;
      angularVelocity?: number;
    },
  ) {
    super(universe);

    this.tags.add(Tag.asteroid);

    this.radius = radius;
    this.health = 1;

    // Rendering
    this.pixiBody = new Graphics();
    const c = rand(0.6, 0.8);
    this.pixiBody.beginFill(rgb(c, c, c));
    this.pixiBody.drawStar(0, 0, 6, radius, (radius * Math.sqrt(3)) / 2);
    this.pixiBody.endFill();

    // this.pixiBody.beginFill(0x333333);
    // this.pixiBody.drawCircle(0, -radius / 2, radius / 15);
    // this.pixiBody.endFill();

    // this.pixiBody.beginFill(0x333333);
    // this.pixiBody.drawCircle(0, radius / 2, radius / 15);
    // this.pixiBody.endFill();

    this.registerPixiBody(this.pixiBody);

    // Physics
    this.physBody = Bodies.polygon(position.x, position.y, 6, radius, {
      restitution: 0,
      friction: 0,
      label: 'asteroid',
      frictionAir: 0,
    });

    Body.setVelocity(this.physBody, velocity);
    Body.setAngularVelocity(this.physBody, angularVelocity);

    this.registerPhysBody(this.physBody);
  }

  // push(force: PointLike) {
  //   console.log(`Push!`, force);

  //   // Body.applyForce(this.physBody, this.physBody.position, force);
  //   Body.setVelocity(this.physBody, new Vector(this.physBody.velocity).add(force));
  // }

  onUpdate(data: UpdateData) {
    super.onUpdate(data);

    if (
      this.position.x < 0 ||
      this.position.x > this.app.view.width ||
      this.position.y < 0 ||
      this.position.y > this.app.view.height
    ) {
      this.destroy();
      return;
    }

    this.gravity.magnitude = 0;

    this.entities
      .filter(e => e.hasTag(Tag.asteroid))
      .forEach(asteroid => {
        const force = new Vector(this.position, asteroid.position);
        const dist = force.magnitude;
        if (dist < 1) {
          return;
        }

        // @ts-ignore
        const r: number = asteroid.radius;
        let g = 0;

        if (true) {
          const mass1 = Math.pow(r, 2) * π;
          const mass2 = Math.pow(this.radius, 2) * π;
          const c = 0.00001;
          g = (c * (mass1 + mass2)) / Math.pow(dist, 1.8);
        } else {
          const c = 0.001;
          const o = Math.pow((r + this.radius) * 2, 1.5);
          // const g = c * (mass1 + mass2) * (Math.atan(Math.pow(dist - o, 2)) / (dist - o));
          g = c * ((dist - o) / Math.pow(dist, 1.1));
        }

        force.normalize(g);
        this.gravity.add(force);
      });
  }

  onDestroy() {
    if (this.targetee) {
      (this.targetee as Spaceship).dropTarget();
    }
    super.onDestroy();
  }

  onDraw() {
    this.pixiBody.rotation = this.physBody.angle;
    this.pixiBody.position.copyFrom(this.physBody.position);
    this.pixiBody.width = this.pixiBody.width;
    this.pixiBody.height = this.pixiBody.height;
    this.pixiBody.tint = this.targetee ? 0xff9900 : 0xffffff;
  }

  handleCollision(entity: Entity) {
    if (entity.hasTag(Tag.bullet) || entity.hasTag(Tag.ship)) {
      this.takeHit();
    }
  }

  takeHit() {
    console.log(`Asteroid hit!`);
    this.health--;
    if (this.health === 0) {
      this.destroy();

      if (this.radius > 15) {
        const velocity = new Vector(rand(-1, 1), rand(-1, 1)).normalize(rand(1, 5));
        new Asteroid(this.universe, {
          position: new Point(this.physBody.position).move(-2, 0),
          velocity,
          radius: this.radius / 2,
          angularVelocity: rand(-0.1, 0.1),
        });

        new Asteroid(this.universe, {
          position: new Point(this.physBody.position).move(2, 0),
          velocity: velocity.invert(),
          radius: this.radius / 2,
          angularVelocity: rand(-0.1, 0.1),
        });
      }
    }
  }
}
