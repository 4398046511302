import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { BLEND_MODES, Graphics } from 'pixi.js';
import { π } from 'src/app/pi';
import { rand } from 'src/app/rand';
import { Point } from '../point';
import { Game } from './game';
import { Blob } from './game/blob';
import { DirectionalGizmo } from './game/directional-gizmo';
import { Spaceship } from './game/spaceship';

@Component({
  selector: 'app-camera-control',
  templateUrl: './camera-control.component.html',
  styleUrls: ['./camera-control.component.scss'],
})
export class CameraControlComponent implements OnInit {
  constructor(readonly elementRef: ElementRef<HTMLDivElement>, protected ngZone: NgZone) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      const game = new Game({
        container: this.elementRef.nativeElement,
        pixiOptions: {
          antialias: true,
          backgroundColor: 0xcccccc,
        },
      });

      // this.addUiCursor(game);
      // this.addWorldCursor(game);
      this.addStars(game);

      // this.addSpaceship(game);

      // for (let i = 0; i < 100; i++) {
      //   new DirectionalGizmo(
      //     {
      //       x: rand(-game.width / 2, game.width / 2),
      //       y: rand(-game.height / 2, game.height / 2),
      //     },
      //     16,
      //     16,
      //   ).addToGame(game);
      // }
      // new DirectionalGizmo(new Point(300, 200), 32, 3).addToGame(game);
      // new DirectionalGizmo(new Point(300, -200), 32, 3).addToGame(game);

      const blobs: Blob[] = [];
      for (let i = 0; i < 20; i++) {
        const blob = new Blob(
          {
            x: rand(-game.width / 2, game.width / 2),
            y: rand(-game.height / 2, game.width / 2),
          },
          12,
        ).addToGame(game);
        blobs.push(blob);
      }

      game.onPixiUpdate(() => {
        // scroll with the wheel
        game.viewTarget.scale *= 1 + game.mouse.wheel / 1000;

        blobs.forEach(blobA => {
          // const dist = new Point(blobA.body.position).distanceTo(game.mouse.worldPosition) / 10;
          // const weight = 10 / (dist + 5);

          blobA.directions.reset();

          if (game.mouse.isPressed) {
            blobA.directions.addConsideration(game.mouse.worldPosition, 1);
          }

          blobs.forEach(blobB => {
            if (blobA !== blobB) {
              const dist = Point.distance(blobA.body.position, blobB.body.position);
              blobA.directions.addConsideration(blobB.body.position, Math.min(0, -10 / dist + 0.1));
              // if (dist < 100) {
              // }
            }
          });
        });

        // game.viewTarget.position.set(blobs[0].body.position);
      });
    });
  }

  private addUiCursor(game: Game) {
    const cursor = new Graphics();
    cursor.lineStyle(1, 0x000099);
    cursor.drawCircle(0, 0, 10);
    game.pixiUI.addChild(cursor);
    game.onPixiUpdate(() => cursor.position.copyFrom(game.mouse.uiPosition));
  }

  private addWorldCursor(game: Game) {
    const r = 10;
    const cursor = new Graphics();
    cursor.beginFill(0xccccff);
    cursor.drawStar(0, 0, 4, r, r / 5);
    cursor.endFill();
    cursor.blendMode = BLEND_MODES.ADD;
    game.pixiWorld.addChild(cursor);
    game.onPixiUpdate(() => {
      cursor.position.copyFrom(game.mouse.worldPosition);
      cursor.scale.copyFrom({
        x: 1 / game.viewTarget.scale,
        y: 1 / game.viewTarget.scale,
      });
    });
  }

  private addStars(game: Game) {
    const stars = new Graphics();
    stars.beginFill(0x999999);
    for (let i = 0; i < 5000; i++) {
      stars.drawCircle(rand(-game.width / 2, game.width / 2), rand(-game.height / 2, game.height / 2), 1);
    }
    stars.endFill();
    stars.blendMode = BLEND_MODES.ADD;
    game.pixiWorld.addChild(stars);

    // if (game.mouse.wasJustPressed) {
    //   const { x, y } = game.mouse.worldPosition;
    //   target.set({ x, y });
    //   stars.beginFill(0xccffcc);
    //   stars.drawStar(x, y, 4, 10 / game.viewTarget.scale, 3 / game.viewTarget.scale);
    //   stars.endFill();
    // }
  }

  private addSpaceship(game: Game) {
    const spaceship = new Spaceship();
    spaceship.addToGame(game);

    game.onPixiUpdate(() => {
      game.viewTarget.position.set(spaceship.body.position);
      game.viewTarget.rotation = spaceship.body.angle + π / 2;

      if (game.keyPressed.has('w')) {
        spaceship.thrust(0.001);
      }
      if (game.keyPressed.has('s')) {
        spaceship.thrust(-0.002);
      }
      if (game.keyPressed.has('e')) {
        spaceship.strafe(0.001);
      }
      if (game.keyPressed.has('q')) {
        spaceship.strafe(-0.001);
      }
      if (game.keyPressed.has('a')) {
        spaceship.turn(-0.1);
      }
      if (game.keyPressed.has('d')) {
        spaceship.turn(0.1);
      }
    });
  }
}
