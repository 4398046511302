import { Component, OnDestroy, OnInit } from '@angular/core';

import { PixiComponent } from '../pixi-component';
import { Point, Vector } from '../point';
import { Hexagon, HexGlobals } from './hexagon';

@Component({
  selector: 'app-pixi',
  templateUrl: './pixijs-hello-world.component.html',
  styleUrls: ['./pixijs-hello-world.component.scss'],
})
export class PixijsHelloWorldComponent extends PixiComponent implements OnInit, OnDestroy {
  waterLevel = 0.19;
  masterScale = 1.83;
  edge = 0.25;
  elevationScale = 100;

  octaveAAmplitude = 1;
  octaveAWavelength = 700;

  octaveBAmplitude = 0.11;
  octaveBWavelength = 198.11;

  octaveCAmplitude = 0.03;
  octaveCWavelength = 66.33;

  readonly offset = new Point();
  private readonly dragStart = new Point();

  private isDragging = false;
  private logStats = true;

  startDragging() {
    this.dragStart.set(this.mouse);
    this.isDragging = true;
    // console.log(`Starting the drag`);
    this.logStats = true;
  }

  stopDragging() {
    this.isDragging = false;
    // console.log(`Stopping the drag`);
  }

  get globals(): HexGlobals {
    return {
      waterLevel: this.waterLevel,
      masterScale: this.masterScale,
      octaveAWavelength: this.octaveAWavelength,
      octaveAAmplitude: this.octaveAAmplitude,
      octaveBWavelength: this.octaveBWavelength,
      octaveBAmplitude: this.octaveBAmplitude,
      octaveCWavelength: this.octaveCWavelength,
      octaveCAmplitude: this.octaveCAmplitude,
      edge: this.edge,
      offset: this.offset,
      center: this.center,
      elevationScale: this.elevationScale,
    };
  }

  // constructor(private elementRef: ElementRef, private ngZone: NgZone) {
  //   super(elementRef, ngZone);
  // }

  init() {
    this.elementRef.nativeElement.onmousemove = (ev: MouseEvent) => {
      this.mouse.x = ev.offsetX;
      this.mouse.y = ev.offsetY;
    };
    this.elementRef.nativeElement.onmousedown = () => {
      this.startDragging();
    };
    this.elementRef.nativeElement.onmouseup = () => {
      this.stopDragging();
    };
    this.elementRef.nativeElement.onmousewheel = (ev: WheelEvent) => {
      this.masterScale *= ev.deltaY > 0 ? 1.1 : 0.9;
    };

    this.offset.set({
      // x: -2729.11,
      // y: 360.87,
      // x: -3843.05,
      // y: -867.01,
      x: -6225.87,
      y: -907.16,
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.init();
    this.animate();
  }

  private async animate() {
    const app = this.app;
    console.log(`Animating`);

    await this.load('isoHex', 'iso-hex.png');
    await this.load('tree', 'tree.png');
    await this.load('rock-1', 'rock-1.png');
    await this.load('rock-2', 'rock-2.png');
    await this.load('rock-3', 'rock-3.png');
    await this.load('rock-4', 'rock-4.png');

    const hexagons: Record<string, Hexagon> = {};

    for (let c = 0; c < Math.ceil(app.view.width / 28) + 1; c++) {
      for (let r = 0; r < Math.ceil(app.view.height / 16) + 1; r++) {
        const x = c * 28 + (r % 2) * 14;
        const y = r * 16;

        const id = `${c}_${r}`;
        // console.log(`Adding ${id}`);
        const hex = new Hexagon(app, {
          id,
          x,
          y,
        });
        hexagons[id] = hex;
      }
    }

    let lastGlobals = '';
    app.ticker.add(() => {
      if (this.isDragging) {
        const delta = new Vector(this.dragStart, this.mouse);
        delta.multiply(1 / this.masterScale);
        this.offset.move(delta);
        this.dragStart.set(this.mouse);
      }

      if (lastGlobals !== JSON.stringify(this.globals)) {
        this.updateHexagons(hexagons);
        lastGlobals = JSON.stringify(this.globals);
      }

      if (this.logStats) {
        const minElevation = Math.min(...Object.values(hexagons).map(hex => hex.elevation(this.globals)));
        const maxElevation = Math.max(...Object.values(hexagons).map(hex => hex.elevation(this.globals)));

        console.log(`min: ${minElevation}`);
        console.log(`max: ${maxElevation}`);

        this.logStats = false;
      }
    });

    console.log(hexagons);
  }

  private updateHexagons(hexagons: Record<string, Hexagon>) {
    Object.values(hexagons).forEach(hex => hex.update(this.globals));
    // console.log('Updated hexagons');
  }
}
