<div class="days">
  <span>{{ dayCount$ | async }}</span>
  <span class="dim"> {{ (dayCount$ | async) === '1' ? 'day' : 'days' }}</span>
</div>
<div class="time">
  <span>{{ hh$ | async }}</span>
  <span class="dim">:</span>
  <span>{{ mm$ | async }}</span>
  <span class="dim">:</span>
  <span>{{ ss$ | async }}</span>
</div>
