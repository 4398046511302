import { AfterViewInit, Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { Graphics, Sprite } from 'pixi.js';
import { Game } from 'src/app/pages/camera-control/game';
import { PointLike, Vector } from 'src/app/pages/point';
import { tiles } from '../aoc201815a/tiles';

enum Entity {
  goblin = 'G',
  elf = 'E',
}

interface Point3 {
  x: number;
  y: number;
  z: number;
}

class Tile {
  neighbor: Partial<{
    left: Tile;
    right: Tile;
    up: Tile;
    down: Tile;
  }> = {};

  readonly id: string;
  readonly x: number;
  readonly y: number;
  readonly z: number;

  get position() {
    return {
      x: this.x,
      y: this.y,
      z: this.z,
    };
  }

  static id(x: number, y: number, z: number): string;
  static id(position: Point3): string;
  static id(...args: any[]): string {
    if (args.length === 1) {
      return `${args[0].x}__${args[0].y}__${args[0].z}`;
    }
    return `${args[0]}__${args[1]}__${args[2]}`;
  }

  constructor(public readonly walkable: boolean, position: Point3, entity?: Entity) {
    this.id = Tile.id(position);
    this.x = position.x;
    this.y = position.y;
    this.z = position.z;
  }
}

function isoCoord(coords: Point3): PointLike;
function isoCoord(x: number, y: number, z?: number): PointLike;
function isoCoord(a: Point3 | number, b?: number, c?: number): PointLike {
  const x = (typeof a === 'number' ? a : a.x) ?? 0;
  const y = (typeof a === 'number' ? b : a.y) ?? 0;
  const z = (typeof a === 'number' ? c : a.z) ?? 0;

  return {
    x: x + y,
    y: x * -0.5 + y * 0.5 - z,
  };
}

@Component({
  selector: 'app-aoc201815a-pixi',
  templateUrl: './aoc201815a-pixi.component.html',
  styleUrls: ['./aoc201815a-pixi.component.scss'],
})
export class Aoc201815aPixiComponent implements AfterViewInit {
  constructor(readonly elementRef: ElementRef<HTMLDivElement>, protected ngZone: NgZone) {}

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.init();
    });
  }

  init() {
    this.ngZone.runOutsideAngular(() => {
      const game = new Game({
        container: this.elementRef.nativeElement,
        pixiOptions: {
          // antialias: true,
          backgroundColor: 0xffffff,
        },
      });

      function scale() {
        return Math.min(game.pixiApp.view.width, game.pixiApp.view.height) / 8;
      }

      const tilesMap = new Map<string, Tile>();
      let maxX = 0;
      let maxY = 0;
      tiles
        .split('\n')
        .filter(x => x.length > 0)
        .map((line, y) =>
          line
            .split('')
            .map(
              (t, x) =>
                new Tile(t !== '#', { x, y, z: 0 }, t === 'E' ? Entity.elf : t === 'G' ? Entity.goblin : undefined),
            ),
        )
        .forEach(tileRow => {
          tileRow.forEach(tile => {
            maxX = Math.max(maxX, tile.x);
            maxY = Math.max(maxY, tile.y);
            tilesMap.set(tile.id, tile);
          });
        });

      for (const tile of tilesMap.values()) {
        const upId = Tile.id(tile.x, tile.y - 1, 0);
        const up = tilesMap.get(upId);
        if (up) {
          tile.neighbor.up = up;
        }

        const rightId = Tile.id(tile.x + 1, tile.y, 0);
        const right = tilesMap.get(rightId);
        if (right) {
          tile.neighbor.right = right;
        }

        const downId = Tile.id(tile.x, tile.y + 1, 0);
        const down = tilesMap.get(downId);
        if (down) {
          tile.neighbor.down = down;
        }

        const leftId = Tile.id(tile.x - 1, tile.y, 0);
        const left = tilesMap.get(leftId);
        if (left) {
          tile.neighbor.left = left;
        }
      }

      function moveTo(graphics: Graphics, x3: number, y3: number, z3 = 0) {
        const { x, y } = isoCoord(x3, y3, z3);
        graphics.moveTo(x, y);
      }
      function lineTo(graphics: Graphics, x3: number, y3: number, z3 = 0) {
        const { x, y } = isoCoord(x3, y3, z3);
        graphics.lineTo(x, y);
      }

      const px = 1 / scale();
      const pxh = px / 2;
      const drawWalls = true;
      const allWalls = false;
      const wallHeight = 0.3;
      const drawEdges = false;

      for (const tile of tilesMap.values()) {
        if (!tile.walkable) {
          continue;
        }
        const graphics = new Graphics();
        graphics.beginFill(0xeeeeee);
        moveTo(graphics, pxh, pxh);
        lineTo(graphics, 1 - pxh, pxh);
        lineTo(graphics, 1 - pxh, 1 - pxh);
        lineTo(graphics, pxh, 1 - pxh);
        lineTo(graphics, pxh, pxh);
        graphics.endFill();

        if (drawWalls) {
          // right (down) wall
          if (allWalls || !tile.neighbor.down?.walkable) {
            graphics.beginFill(0xcccccc);
            moveTo(graphics, pxh, 1 - pxh);
            lineTo(graphics, 1 - pxh, 1 - pxh);
            lineTo(graphics, 1 - pxh, 1 - pxh, -wallHeight);
            lineTo(graphics, pxh, 1 - pxh, -wallHeight);
            graphics.endFill();
          }

          // left wall
          if (allWalls || !tile.neighbor.left?.walkable) {
            graphics.beginFill(0xdddddd);
            moveTo(graphics, pxh, pxh);
            lineTo(graphics, pxh, 1 - pxh);
            lineTo(graphics, pxh, 1 - pxh, -wallHeight);
            lineTo(graphics, pxh, pxh, -wallHeight);
            graphics.endFill();
          }
        }

        if (drawEdges) {
          // down edge
          graphics.lineStyle(px * 2, 0xbbbbbb);
          if (!tile.neighbor.down?.walkable) {
            moveTo(graphics, 0, 1);
            lineTo(graphics, 1, 1);
          }

          // up edge
          if (!tile.neighbor.up?.walkable) {
            moveTo(graphics, 0, 0);
            lineTo(graphics, 1, 0);
          }

          // left edge
          if (!tile.neighbor.left?.walkable) {
            moveTo(graphics, 0, 0);
            lineTo(graphics, 0, 1);
          }

          // right edge
          if (!tile.neighbor.right?.walkable) {
            moveTo(graphics, 1, 0);
            lineTo(graphics, 1, 1);
          }
        }

        graphics.pivot.copyFrom(isoCoord(0.5, 0.5));
        graphics.position.copyFrom(isoCoord(tile));
        graphics.zIndex = graphics.position.y;
        game.pixiWorld.addChild(graphics);
      }

      game.pixiWorld.sortableChildren = true;
      game.pixiWorld.sortChildren();

      const targetTile = tilesMap.get(Tile.id(23, 7, 0));

      const drawTarget = true;
      if (drawTarget) {
        const target = new Graphics();
        target.lineStyle(1 / scale(), 0x990000);
        const w2 = game.pixiApp.view.width / (2 * scale());
        const h2 = game.pixiApp.view.height / (2 * scale());
        target.moveTo(-w2, 0);
        target.lineTo(w2, 0);
        target.moveTo(0, -h2);
        target.lineTo(0, h2);
        target.zIndex = 100;
        game.pixiWorld.addChild(target);

        if (targetTile) {
          target.position.copyFrom(isoCoord(targetTile));
        }
      }

      game.onPixiUpdate(() => {
        if (targetTile) {
          game.viewTarget.position.set(isoCoord(targetTile));
          // const offset = new Vector(new Date().getTime() / 100);
          // game.viewTarget.position.move(offset);
        }
        game.viewTarget.scale = scale();
      });
    });
  }
}
