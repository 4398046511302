import { Bodies, Body } from 'matter-js';
import { Graphics } from 'pixi.js';
import { Point, PointLike, Vector, Pointer } from '../point';
import { Entity, Tag, UpdateData } from './entity';
import { Universe } from './universe.interface';

export class Wall extends Entity {
  readonly physBody: Body;
  readonly pixiBody: Graphics;

  get position() {
    return {
      x: this.physBody.position.x,
      y: this.physBody.position.y,
    };
  }

  constructor(universe: Universe, position: PointLike = Point.center, width: number, height: number) {
    super(universe);

    this.tags.add(Tag.wall);

    // render
    this.pixiBody = new Graphics();
    this.pixiBody.position.copyFrom(position);
    this.pixiBody.beginFill(0xffffff);
    this.pixiBody.drawRect(-width / 2, -height / 2, width, height);
    this.pixiBody.endFill();
    this.registerPixiBody(this.pixiBody);

    // physics
    this.physBody = Bodies.rectangle(position.x, position.y, width, height, {
      isStatic: true,
    });
    this.registerPhysBody(this.physBody);
  }

  onDraw() {
    this.pixiBody.rotation = this.physBody.angle;
    this.pixiBody.position.copyFrom(this.physBody.position);
  }
}
