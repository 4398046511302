import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

@Component({
  selector: 'app-cipher',
  templateUrl: './cipher.component.html',
  styleUrls: ['./cipher.component.scss'],
})
export class CipherComponent implements OnInit, OnDestroy {
  private inputSubject = new BehaviorSubject<string>(window.localStorage.getItem('cipher-input') || '');
  readonly inputObserver = this.inputSubject.pipe(distinctUntilChanged());
  set input(val: string) {
    this.inputSubject.next(val);
  }
  get input() {
    return this.inputSubject.value;
  }

  private stepsSubject = new BehaviorSubject<string>(window.localStorage.getItem('cipher-steps') || '0');
  readonly stepsObserver = this.stepsSubject.pipe(distinctUntilChanged());
  set steps(val: string) {
    this.stepsSubject.next(val);
  }
  get steps() {
    return this.stepsSubject.value;
  }

  readonly stepsArray = this.stepsObserver.pipe(map(s => s.split(' ').map(s => letters.length - parseInt(s, 10))));

  readonly output = combineLatest([this.inputObserver, this.stepsArray]).pipe(
    map(([input, steps]) => {
      let i = -1;
      return input
        .split('')
        .map(c => {
          if (this.isLetter(c)) {
            i++;
          }

          const ind = i % steps.length;
          const r = this.convertLetter(c, steps[ind]);
          return r;
        })
        .join('');
    }),
  );

  private isLetter(c: string) {
    return letters.indexOf(c.toUpperCase()) > -1;
  }

  private destroyed = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    this.inputObserver.pipe(takeUntil(this.destroyed)).subscribe(input => {
      window.localStorage.setItem('cipher-input', input);
    });

    this.stepsObserver.pipe(takeUntil(this.destroyed)).subscribe(steps => {
      window.localStorage.setItem('cipher-steps', steps);
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
  }

  private convertLetter(input: string, slide: number) {
    const modSlide = (slide + letters.length) % letters.length;
    const inIndex = letters.indexOf(input.toUpperCase());
    if (inIndex === -1) {
      return input;
    }

    return letters[(inIndex + modSlide) % letters.length];
  }
}

// DDO'U ZBJW UP VFF BPV. PJOG JG L CPUSPZ UIH DBU BHDJO? -F
