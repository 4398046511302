export interface Mouse {
  x: number;
  y: number;
  down: boolean;
}

export class CanvasAnimation {
  private stopped = false;

  public width: number;
  public height: number;

  constructor(
    private ctx: CanvasRenderingContext2D,
    { width, height }: { width: number; height: number },
    protected mouse: Mouse,
  ) {
    this.width = width;
    this.height = height;
  }

  init() {
    this.OnStart();
    return this;
  }

  protected OnStart = () => {};
  protected OnUpdate = () => {};
  protected OnDraw = (ctx: CanvasRenderingContext2D) => {};

  stop() {
    this.stopped = true;
  }

  animate() {
    this.update();
    this.draw();

    if (!this.stopped) {
      window.requestAnimationFrame(() => this.animate());
    }
  }

  update() {
    this.OnUpdate();
  }

  draw() {
    this.OnDraw(this.ctx);
  }
}
