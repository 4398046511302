import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statModifier',
})
export class StatModifierPipe implements PipeTransform {
  transform(value: number): string {
    return `${value < 0 ? '-' : '+'}${Math.abs(value)}`;
  }
}
