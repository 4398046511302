<header>
  <h1>
    <span class="hidden-for-effect" [class.active]="((introPhase | async) || 0) > 0">this is</span>
    <span class="nowrap">
      <span [class.active]="((introPhase | async) || 0) > 1" class="hidden-for-effect">josh</span>
      <span [class.active]="((introPhase | async) || 0) > 2" class="hidden-for-effect">hansen</span>
    </span>
  </h1>
</header>

<p class="hidden-for-effect" [class.active]="visible">
  {{ msg }}
</p>
