import { Point, PointLike } from '../point';

export interface TRBL {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

function mod(n: number, m: number): number {
  return ((n % m) + m) % m;
}

export interface CellLike {
  col: number;
  row: number;
}

export class WorldView {
  readonly center: Point;
  private _target: Point;

  width: number;
  height: number;
  cellSize: number;

  limit?: Partial<TRBL>;

  constructor(config: { width: number; height: number; center?: PointLike; cellSize: number; limit?: Partial<TRBL> }) {
    this.center = new Point(config.center) || new Point();
    this._target = this.center.clone();
    this.width = config.width;
    this.height = config.height;
    this.cellSize = config.cellSize;
    this.limit = config.limit;
  }

  // set target(worldPoint: PointLike) {
  //   let x = worldPoint.x;
  //   let y = worldPoint.y;

  //   if (this.limit) {
  //     if (typeof this.limit.left !== 'undefined') {
  //       x = Math.min(worldPoint.x, this.cellToWorldPoint().x);
  //     }
  //   }

  //   this._target.set({
  //     x,
  //     y,
  //   });
  // }

  get target() {
    return this._target.clone();
  }

  uiPointToWorldPoint(point: PointLike): PointLike {
    return {
      x: this.center.x + point.x - this.width / 2,
      y: this.center.y + point.y - this.height / 2,
    };
  }

  uiPointToCell(point: PointLike): CellLike {
    return this.worldPointToCell(this.uiPointToWorldPoint(point));
  }

  worldPointToUiPoint(worldPoint: PointLike): PointLike {
    return {
      x: worldPoint.x - this.center.x + this.width / 2,
      y: worldPoint.y - this.center.y + this.height / 2,
    };
  }

  worldPointToCell(point: PointLike): CellLike {
    return {
      col: Math.floor(point.x / this.cellSize),
      row: Math.floor(point.y / this.cellSize),
    };
  }

  visibleCells() {
    const cells = new Set<CellLike>();
    const cols = Math.ceil(this.width / this.cellSize) + 1;
    const rows = Math.ceil(this.height / this.cellSize) + 1;

    const firstCell: CellLike = this.uiPointToCell({
      x: 0,
      y: 0,
    });

    for (let col = 0; col < cols; col++) {
      for (let row = 0; row < rows; row++) {
        cells.add({
          col: col + firstCell.col,
          row: row + firstCell.row,
        });
      }
    }

    return cells;
  }

  cellToWorldPoint(cell: CellLike): PointLike {
    return {
      x: cell.col * this.cellSize - this.center.x + this.width / 2,
      y: cell.row * this.cellSize - this.center.y + this.height / 2,
    };
  }

  cellToUiPoint(cell: CellLike): PointLike {
    return this.worldPointToUiPoint(this.cellToWorldPoint(cell));
  }
}
