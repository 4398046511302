import { Body, Engine, World } from 'matter-js';
import { Application as PixiApplication, Container } from 'pixi.js';
import { Subject } from 'rxjs';

import { Point, PointLike, Vector } from '../point';

export interface PixiApplicationOptions {
  autoStart?: boolean;
  width?: number;
  height?: number;
  view?: HTMLCanvasElement;
  transparent?: boolean;
  autoDensity?: boolean;
  antialias?: boolean;
  preserveDrawingBuffer?: boolean;
  resolution?: number;
  forceCanvas?: boolean;
  backgroundColor?: number;
  clearBeforeRender?: boolean;
  forceFXAA?: boolean;
  powerPreference?: string;
  sharedTicker?: boolean;
  sharedLoader?: boolean;
  resizeTo?: Window | HTMLElement;
}

export class GameMouse {
  constructor(private readonly game: Game) {}

  uiPosition = new Point();
  isPressed = false;
  wasJustPressed = false;
  wheel = 0;

  get worldPosition(): PointLike {
    return this.game.viewTarget.uiToWorldPosition(this.uiPosition);
  }
}

export class ViewTarget {
  constructor(readonly position = new Point(), public scale = 1, public rotation = 0) {}

  uiToWorldPosition(_point: PointLike): PointLike {
    const uiVector = new Vector(this.position).multiply(this.scale);
    const worldPoint = new Vector(_point);

    worldPoint.rotate(this.rotation);
    worldPoint.add(uiVector);
    worldPoint.multiply(1 / this.scale);
    return worldPoint.toJSON();
  }
}

export class Game {
  readonly mouse = new GameMouse(this);
  readonly keyPressed = new Set<string>();
  readonly pixiApp: PixiApplication;
  readonly devicePixelRatio = window.devicePixelRatio || 1;
  readonly pixiWorld: Container;
  readonly pixiUI: Container;
  readonly engine: Engine;

  private onUpdateSubject = new Subject();

  readonly viewTarget = new ViewTarget();

  private windowSize!: { width: number; height: number };
  private container: HTMLDivElement;

  get width() {
    return this.windowSize.width;
  }

  get height() {
    return this.windowSize.height;
  }

  constructor(options: { container: HTMLDivElement; pixiOptions?: PixiApplicationOptions }) {
    this.container = options.container;
    window.onresize = () => {
      this.resize();
    };
    this.pixiApp = new PixiApplication(options.pixiOptions || {});
    this.resize();
    this.container.appendChild(this.pixiApp.view);

    this.container.onmousemove = ev => {
      this.mouse.uiPosition.x = ev.offsetX - this.width / 2;
      this.mouse.uiPosition.y = ev.offsetY - this.height / 2;
    };

    this.container.onmousedown = () => {
      this.mouse.wasJustPressed = true;
      this.mouse.isPressed = true;
    };
    this.container.onmouseup = ev => (this.mouse.isPressed = false);

    document.addEventListener('keydown', ev => this.keyPressed.add(ev.key));
    document.addEventListener('keyup', ev => this.keyPressed.delete(ev.key));
    document.addEventListener('wheel', ev => (this.mouse.wheel = ev.deltaY));

    // Physics

    this.engine = Engine.create();
    this.engine.world.gravity.y = 0;
    Engine.run(this.engine);

    // Pixi

    this.pixiWorld = new Container();
    this.pixiWorld.sortableChildren = true;
    this.pixiApp.stage.addChild(this.pixiWorld);

    this.pixiUI = new Container();
    this.pixiApp.stage.addChild(this.pixiUI);

    this.pixiApp.ticker.add(() => {
      this.pixiUI.position.copyFrom({
        x: this.width / 2,
        y: this.height / 2,
      });

      // target the center of screen
      this.pixiWorld.position.copyFrom({
        x: this.width / 2,
        y: this.height / 2,
      });

      // match the view position
      this.pixiWorld.pivot.copyFrom({
        x: this.viewTarget.position.x,
        y: this.viewTarget.position.y,
      });

      // match the view scale
      this.pixiWorld.scale.copyFrom({ x: this.viewTarget.scale, y: this.viewTarget.scale });

      // match the rotation
      this.pixiWorld.rotation = -this.viewTarget.rotation;

      this.onUpdateSubject.next();

      this.mouse.wasJustPressed = false;
      this.mouse.wheel = 0;
    });
  }

  addPhysicsBody(body: Body) {
    World.addBody(this.engine.world, body);
    return this;
  }

  removePhysicsBody(body: Body) {
    World.remove(this.engine.world, body);
    return this;
  }

  onPixiUpdate(cb: Function) {
    return this.onUpdateSubject.subscribe(() => cb());
  }

  private resize() {
    this.windowSize = {
      width: this.container.offsetWidth,
      height: this.container.offsetHeight,
    };

    const viewportScale = 1 / this.devicePixelRatio;
    this.pixiApp.renderer.resize(this.width * this.devicePixelRatio, this.height * this.devicePixelRatio);
    this.pixiApp.view.style.transform = `scale(${viewportScale})`;
    this.pixiApp.view.style.transformOrigin = `top left`;
  }
}
