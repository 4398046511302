import { Component, OnInit } from '@angular/core';
import { BLEND_MODES, Container, Graphics } from 'pixi.js';
import { π, π2, πh } from 'src/app/pi';
import { ApplicationOptions, PixiComponent } from '../pixi-component';
import { Point, Pointer, PointLike, Vector } from '../point';

function mod(n: number, m: number): number {
  return ((n % m) + m) % m;
}

function clamp(value: number, min: number, max: number): number {
  return Math.min(max, Math.max(min, value));
}

class Obstacle {
  graphics?: Graphics;

  constructor(public readonly center: Point, public radius: number) {
    // this.graphics = this.initGraphics();
  }

  initGraphics() {
    const graphics = new Graphics();
    graphics.beginFill(0xff9900, 0.25);
    graphics.drawCircle(0, 0, this.radius);
    graphics.endFill();
    graphics.position.copyFrom(this.center);
    return graphics;
  }

  update() {
    this.graphics?.position.copyFrom(this.center);
  }
}

@Component({
  selector: 'app-boids',
  templateUrl: './boids.component.html',
  styleUrls: ['./boids.component.scss'],
})
export class BoidsComponent extends PixiComponent implements OnInit {
  isMouseDown = false;

  readonly applicationOptions: ApplicationOptions = { antialias: true };

  private thingsToUpdate = new Set<{
    updateGraphics: () => void;
    updatePhysics: () => void;
  }>();
  private boids = new Set<Boid>();
  private mouseRadius = 200;
  // private mouseObstacle!: Obstacle;
  private obstacles = new Set<Obstacle>();

  ngOnInit(): void {
    super.ngOnInit();
    void this.init();
    this.play();
  }

  private init() {
    const width = this.app.view.width;
    const height = this.app.view.height;
    const boidCount = 200;

    for (let i = 0; i < boidCount; i++) {
      const boid = new Boid(
        {
          x: Math.random() * width,
          y: Math.random() * height,
        },
        new Vector(Math.random() * π2),
      );
      if (i === 0) {
        boid.showVision = true;
      }
      this.boids.add(boid);
      this.thingsToUpdate.add(boid);
      this.app.stage.addChild(boid.graphics);
    }

    // const mouseObstacle = new Obstacle(this.mouse, this.mouseRadius);
    // this.obstacles.add(mouseObstacle);
    // this.app.stage.addChild(mouseObstacle.graphics);

    // this.mouseObstacle = mouseObstacle;

    for (let i = 0; i < 10; i++) {
      const center = new Point({
        x: Math.random() * width,
        y: Math.random() * height,
      });
      const radius = 10 + Math.random() * 50;
      this.addObstacle(center, radius);
    }

    this.onMouseDown = () => {
      const center = this.mouse.clone();
      const radius = 100;
      this.addObstacle(center, radius);
    };
  }

  private addObstacle(center: Point, radius: number) {
    const obstacle = new Obstacle(center, radius);
    this.obstacles.add(obstacle);
    this.app.stage.addChild(obstacle.initGraphics());
  }

  private play() {
    this.app.ticker.add(() => {
      this.obstacles.forEach(obstacle => obstacle.update());

      this.boids.forEach(boid => {
        boid.gizomos.clear();

        const separation = true;
        const alignment = true;
        const cohesion = true;
        const avoidObstacles = true;

        const avoidanceWeight = 0.1;
        const alignmentWeight = 0.1;
        const cohesionWeight = 0.1;
        const obstacleWeight = 0.5;

        const turns: number[] = [];

        const near = this.getVisibleBoids(boid);
        near.forEach(other => {
          const relativeAngle = boid.relativeAngleTo(other.center);
          const dist = boid.center.distanceTo(other.center);
          const rangeWeight = (boid.visionRadius - dist) / boid.visionRadius;
          const relativePosition = new Vector(relativeAngle).normalize(dist);
          boid.gizomos.lineStyle(2, 0xcccccc, rangeWeight);
          boid.gizomos.moveTo(0, 0);
          boid.gizomos.lineTo(relativePosition.x, relativePosition.y);
        });

        if (separation) {
          near.forEach(other => {
            const relativeAngle = boid.relativeAngleTo(other.center);
            const dist = boid.center.distanceTo(other.center);
            const rangeWeight = (boid.visionRadius - dist) / boid.visionRadius;
            const turn = rangeWeight * avoidanceWeight * (relativeAngle > 0 ? -1 : 1);
            turns.push(turn);
            const relativeDirectionPosition = new Vector(turn).normalize(boid.visionRadius * 0.5);
            boid.gizomos.lineStyle(2, 0xff0000, rangeWeight);
            boid.gizomos.moveTo(0, 0);
            boid.gizomos.lineTo(relativeDirectionPosition.x, relativeDirectionPosition.y);
          });
        }

        if (alignment) {
          // Get average vector
          const averagePoint = new Point();
          near.forEach(other =>
            averagePoint.move(
              other.rotation
                .clone()
                .normalize(
                  (other.speed * (boid.visionRadius - boid.center.distanceTo(other.center))) / boid.visionRadius,
                ),
            ),
          );
          const averageVector = new Vector(averagePoint).multiply(1 / (near.length + 1));

          // convert to turn
          // const totalSpeed = boid.speed + near.reduce((a, c) => a + c.speed, 0);
          // const count = near.length + 1;
          // const averageSpeed = totalSpeed / count;
          const relativeVector = averageVector.clone().rotate(-boid.rotation.angle);
          const turn = relativeVector.angle * alignmentWeight;

          boid.gizomos.lineStyle(2, 0x00ff00, 0.5);
          boid.gizomos.moveTo(0, 0);
          // const relativeVector = new Vector(relativeAngle)
          //   // .rotate(-boid.rotation.angle)
          //   .normalize(averageSpeed * boid.visionRadius * 0.5);
          const relativeVectorGizmo = relativeVector.clone().multiply(boid.visionRadius * 0.5);
          boid.gizomos.lineTo(relativeVectorGizmo.x, relativeVectorGizmo.y);

          // add it
          turns.push(turn);
        }

        if (cohesion) {
          // get center point
          if (near.length > 0) {
            const center = boid.center.clone();
            near.forEach(other => center.move(other.center));
            center.x /= near.length + 1;
            center.y /= near.length + 1;

            const relativeAngle = boid.relativeAngleTo(center);
            const dist = boid.center.distanceTo(center);
            const rangeWeight = 1 - (boid.visionRadius - dist) / boid.visionRadius;

            const turn = rangeWeight * cohesionWeight * relativeAngle;
            turns.push(turn);

            // gizmo
            const relativeVector = new Vector(relativeAngle).normalize(boid.visionRadius * rangeWeight * 3);
            const relativeCenter = new Vector(relativeAngle).normalize(dist);
            boid.gizomos.lineStyle(2, 0x0099ff, rangeWeight);
            boid.gizomos.moveTo(0, 0);
            boid.gizomos.lineTo(relativeVector.x, relativeVector.y);

            boid.gizomos.beginFill(0x0099ff, rangeWeight);
            boid.gizomos.drawCircle(relativeCenter.x, relativeCenter.y, 5);
            boid.gizomos.endFill();
          }
        }

        if (avoidObstacles) {
          const walls: Obstacle[] = [];
          // top
          walls.push(
            new Obstacle(
              new Point({
                x: boid.center.x,
                y: 0,
              }),
              0,
            ),
          );

          walls.push(
            new Obstacle(
              new Point({
                x: boid.center.x,
                y: this.app.view.height,
              }),
              0,
            ),
          );
          walls.push(
            new Obstacle(
              new Point({
                x: 0,
                y: boid.center.y,
              }),
              0,
            ),
          );
          walls.push(
            new Obstacle(
              new Point({
                x: this.app.view.width,
                y: boid.center.y,
              }),
              0,
            ),
          );
          [...this.obstacles, ...walls].forEach(obstacle => {
            const dist = obstacle.center.distanceTo(boid.center);
            const radius = obstacle.radius + boid.visionRadius;
            if (dist < obstacle.radius) {
              this.app.stage.removeChild(boid.graphics);
              this.boids.delete(boid);
              this.thingsToUpdate.delete(boid);
            }
            if (dist < radius) {
              const nearestPoint = obstacle.center.clone().moveToward(boid.center, obstacle.radius);
              const relativeAngle = boid.relativeAngleTo(nearestPoint);
              const weight = ((radius - dist) / radius) * (π - Math.abs(relativeAngle));
              const target = new Vector(relativeAngle + π).normalize(weight);

              const turn = obstacleWeight * weight * (relativeAngle < 0 ? 1 : -1);
              turns.push(turn);

              const relativePosition = new Vector(target).multiply(boid.visionRadius);
              boid.gizomos.lineStyle(2, 0xcccccc, weight);
              boid.gizomos.moveTo(0, 0);
              boid.gizomos.lineTo(relativePosition.x, relativePosition.y);
            }
          });
        }

        const maxTurn = π * 0.1 * boid.speed;
        const turnSum = turns.reduce((a, c) => a + c, 0);
        const final = clamp(turnSum, -maxTurn, maxTurn);

        boid.rotation.rotate(final);
      });

      this.thingsToUpdate.forEach(thing => thing.updatePhysics());
      this.thingsToUpdate.forEach(thing => thing.updateGraphics());
      this.boids.forEach(boid => {
        boid.center.x = mod(boid.center.x, this.app.view.width);
        boid.center.y = mod(boid.center.y, this.app.view.height);
      });
    });
  }

  private getVisibleBoids(boid: Boid) {
    return [...this.boids].filter(other => {
      if (other === boid) {
        return false;
      }

      const inRange = boid.center.distanceTo(other.center) < boid.visionRadius;

      if (inRange) {
        if (Math.abs(boid.relativeAngleTo(other.center)) < π * 0.75) {
          return true;
        }
      }

      return false;
    });
  }
}

class Boid extends Pointer {
  public readonly graphics: Graphics;

  private readonly visionGraphic: Graphics;
  readonly gizomos: Graphics;

  public showVision = false;
  public readonly visionRadius = 50;
  public readonly visionAngle = π * 0.75;

  constructor(position: PointLike = new Point(), rotation = new Vector(), public speed = 5) {
    super({ center: position, vector: rotation });
    const { graphics, vision, gizomos } = this.initGraphics();
    this.graphics = graphics;
    this.gizomos = gizomos;
    this.visionGraphic = vision;
    this.updateGraphics();
  }

  private initGraphics() {
    const scale = 1;
    const boidWidth = 4 * scale;
    const boidHeight = 10 * scale;
    const offset = {
      x: boidHeight * -0.25,
      y: 0,
    };

    const graphics = new Graphics();

    const vision = new Graphics();
    // vision.blendMode = BLEND_MODES.ADD;
    vision.beginFill(0xffffff, 0.1);
    vision.moveTo(0, 0);
    vision.arc(0, 0, this.visionRadius, -this.visionAngle, this.visionAngle);
    vision.lineTo(0, 0);
    vision.endFill();
    graphics.addChild(vision);

    const boid = new Graphics();
    boid.beginFill(0xffffff);
    boid.moveTo(offset.x + boidHeight, offset.y + 0);
    boid.lineTo(offset.x + 0, offset.y + boidWidth);
    boid.lineTo(offset.x + 0, offset.y + -boidWidth);
    boid.endFill();
    graphics.addChild(boid);

    const gizomos = new Graphics();
    graphics.addChild(gizomos);

    return {
      graphics,
      vision,
      gizomos,
    };
  }

  updatePhysics() {
    this.center.move(this.rotation.clone().normalize(this.speed));
  }

  updateGraphics() {
    this.graphics.position.copyFrom(this.center);
    this.graphics.rotation = this.rotation.angle;
    this.visionGraphic.visible = this.showVision;
    this.gizomos.visible = this.showVision;
  }
}

class SpacialGrid {
  //
}
