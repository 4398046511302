import { Component, OnInit } from '@angular/core';
import { Howler } from 'howler';
import { Body, Engine, Events, Query } from 'matter-js';
import { Graphics } from 'pixi.js';
import { π2 } from 'src/app/pi';
import { rand } from 'src/app/rand';
import { ApplicationOptions, PixiComponent } from '../pixi-component';
import { Vector } from '../point';
import { Asteroid } from './asteroid';
import { Tag } from './entity';
import { Explosion } from './explosion';
import { Spaceship } from './spaceship';
import { Universe } from './universe.interface';
import { Wall } from './wall';

Howler.volume(0.5);

@Component({
  selector: 'app-star-map',
  templateUrl: './star-map.component.html',
  styleUrls: ['./star-map.component.scss'],
})
export class StarMapComponent extends PixiComponent implements OnInit {
  // constructor() { }

  isMouseDown = false;

  readonly applicationOptions: ApplicationOptions = { antialias: true };

  ngOnInit(): void {
    super.ngOnInit();
    void this.init();
  }

  private init() {
    const width = this.app.view.width;
    const height = this.app.view.height;

    const engine = Engine.create();

    const universe: Universe = {
      world: engine.world,
      app: this.app,
      entities: [],
      physBodyToEntityMap: {},
    };

    Events.on(engine, 'collisionStart', c => {
      c.pairs.forEach(pair => {
        const entityA = universe.physBodyToEntityMap[pair.bodyA.id];
        const entityB = universe.physBodyToEntityMap[pair.bodyB.id];

        if (entityA && entityB) {
          entityA.handleCollision(entityB);
          entityB.handleCollision(entityA);
        }
      });
    });

    engine.world.gravity.y = 0;

    if (true) {
      const wallSize = 50;

      const walls = [
        // bottom
        new Wall(
          universe,
          {
            x: width / 2,
            y: height + wallSize / 2,
          },
          width,
          wallSize,
        ),
        // left
        new Wall(
          universe,
          {
            x: -wallSize / 2,
            y: height / 2,
          },
          wallSize,
          height,
        ),
        // right
        new Wall(
          universe,
          {
            x: width + wallSize / 2,
            y: height / 2,
          },
          wallSize,
          height,
        ),
        // top
        new Wall(
          universe,
          {
            x: width / 2,
            y: -wallSize / 2,
          },
          width,
          wallSize,
        ),
      ];
    }

    // add ships
    // for (let i = 0; i < 0; i++) {
    //   const ship = new Spaceship(universe, {
    //     x: rand(width),
    //     y: rand(height),
    //   });
    // }

    // Stars
    const stars: Graphics[] = [];
    for (let i = 0; i < 5000; i++) {
      const star = new Graphics();
      star.position.copyFrom({
        x: rand(width),
        y: rand(height),
      });
      star.beginFill(0xffffff);
      const size = rand(0.5, 2);
      star.drawRect(0, 0, size, size);
      star.endFill();
      star.alpha = size / 2;
      this.app.stage.addChild(star);
      stars.push(star);
    }

    // Asteroids
    // const asteroids: Asteroid[] = [];
    const rad = Math.min(width, height) / 2;
    for (let i = 0; i < 50; i++) {
      const position = new Vector()
        .rotate(rand(π2))
        .normalize(rand(rad))
        .add(width / 2, height / 2);

      const asteroid = new Asteroid(universe, {
        position,
        angularVelocity: rand(-0.1, 0.1),
        radius: 32, // rand(6, 50),
      });
      // asteroids.push(asteroid);
    }

    // new Asteroid(universe, {
    //   position: {
    //     x: 0.5 * width,
    //     y: 0.5 * height,
    //   },
    //   velocity: {
    //     x: 0,
    //     y: 0,
    //   },
    //   radius: 100,
    // });

    // new Asteroid(universe, {
    //   position: {
    //     x: width / 3,
    //     y: height / 2,
    //   },
    //   angularVelocity: 0.1,
    //   velocity: {
    //     x: 0,
    //     y: -2,
    //   },
    //   radius: 15,
    // });

    // let i = 0;
    // let iv: any;
    // iv = setInterval(() => {
    //   i++;
    //   if (i > 24) {
    //     clearInterval(iv);
    //     return;
    //   }
    //   new Asteroid(universe, {
    //     position: {
    //       x: rand(width),
    //       y: rand(height),
    //     },
    //     // velocity: {
    //     //   x: 0,
    //     //   y: rand(-1, 1),
    //     // },
    //     radius: 16, // rand(5, 20),
    //   });
    // }, 10);

    const mouse = this.mouse;

    // const com = new Graphics();
    // com.beginFill(0xff0000);
    // com.drawStar(width / 2, height / 2, 4, 30, 3);
    // // com.alpha = 0.5;
    // com.endFill();
    // this.app.stage.addChild(com);

    let canAddShip = true;

    const l = new Graphics().lineStyle(1, 0xf3a33f);
    l.moveTo(20, 20);
    l.lineTo(500, 500);
    this.app.stage.addChild(l);

    this.app.ticker.add(() => {
      if (this.isMouseDown) {
        if (canAddShip) {
          new Spaceship(universe, mouse);
          canAddShip = false;
        }
      } else {
        canAddShip = true;
      }

      const moveStars = true;
      if (moveStars) {
        stars.forEach(star => {
          star.position.x -= Math.pow(star.alpha, 2) * 0.02;
          if (star.position.x < 0) {
            star.position.y = rand(height);
            star.x = width;
          }
        });
      }

      if (this.keyPressed.has('a')) {
        const asteroidsUnderMouse = Query.point(
          universe.entities.filter(e => e instanceof Asteroid).map(a => (a as Asteroid).physBody),
          mouse,
        );
        if (asteroidsUnderMouse.length === 0) {
          new Asteroid(universe, {
            position: mouse,
            radius: 32,
          });
        }
      }

      if (this.keyPressed.has('e')) {
        new Explosion(universe, mouse, 10, 0x0000ff);
      }

      l.clear();
      l.moveTo(20, 20);
      l.lineTo(new Date().getTime() % 1000, 500);

      universe.entities.forEach((ent, i) => {
        if (ent.delete) {
          ent.onDestroy();
          universe.entities.splice(i, 1);
        }
      });

      universe.entities.forEach(entity =>
        entity.onUpdate({ mouse, isMouseDown: this.isMouseDown, keyPressed: this.keyPressed }),
      );

      universe.entities
        .filter(e => e.hasTag(Tag.asteroid))
        .forEach(asteroid => {
          const a = asteroid as Asteroid;
          Body.applyForce(a.physBody, a.position, a.gravity);
        });

      universe.entities.forEach(entity => entity.onDraw());

      // let totalMass = 0;
      // let totalx = 0;
      // let totaly = 0;
      // universe.entities
      //   .filter(e => e.hasTag(Tag.asteroid))
      //   // .slice(0, 1)
      //   .forEach(asteroid => {
      //     // @ts-ignore
      //     const r: number = asteroid.radius;
      //     const mass = π * r * r;
      //     totalMass += mass;
      //     totalx += asteroid.position.x * mass;
      //     totaly += asteroid.position.y * mass;
      //   });

      // const center = {
      //   x: totalx / totalMass - width / 2,
      //   y: totaly / totalMass - height / 2,
      // };
      // // console.log(center);
      // com.position.copyFrom(center);
      // universe.entities.forEach(entity => entity.onDraw());
    });
    Engine.run(engine);
  }

  onMouseDown(ev: MouseEvent) {
    this.isMouseDown = true;
  }

  onMouseUp(ev: MouseEvent) {
    this.isMouseDown = false;
  }
}
