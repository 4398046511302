import { Howl } from 'howler';
import { Bodies, Body, Composite, World } from 'matter-js';
import { Graphics, Sprite } from 'pixi.js';
import { π, πh } from 'src/app/pi';
import { Point, PointLike, Vector, Pointer } from '../point';
import { Asteroid } from './asteroid';
import { Bullet } from './bullet';
import { Entity, Tag, UpdateData } from './entity';
import { Explosion } from './explosion';
import { Universe } from './universe.interface';

export class Spaceship extends Entity {
  readonly physBody: Body;
  readonly pixiBody: Sprite;
  bulletCharge = 0;
  bulletChargeNeeded = 10;

  supermode = 0;
  superBulletCharge = 0;
  superBulletChargeNeeded = 7;

  targetEntity?: Asteroid;
  targetGraphic?: Graphics;

  constructor(private universe: Universe, position: PointLike = Point.center) {
    super(universe);

    this.tags.add(Tag.ship);

    // render
    this.pixiBody = Sprite.from('assets/spaceship-1.png');
    this.pixiBody.scale.copyFrom({ x: 0.2, y: 0.2 });
    this.pixiBody.anchor.copyFrom({ x: 0.5, y: 0.5 });
    const radius = Math.max(128 * this.pixiBody.scale.x, 132 * this.pixiBody.scale.y) / 2;
    this.registerPixiBody(this.pixiBody);

    // physics
    this.physBody = Bodies.circle(position.x, position.y, radius, {
      frictionAir: 0.02,
    });
    // this.physBody.frictionAir = 0.01;
    this.registerPhysBody(this.physBody);

    this.playSound('powerUp');
  }

  target(entity: Asteroid) {
    this.dropTarget();
    this.targetEntity = entity;
    entity.targetee = this;

    // this.targetGraphic = new Graphics();
    // this.registerPixiBody(this.targetGraphic);
  }

  dropTarget() {
    if (this.targetGraphic) {
      this.app.stage.removeChild(this.targetGraphic);
    }
    if (this.targetEntity && 'targetee' in this.targetEntity) {
      delete this.targetEntity.targetee;
    }
    delete this.targetEntity;
  }

  get position() {
    return {
      x: this.physBody.position.x,
      y: this.physBody.position.y,
    };
  }

  onUpdate(data: UpdateData) {
    super.onUpdate(data);

    if (
      this.position.x < 0 ||
      this.position.x > this.app.view.width ||
      this.position.y < 0 ||
      this.position.y > this.app.view.height
    ) {
      this.destroy();
      return;
    }

    if (this.bulletCharge < this.bulletChargeNeeded) {
      this.bulletCharge++;
    }

    if (this.superBulletCharge < this.superBulletChargeNeeded) {
      this.superBulletCharge++;
    }

    if (this.supermode > 0) {
      this.supermode--;
    }

    const asteroid = this.entities
      .filter(e => e.hasTag(Tag.asteroid) && (!(e as Asteroid).targetee || (e as Asteroid).targetee === this))
      .sort((a, b) => {
        // @ts-ignore
        const ar = a.radius;
        // @ts-ignore
        const br = b.radius;
        const d1 = new Vector(this.position, a.position).magnitude + ar;
        const d2 = new Vector(this.position, b.position).magnitude + br;
        return d1 - d2;
      })[0] as undefined | Asteroid;
    if (asteroid) {
      this.target(asteroid);
      this.lookAt(asteroid.position);

      const force = new Vector(this.position, asteroid.position);
      const dist = force.magnitude;

      // @ts-ignore
      const r: number = asteroid.radius;

      // const mass1 = Math.pow(r, 2) * π;
      // const mass2 = Math.pow(this.physBody.circleRadius!, 2) * π;
      // const c = 0.000005;
      // const g = (c * (mass1 + mass2)) / Math.pow(dist, 1.2);

      const c = 0.05;
      const o = r + 200;
      // const g = c * (mass1 + mass2) * (Math.atan(Math.pow(dist - o, 2)) / (dist - o));
      const g = c * ((dist - o) / Math.pow(dist, 1.5));

      if (this.bulletCharge === this.bulletChargeNeeded && dist < o * 1.5) {
        this.fireBullet();
      }

      force.normalize(g);
      Body.applyForce(this.physBody, this.position, force);
    }
  }

  lookAt(target: PointLike) {
    const center = new Point(this.physBody.position);
    const angle = new Vector(this.physBody.angle);

    const pointer = new Pointer({
      center,
      vector: angle,
    });

    const delta = pointer.relativeAngleTo(target);
    this.physBody.torque = delta / 10;
    Body.setAngularVelocity(this.physBody, this.physBody.angularVelocity * 0.9);
  }

  onDestroy() {
    super.onDestroy();
    this.dropTarget();
    this.playSound('explosion');
  }

  onDraw() {
    const tg = this.targetGraphic;
    if (tg && this.targetEntity) {
      tg.clear();
      tg.lineStyle(1, 0xff6633);

      const a = this.physBody.position;
      const b = this.targetEntity.position;
      tg.moveTo(a.x, a.y);
      tg.lineTo(b.x, b.y);
    }

    this.pixiBody.rotation = this.physBody.angle;
    this.pixiBody.position.copyFrom(this.physBody.position);
  }

  handleCollision(entity: Entity) {
    // if (entity.hasTag(Tag.bullet)) {
    //   this.bulletCharge = this.bulletChargeNeeded;
    //   this.supermode += 3;
    // }

    if (entity.hasTag(Tag.asteroid)) {
      new Explosion(this.universe, this.physBody.position, 5, 0x00ccff);
      this.destroy();
    }
  }

  fireBullet() {
    this.bulletCharge = 0;

    // velocity.add(this.body.velocity);
    const velocity = new Vector(this.physBody.angle).normalize(20);
    // const positionA = new Point(this.physBody.position).move(new Vector(this.physBody.angle + πh / 2).normalize(10));
    // const positionB = new Point(this.physBody.position).move(new Vector(this.physBody.angle - πh / 2).normalize(10));
    // new Bullet(this.universe, positionA, velocity);
    // new Bullet(this.universe, positionB, velocity);
    const positiona = new Point(this.physBody.position).move(new Vector(this.physBody.angle).normalize(10));
    new Bullet(this.universe, positiona, velocity);
  }

  fireSuperBullet() {
    this.superBulletCharge = 0;
    const velocity = new Vector(this.physBody.angle).normalize(20);

    if (this.supermode) {
      const positionA = new Point(this.physBody.position).move(new Vector(this.physBody.angle + πh / 2).normalize(10));
      const positionB = new Point(this.physBody.position).move(new Vector(this.physBody.angle - πh / 2).normalize(10));
      new Bullet(this.universe, positionA, velocity.clone().rotate(π / 30));
      new Bullet(this.universe, positionB, velocity.clone().rotate(-π / 30));
    }
  }
}
