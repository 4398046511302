import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentLoadedService } from './services/content-loaded.service';
import { IntroTimingService } from './services/intro-timing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  introPhase = this.introTimingService.phase;

  canMoveHeaderUp = combineLatest([this.introTimingService.complete, this.contentLoadedService.loaded]).pipe(
    map(([a, b]) => a && b),
  );

  constructor(private introTimingService: IntroTimingService, private contentLoadedService: ContentLoadedService) {}

  ngOnInit(): void {
    this.introTimingService.run();
  }
}
