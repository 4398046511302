import { Body } from 'matter-js';
import { Graphics, Sprite } from 'pixi.js';
import { Subscription } from 'rxjs';
import { Game } from '../game';

export class Entity {
  private game?: Game;
  private updatesub?: Subscription;

  protected pixiGraphics = new Set<Graphics | Sprite>();
  protected physBodies = new Set<Body>();

  addToGame(game: Game) {
    this.game = game;
    this.onInit(game);

    this.updatesub = game.onPixiUpdate(() => {
      this.onUpdate(game);
    });

    return this;
  }

  addGraphic(graphic: Graphics | Sprite) {
    if (this.game) {
      this.pixiGraphics.add(graphic);
      this.game.pixiWorld.addChild(graphic);
    }
  }

  addBody(body: Body) {
    if (this.game) {
      this.physBodies.add(body);
      this.game.addPhysicsBody(body);
    }
  }

  destroy() {
    if (this.updatesub) {
      this.updatesub.unsubscribe();
    }
    if (this.game) {
      this.pixiGraphics.forEach(graphic => this.game!.pixiWorld.removeChild(graphic));
      this.physBodies.forEach(body => this.game!.removePhysicsBody(body));
      this.onDestroy(this.game);
    }
  }

  protected onInit(_game: Game) {}
  protected onUpdate(_game: Game): void {}
  protected onDestroy(_game: Game) {}
}
