import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Mouse } from 'src/app/util/canvas-animation';
import { FlowChartCanvas } from './flowchart-canvas';

@Component({
  selector: 'app-flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.scss'],
})
export class FlowchartComponent implements AfterViewInit, OnDestroy {
  private destroyed = new Subject<void>();

  get width() {
    return this.content?.nativeElement.offsetWidth ?? 0;
  }

  get height() {
    return this.content?.nativeElement.offsetHeight ?? 0;
  }

  mouse: Mouse = {
    x: 0,
    y: 0,
    down: false,
  };

  @ViewChild('canvas') canvas?: ElementRef<HTMLCanvasElement>;
  @ViewChild('content') content?: ElementRef<HTMLCanvasElement>;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    const cnv = this.canvas?.nativeElement;

    if (!cnv) {
      console.error(`no canvas`);
      return;
    }

    const ctx = cnv.getContext('2d');
    if (!ctx) {
      console.error(`no ctx`);
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      const width = this.width;
      const height = this.height;
      const animation = new FlowChartCanvas(ctx, { width, height }, this.mouse);
      animation.init().animate();
      this.destroyed.subscribe(() => animation.stop());

      const takeContainerSize = () => {
        const width = this.width * 2;
        const height = this.height * 2;
        this.canvas?.nativeElement.setAttribute('width', `${width}`);
        this.canvas?.nativeElement.setAttribute('height', `${height}`);
        animation.width = width;
        animation.height = height;
      };

      takeContainerSize();
      window.onresize = () => {
        takeContainerSize();
      };
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
  }

  updateMouse(ev: MouseEvent) {
    this.mouse.x = ev.offsetX;
    this.mouse.y = ev.offsetY;
  }
}
