import { π2 } from 'src/app/pi';
import { CanvasAnimation } from 'src/app/util/canvas-animation';
import { tiles } from './tiles';

enum Entity {
  goblin = 'G',
  elf = 'E',
}

interface Point3 {
  x: number;
  y: number;
  z: number;
}

class Tile {
  neighbor: Partial<{
    left: Tile;
    right: Tile;
    up: Tile;
    down: Tile;
  }> = {};

  readonly id: string;
  readonly x: number;
  readonly y: number;
  readonly z: number;

  get position() {
    return {
      x: this.x,
      y: this.y,
      z: this.z,
    };
  }

  static id(x: number, y: number, z: number): string;
  static id(position: Point3): string;
  static id(...args: any[]): string {
    if (args.length === 1) {
      return `${args[0].x}__${args[0].y}__${args[0].z}`;
    }
    return `${args[0]}__${args[1]}__${args[2]}`;
  }

  constructor(public readonly walkable: boolean, position: Point3, entity?: Entity) {
    this.id = Tile.id(position);
    this.x = position.x;
    this.y = position.y;
    this.z = position.z;
  }
}

function isoCoord(x: number, y: number, z = 0) {
  return {
    x: x + y,
    y: x * -0.5 + y * 0.5 - z,
  };
}

function isoMoveTo(ctx: CanvasRenderingContext2D, x: number, y: number, z = 0) {
  const { x: x2, y: y2 } = isoCoord(x, y, z);
  ctx.moveTo(x2, y2);
}

function isoLineTo(ctx: CanvasRenderingContext2D, x: number, y: number, z = 0) {
  const { x: x2, y: y2 } = isoCoord(x, y, z);
  ctx.lineTo(x2, y2);
}

export class CaveFight extends CanvasAnimation {
  tiles = new Map<string, Tile>();
  maxX = 0;
  maxY = 0;

  OnStart = () => {
    tiles
      .split('\n')
      .filter(x => x.length > 0)
      .map((line, y) =>
        line
          .split('')
          .map(
            (t, x) =>
              new Tile(t !== '#', { x, y, z: 0 }, t === 'E' ? Entity.elf : t === 'G' ? Entity.goblin : undefined),
          ),
      )
      .forEach(tileRow => {
        tileRow.forEach(tile => {
          this.maxX = Math.max(this.maxX, tile.x);
          this.maxY = Math.max(this.maxY, tile.y);
          this.tiles.set(tile.id, tile);
        });
      });

    for (const tile of this.tiles.values()) {
      const upId = Tile.id(tile.x, tile.y - 1, 0);
      const up = this.tiles.get(upId);
      if (up) {
        tile.neighbor.up = up;
      }

      const rightId = Tile.id(tile.x + 1, tile.y, 0);
      const right = this.tiles.get(rightId);
      if (right) {
        tile.neighbor.right = right;
      }

      const downId = Tile.id(tile.x, tile.y + 1, 0);
      const down = this.tiles.get(downId);
      if (down) {
        tile.neighbor.down = down;
      }

      const leftId = Tile.id(tile.x - 1, tile.y, 0);
      const left = this.tiles.get(leftId);
      if (left) {
        tile.neighbor.left = left;
      }
    }
  };

  OnDraw = (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, this.width, this.height);
    const size = 42;

    ctx.save();

    const focus = this.tiles.get(Tile.id(23, 7, 0));
    const scale = 2; //(Math.sin(new Date().getTime() / 1000) + 1) * 0.5 + 1;
    if (focus) {
      const { x, y } = isoCoord((focus.x + 0.5) * size, (focus.y + 0.5) * size);
      ctx.translate(x, y);
      ctx.scale(scale, scale);
      ctx.translate(-x / scale, -y / scale);
      ctx.translate(this.width / 2 / scale, this.height / 2 / scale);
      ctx.translate(-size * (this.maxX + this.maxY + 2) * 0.5, 0);

      ctx.fillStyle = '#900';
      ctx.beginPath();
      ctx.arc(focus.x * size, focus.y * size, 100, 0, π2);
      ctx.fill();
      ctx.closePath();
    }

    const betweenStyle = `#00000011`;
    const edgeStyle = `#ccc`;

    for (const tile of this.tiles.values()) {
      if (!tile.walkable) {
        continue;
      }
      const left = size * tile.x;
      const top = size * tile.y;
      const right = left + size;
      const bottom = top + size;

      // Fill
      // ctx.fillStyle = '#99ff0099';
      // ctx.beginPath();
      // isoMoveTo(ctx, left, top);
      // isoLineTo(ctx, right, top);
      // isoLineTo(ctx, right, bottom);
      // isoLineTo(ctx, left, bottom);
      // ctx.fill();
      // ctx.closePath();

      // top
      const topTile = tile.neighbor.up;
      if (topTile) {
        ctx.strokeStyle = topTile.walkable ? betweenStyle : edgeStyle;
        ctx.beginPath();
        isoMoveTo(ctx, left, top);
        isoLineTo(ctx, right, top);
        ctx.closePath();
        ctx.stroke();
      }

      // bottom
      const bottomTile = tile.neighbor.down;
      if (bottomTile && !bottomTile.walkable) {
        ctx.strokeStyle = edgeStyle;
        ctx.beginPath();
        isoMoveTo(ctx, left, bottom);
        isoLineTo(ctx, right, bottom);
        ctx.closePath();
        ctx.stroke();
      }

      // left
      const leftTile = tile.neighbor.left;
      if (leftTile) {
        ctx.strokeStyle = leftTile.walkable ? betweenStyle : edgeStyle;
        ctx.beginPath();
        isoMoveTo(ctx, left, top);
        isoLineTo(ctx, left, bottom);
        ctx.closePath();
        ctx.stroke();
      }

      // right
      const rightTile = tile.neighbor.right;
      if (rightTile && !rightTile.walkable) {
        ctx.strokeStyle = edgeStyle;
        ctx.beginPath();
        isoMoveTo(ctx, right, top);
        isoLineTo(ctx, right, bottom);
        ctx.closePath();
        ctx.stroke();
      }
    }

    // for (const tile of this.tiles.values()) {
    //   const left = size * tile.position.x;
    //   const top = size * tile.position.y;
    //   const right = left + size;
    //   const bottom = top + size;
    //   ctx.fillStyle = tile.walkable ? '#9f9' : '#00000011';
    //   ctx.beginPath();
    //   isoMoveTo(ctx, left, top);
    //   isoLineTo(ctx, right, top);
    //   isoLineTo(ctx, right, bottom);
    //   isoLineTo(ctx, left, bottom);
    //   ctx.fill();
    //   ctx.closePath();
    // }

    ctx.restore();
  };
}
