import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Aoc201815aPixiComponent } from './pages/aoc/2018/15/aoc201815a-pixi/aoc201815a-pixi.component';
import { BoidsComponent } from './pages/boids/boids.component';
import { CameraControlComponent } from './pages/camera-control/camera-control.component';
import { CipherComponent } from './pages/cipher/cipher.component';
import { CountdownComponent } from './pages/countdown/countdown.component';
import { CropperComponent } from './pages/cropper/cropper.component';
import { DndStatDescriptorComponent } from './pages/dnd-stat-descriptor/dnd-stat-descriptor.component';
import { EmpireBuilderComponent } from './pages/empire-builder/empire-builder.component';
import { FlowchartComponent } from './pages/flowchart/flowchart.component';
import { HomeComponent } from './pages/home/home.component';
import { PixiGameEngineTestPageComponent } from './pages/pixi-game-engine-test-page/pixi-game-engine-test-page.component';
import { PixijsHelloWorldComponent } from './pages/pixijs-hello-world/pixijs-hello-world.component';
import { ScrollingTilesComponent } from './pages/scrolling-tiles/scrolling-tiles.component';
import { ShadowComponent } from './pages/shadow/shadow.component';
import { StarMapComponent } from './pages/star-map/star-map.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'hexworld-generator',
    component: PixijsHelloWorldComponent,
  },
  {
    path: 'star-map',
    component: StarMapComponent,
  },
  {
    path: 'scrolling-tiles',
    component: ScrollingTilesComponent,
  },
  {
    path: 'camera-control',
    component: CameraControlComponent,
  },
  {
    path: 'empire-builder',
    component: EmpireBuilderComponent,
  },
  {
    path: 'shadow',
    component: ShadowComponent,
  },
  {
    path: 'cipher',
    component: CipherComponent,
  },
  {
    path: 'cropper',
    component: CropperComponent,
  },
  {
    path: 'boids',
    component: BoidsComponent,
  },
  {
    path: 'dnd-stats',
    component: DndStatDescriptorComponent,
  },
  {
    path: 'dnd-spells',
    loadChildren() {
      return import('./pages/dnd-spells/dnd-spells.module').then(mod => mod.DndSpellsModule);
    },
  },
  {
    path: 'space-trader',
    loadChildren() {
      return import('./pages/space-trader/space-trader.module').then(mod => mod.SpaceTraderModule);
    },
  },
  {
    path: 'a-star-test',
    loadChildren() {
      return import('./pages/a-star-test/a-star-test.module').then(mod => mod.AStarTestModule);
    },
  },

  {
    path: 'food-week',
    loadChildren() {
      return import('./pages/food-week/food-week.module').then(mod => mod.FoodWeekModule);
    },
  },
  {
    path: 'advent-of-code/2021',
    loadChildren() {
      return import('./pages/advent-of-code/aoc2021/aoc2021.module').then(mod => mod.Aoc2021Module);
    },
  },
  {
    path: 'colorado-countdown',
    component: CountdownComponent,
  },
  {
    path: 'flow-chart',
    component: FlowchartComponent,
  },
  {
    path: 'cave-fight',
    component: Aoc201815aPixiComponent,
  },
  {
    path: 'pixi-game-engine-test',
    component: PixiGameEngineTestPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
