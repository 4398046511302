import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContentLoadedService {
  private loadedSubject = new BehaviorSubject<boolean>(false);
  readonly loaded = this.loadedSubject.pipe(distinctUntilChanged());

  constructor() {}

  claimLoaded(isLoaded = true) {
    this.loadedSubject.next(isLoaded);
  }
}
