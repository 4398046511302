import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BoidsComponent } from './pages/boids/boids.component';
import { CameraControlComponent } from './pages/camera-control/camera-control.component';
import { CipherComponent } from './pages/cipher/cipher.component';
import { CropperComponent } from './pages/cropper/cropper.component';
import { DndStatDescriptorComponent } from './pages/dnd-stat-descriptor/dnd-stat-descriptor.component';
import { EmpireBuilderComponent } from './pages/empire-builder/empire-builder.component';
import { HomeComponent } from './pages/home/home.component';
import { PixijsHelloWorldComponent } from './pages/pixijs-hello-world/pixijs-hello-world.component';
import { ScrollingTilesComponent } from './pages/scrolling-tiles/scrolling-tiles.component';
import { ShadowComponent } from './pages/shadow/shadow.component';
import { StarMapComponent } from './pages/star-map/star-map.component';
import { StatModifierPipe } from './pipes/stat-modifier.pipe';
import { JesusTemptedComponent } from './pages/jesus-tempted/jesus-tempted.component';
import { CountdownModule } from './pages/countdown/countdown.module';
import { FlowchartComponent } from './pages/flowchart/flowchart.component';
import { Aoc201815aComponent } from './pages/aoc/2018/15/aoc201815a/aoc201815a.component';
import { Aoc201815aPixiComponent } from './pages/aoc/2018/15/aoc201815a-pixi/aoc201815a-pixi.component';
import { PixiGameEngineTestPageComponent } from './pages/pixi-game-engine-test-page/pixi-game-engine-test-page.component';

@NgModule({
  declarations: [
    AppComponent,
    BoidsComponent,
    CameraControlComponent,
    CipherComponent,
    CropperComponent,
    DndStatDescriptorComponent,
    EmpireBuilderComponent,
    HomeComponent,
    PixijsHelloWorldComponent,
    ScrollingTilesComponent,
    ShadowComponent,
    StarMapComponent,
    StatModifierPipe,
    JesusTemptedComponent,
    FlowchartComponent,
    Aoc201815aComponent,
    Aoc201815aPixiComponent,
    PixiGameEngineTestPageComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule, CountdownModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
