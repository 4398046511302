import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private http: HttpClient) {}

  async getMessage() {
    const response = await this.http
      .get<{ msg: string }>(`http://${window.location.hostname}:${environment.apiPort}/`)
      .toPromise();
    return response.msg;
  }
}
