import { π, πh } from 'src/app/pi';
import { CanvasAnimation } from 'src/app/util/canvas-animation';
import { Point, PointLike, Vector } from '../point';

interface Data {
  id: string;
  label: string;
  reqs?: string[];
}

const rows: Data[] = [
  {
    id: 'josh',
    label: 'Josh',
  },
  {
    id: 'christi',
    label: 'Christi',
    reqs: ['josh'],
  },
  {
    id: 'christopher',
    label: 'Chris',
    reqs: ['josh', 'christi'],
  },
  {
    id: 'steven',
    label: 'Stevie',
    reqs: ['josh', 'christi'],
  },
  {
    id: 'chloe',
    label: 'Chloe',
    reqs: ['josh', 'christi'],
  },
];

const em = 32;
const ch = em / 2;

class Entity {
  data: Data;
  position: Point;
  target: Point;
  width: number;
  height: number;

  get center(): PointLike {
    return {
      x: this.position.x + this.width / 2,
      y: this.position.y + this.height / 2,
    };
  }

  get inputPoint() {
    return this.position.clone().move(0, this.height / 2);
  }

  get outputPoint() {
    return this.position.clone().move(this.width, this.height / 2);
  }

  constructor({
    data,
    position,
    dimensions,
  }: {
    data: Data;
    position: PointLike;
    dimensions: { width: number; height: number };
  }) {
    this.data = data;
    this.position = new Point(position);
    this.target = new Point(position);
    this.width = dimensions.width;
    this.height = dimensions.height;
  }
}

export class FlowChartCanvas extends CanvasAnimation {
  private entityMap = new Map<string, Entity>();

  *entities(ids?: string[]) {
    for (const id of ids ?? this.entityMap.keys()) {
      const data = this.entityMap.get(id);
      if (data) {
        yield data;
      }
    }
  }

  OnStart = () => {
    let i = 0;
    for (const data of rows) {
      this.entityMap.set(
        data.id,
        new Entity({
          data,
          position: {
            x: em,
            y: em + i * em * 2,
          },
          dimensions: {
            width: (data.label.length + 3) * ch,
            height: em * 1.5,
          },
        }),
      );

      i++;
    }
  };

  OnUpdate = () => {
    for (const entity of this.entities()) {
      // for (const other of this.entities()) {
      //   if (other === entity) {
      //     continue;
      //   }

      // }

      for (const req of this.entities(entity.data.reqs)) {
        // entity.target.x = Math.max(entity.target.x, req.position.x + req.width + em * 5);
        // entity.position.x++;
      }
    }

    for (const entity of this.entities()) {
      const dist = entity.position.distanceTo(entity.target);
      entity.position.moveToward(entity.target, dist * 0.1);
    }
  };

  OnDraw = (ctx: CanvasRenderingContext2D) => {
    // clear it!
    ctx.clearRect(0, 0, this.width, this.height);

    // mouse
    ctx.strokeStyle = '#f90';
    ctx.beginPath();
    ctx.moveTo(this.mouse.x, 0);
    ctx.lineTo(this.mouse.x, this.height);
    ctx.moveTo(0, this.mouse.y);
    ctx.lineTo(this.width, this.mouse.y);
    ctx.stroke();
    ctx.closePath();

    // Draw the lines
    ctx.strokeStyle = '#00000099';
    ctx.lineWidth = em * 0.05;
    const rat = 0.5;
    const size = ch * rat;

    for (const entity of this.entities()) {
      for (const req of this.entities(entity.data.reqs ?? [])) {
        const target = entity.inputPoint.clone();
        const source = req.outputPoint.clone();
        const a = source.clone().moveToward(target, ch * rat);
        const b = target.clone().moveToward(source, ch * rat * 2);
        const c = target.clone().moveToward(source, ch * rat);

        ctx.beginPath();
        ctx.moveTo(b.x, b.y);
        ctx.lineTo(a.x, a.y);
        ctx.stroke();
        ctx.closePath();

        ctx.save();
        ctx.translate(c.x, c.y);

        ctx.beginPath();
        ctx.rotate(new Vector(target, source).angle - πh);
        ctx.moveTo(-size, size);
        ctx.lineTo(0, 0);
        ctx.lineTo(size, size);
        ctx.stroke();
        ctx.closePath();

        ctx.restore();
      }
    }

    // Draw the boxes and text
    ctx.font = `normal ${em}px "Courier New"`;
    for (const entity of this.entities()) {
      ctx.save();
      ctx.translate(entity.position.x, entity.position.y);
      ctx.fillStyle = '#eeeeeecc';
      ctx.fillRect(0, 0, entity.width, entity.height);

      ctx.fillStyle = '#333';
      ctx.fillText(entity.data.label, em / 2, em, entity.width);
      ctx.restore();
    }
  };
}
