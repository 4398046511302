import { Howl } from 'howler';
import { PointLike } from '../point';

export const sounds = {
  explosion: 'explosion-medium.wav',
  lazer: 'laser-alt.wav',
  powerUp: 'power-up.wav',
};

export function playStereoSound(name: keyof typeof sounds, pos: number | PointLike, width: number, volume = 1) {
  const x = typeof pos === 'number' ? pos : pos.x;
  const s = (x - width / 2) / (width / 2);
  const stereo = Math.cbrt(s);
  new Howl({
    src: [`./assets/sounds/${sounds[name]}`],
    volume,
    // @ts-ignore
    stereo,
  }).play();
}
