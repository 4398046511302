import { Component, OnInit } from '@angular/core';

class Stat {
  constructor(public readonly title: string, private descriptors: string[], public modifier = 0) {}

  // get modifier() {
  //   return Math.floor(this.value / 2) - 5;
  // }

  get description() {
    return this.descriptors[this.modifier + 5];
  }

  static strDescriptors = [
    'Morbidly weak, has significant trouble lifting own limbs',
    'Needs help to stand, can be knocked over by strong breezes',
    'Knocked off balance by swinging something dense',
    'Difficulty pushing an object of their weight',
    'Has trouble even lifting heavy objects',
    'Can literally pull their own weight',
    'Carries heavy objects for short distances',
    'Visibly toned, throws small objects for long distances',
    'Carries heavy objects with one arm',
    'Can break objects like wood with bare hands',
    'Able to out-wrestle a work animal or catch a falling person',
    'Can pull very heavy objects at appreciable speeds',
    'Pinnacle of brawn, able to out-lift several people',
  ];

  static dexDescriptors = [
    'Barely mobile, probably significantly paralyzed',
    'Incapable of moving without noticeable effort or pain',
    'Visible paralysis or physical difficulty',
    'Significant klutz or very slow to react',
    'Somewhat slow, occasionally trips over own feet',
    'Capable of usually catching a small tossed object',
    'Able to often hit large targets',
    'Can catch or dodge a medium-speed surprise projectile',
    'Able to often hit small targets',
    'Light on feet, able to often hit small moving targets',
    'Graceful, able to flow from one action into another easily',
    'Very graceful, capable of dodging a number of thrown objects',
    'Moves like water, reacting to all situations with almost no effort',
  ];
  static conDescriptors = [
    'Minimal immune system, body reacts violently to anything foreign',
    'Frail, suffers frequent broken bones',
    'Bruises very easily, knocked out by a light punch',
    'Unusually prone to disease and infection',
    'Easily winded, incapable of a full day’s hard labor',
    'Occasionally contracts mild sicknesses',
    'Can take a few hits before being knocked unconscious',
    'Able to labor for twelve hours most days',
    'Easily shrugs off most illnesses',
    'Able to stay awake for days on end',
    'Very difficult to wear down, almost never feels fatigue',
    'Never gets sick, even to the most virulent diseases',
    'Tireless paragon of physical endurance',
  ];
  static intDescriptors = [
    'Animalistic, no longer capable of logic or reason',
    'Barely able to function, very limited speech and knowledge',
    'Often resorts to charades to express thoughts',
    'Often misuses and mispronounces words',
    'Has trouble following trains of thought, forgets most unimportant things',
    'Knows what they need to know to get by',
    'Knows a bit more than is necessary, fairly logical',
    'Able to do math or solve logic puzzles mentally with reasonable accuracy',
    'Fairly intelligent, able to understand new tasks quickly',
    'Very intelligent, may invent new processes or uses for knowledge',
    'Highly knowledgeable, probably the smartest person many people know',
    'Able to make Holmesian leaps of logic',
    'Famous as a sage and genius',
  ];
  static wisDescriptors = [
    'Seemingly incapable of thought, barely aware',
    'Rarely notices important or prominent items, people, or occurrences',
    'Seemingly incapable of forethought',
    'Often fails to exert common sense',
    'Forgets or opts not to consider options before taking action',
    'Makes reasoned decisions most of the time',
    'Able to tell when a person is upset',
    'Can get hunches about a situation that doesn’t feel right',
    'Reads people and situations fairly well',
    'Often used as a source of wisdom or decider of actions',
    'Reads people and situations very well, almost unconsciously',
    'Can tell minute differences among many situations',
    'Nearly prescient, able to reason far beyond logic',
  ];
  static chaDescriptors = [
    'Barely conscious, incredibly tactless and non-empathetic',
    'Minimal independent thought, relies heavily on others to think instead',
    'Has trouble thinking of others as people',
    'Terribly reticent, uninteresting, or rude',
    'Something of a bore or makes people mildly uncomfortable',
    'Capable of polite conversation',
    'Mildly interesting, knows what to say to the right people',
    'Interesting, knows what to say to most people',
    'Popular, receives greetings and conversations on the street',
    'Immediately likeable by many people, subject of favorable talk',
    'Life of the party, able to keep people entertained for hours',
    'Immediately likeable by almost everybody',
    'Renowned for wit, personality, and/or looks',
  ];
}

class Character {
  constructor(public name = '', stats?: number[]) {
    if (stats) {
      stats.forEach((modifier, i) => (this.stats[i].modifier = modifier));
    }
  }

  readonly stats: Stat[] = [
    new Stat('Strength', Stat.strDescriptors),
    new Stat('Dexterity', Stat.dexDescriptors),
    new Stat('Constitution', Stat.conDescriptors),
    new Stat('Intelligence', Stat.intDescriptors),
    new Stat('Wisdom', Stat.wisDescriptors),
    new Stat('Charisma', Stat.chaDescriptors),
  ];
}

@Component({
  selector: 'app-dnd-stat-descriptor',
  templateUrl: './dnd-stat-descriptor.component.html',
  styleUrls: ['./dnd-stat-descriptor.component.scss'],
})
export class DndStatDescriptorComponent implements OnInit {
  characters: Character[] = [
    new Character('Kelryn', [2, 2, 2, 5, 1, 1]),
    new Character('Tally', [1, 0, 1, 4, 4, 4]),
    new Character('Miruki', [4, 3, 3, 2, 1, 0]),
    new Character('Dresdin', [1, 1, 3, 0, 1, 5]),
  ].sort((a, b) => {
    return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
  });

  constructor() {}

  ngOnInit(): void {}

  addCharacter() {
    this.characters.push(new Character());
    setTimeout(() => {
      const names = document.body.querySelectorAll('.name');
      console.log({ names });
      // @ts-ignore
      names.item(names.length - 1).focus();
    }, 0);
  }

  removeCharacter(index: number) {
    this.characters.splice(index, 1);
  }
}
