import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { BLEND_MODES, Container, Filter, Graphics, SCALE_MODES, Sprite } from 'pixi.js';
import { π, π2 } from 'src/app/pi';
import { rand, randInt } from 'src/app/rand';
import { Point } from '../point';
import { Game, ViewTarget } from './game';

@Component({
  selector: 'app-shadow',
  templateUrl: './shadow.component.html',
  styleUrls: ['./shadow.component.scss'],
})
export class ShadowComponent implements OnInit {
  constructor(readonly elementRef: ElementRef<HTMLDivElement>, protected ngZone: NgZone) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.init();
    });
  }

  init() {
    const game = new Game({
      container: this.elementRef.nativeElement,
      pixiOptions: {
        antialias: false,
        backgroundColor: 0xcccccc,
      },
    });

    const land = new Container();
    land.sortableChildren = true;
    game.pixiWorld.addChild(land);

    const ground = new Graphics();
    ground.zIndex = Number.NEGATIVE_INFINITY;
    ground.beginFill(0x99cc99);
    const tl = game.viewTarget.uiToWorldPosition(game.viewTarget.topLeft);
    const top = tl.y;
    const left = tl.x;
    const br = game.viewTarget.uiToWorldPosition(game.viewTarget.bottomRight);
    const right = br.x;
    const bottom = br.y;
    ground.drawRect(left, top, right - left, bottom - top);
    ground.endFill();
    land.addChild(ground);

    const rocks = this.addRocks(land, game.viewTarget);

    const lightMask = new Graphics();
    lightMask.zIndex = Number.POSITIVE_INFINITY;

    // const dark = new Graphics();
    // dark.beginFill(0x000000);
    // dark.drawCircle(0, 0, 50000);
    // dark.endFill();
    // lightMask.addChild(dark);

    game.onPixiUpdate(() => {
      lightMask.clear();

      // cover everything
      lightMask.beginFill();
      lightMask.drawCircle(0, 0, Point.distance({ x: game.width, y: game.height }));
      lightMask.endFill();

      // draw a hole -- this can be anything..?
      lightMask.beginHole();
      lightMask.drawCircle(player.position.x, player.position.y, 300);
      lightMask.endHole();

      // draw it back?
      lightMask.beginFill();
      const d = new Date().getTime() / (1000 / π2);
      lightMask.drawCircle(player.position.x + Math.cos(d) * 300, player.position.y + Math.sin(d) * 300, 100);
      lightMask.endFill();
    });

    // rocks.forEach(rock => {
    //   // const light = new Graphics();
    //   // light.beginFill(0xffffff);
    //   // light.drawCircle(rock.position.x, rock.position.y, 50);
    //   // light.endFill();
    //   // lightMask.addChild(light);
    //   lightMask.drawCircle(rock.position.x, rock.position.y, 50);
    // });

    const shadow = new Graphics();
    shadow.blendMode = BLEND_MODES.MULTIPLY;
    shadow.zIndex = Number.POSITIVE_INFINITY;
    shadow.beginFill(0x333366);
    shadow.drawCircle(0, 0, Point.distance({ x: game.width, y: game.height }));
    shadow.endFill();

    const light = new Container();
    light.zIndex = Number.POSITIVE_INFINITY;
    light.addChild(lightMask);
    light.mask = lightMask;
    light.addChild(shadow);

    land.addChild(light);

    const player = new Graphics();
    player.beginFill(0x003399, 0.9);
    player.drawStar(0, 0, 8, 32, 24);
    player.endFill();
    land.addChild(player);

    game.onPixiUpdate(() => {
      player.zIndex = player.position.y;
      player.position.copyFrom(game.mouse.worldPosition);
      // lightMask.position.copyFrom(player.position);

      const scale = Math.sin(new Date().getTime() / 1000) + 1 + 0.5;
      // lightMask.scale.copyFrom({ x: scale, y: scale });
    });
  }

  private addRocks(container: Container, view: ViewTarget) {
    const rocks = [];
    for (let i = 0; i < 50; i++) {
      const rock = Sprite.from(`../../../assets/rock-${randInt(4, 1)}.png`);
      const tl = view.uiToWorldPosition(view.topLeft);
      const top = tl.y;
      const left = tl.x;
      const br = view.uiToWorldPosition(view.bottomRight);
      const right = br.x;
      const bottom = br.y;
      rock.position.copyFrom({
        x: randInt(left, right),
        y: randInt(top, bottom),
      });
      rock.anchor.copyFrom({ x: 0.5, y: 1 });
      rock.scale.copyFrom({ x: 5, y: 5 });
      rock.texture.baseTexture.scaleMode = SCALE_MODES.NEAREST;
      rock.zIndex = rock.position.y;
      container.addChild(rock);
      rocks.push(rock);
    }
    return rocks;
  }
}
