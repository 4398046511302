import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { Container, Graphics, Text, TextStyle } from 'pixi.js';
import { Point, PointLike, Vector } from '../point';
import { cells } from './cells';
import { Drawing } from './drawing';
import { Game } from './game';
import { GridMath } from './grid-math';
import { MapPoint, MapPointType } from './map-point';

const sqrt3 = Math.sqrt(3);
const hexRatio = sqrt3 / 2;

const types = [
  MapPointType.dne,
  MapPointType.dot,
  MapPointType.mountain,
  MapPointType.circleCity,
  MapPointType.squareCity,
  MapPointType.hexCity,
];

@Component({
  selector: 'app-empire-builder',
  templateUrl: './empire-builder.component.html',
  styleUrls: ['./empire-builder.component.scss'],
})
export class EmpireBuilderComponent implements OnInit {
  private localNames: Record<string, string> = JSON.parse(window.localStorage.getItem('local-names') || '{}');
  private labelLayer = new Container();

  constructor(readonly elementRef: ElementRef<HTMLDivElement>, protected ngZone: NgZone) {}

  // game!: Game;

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.init();
    });
  }

  init() {
    const game = new Game({
      container: this.elementRef.nativeElement,
      pixiOptions: {
        antialias: true,
        backgroundColor: 0x66ccff,
      },
    });

    const gridSize = 42;
    const gridMath = new GridMath(gridSize);

    // this.game = game;

    // game.showMouseIndicator();

    this.addCountryOutline(game, gridSize);

    // const walls = new Graphics();
    // game.pixiWorld.addChild(walls);
    game.onPixiUpdate(() => {
      // scroll with the wheel
      const worldPos = game.mouse.worldPosition;
      game.viewTarget.scale *= 1 + game.mouse.wheel / -1000;
      // clamp scaling
      game.viewTarget.scale = Math.min(50, Math.max(0.25, game.viewTarget.scale));
      const newWorldPos = game.mouse.worldPosition;
      game.viewTarget.position.move(new Vector(newWorldPos, worldPos));

      if (game.mouse.isPressed && game.keyPressed.has(' ')) {
        game.viewTarget.followMouse();
      }

      // if (game.mouse.wasJustPressed) {
      //   const coords = game.viewTarget.uiWalls;
      //   const size = game.viewTarget.uiSize;
      //   console.log({ walls: coords, size });

      //   walls.clear();
      //   walls.lineStyle(10 / game.viewTarget.scale, 0x0000ff, 1, 0);
      //   walls.drawRect(coords.left, coords.top, size.width, size.height);
      // }
    });

    // track
    // const trackOne = new Graphics();
    // game.pixiWorld.addChild(trackOne);
    // trackOne.lineStyle(dotRadius, 0x00cc00);
    // let p = gridMath.coordToPoint({ col: 0, row: 0 });
    // trackOne.moveTo(p.x, p.y);
    // p = gridMath.coordToPoint({ col: 0, row: 1 });
    // trackOne.lineTo(p.x, p.y);
    // p = gridMath.coordToPoint({ col: 1, row: 1 });
    // trackOne.lineTo(p.x, p.y);

    const cols = 69;
    const rows = 49;

    const gridLayer = new Graphics();
    gridLayer.sortableChildren = true;

    const mapPoints: Record<string, MapPoint> = {};

    const localCells: Record<string, MapPointType> = JSON.parse(window.localStorage.getItem('map-points') || '{}');
    console.log({ cells, localCells });
    Object.keys(localCells).forEach(id => {
      cells[id] = { type: localCells[id] };
    });

    for (let col = 1; col < cols; col++) {
      for (let row = 0; row < rows; row++) {
        const coord = { col, row };
        const id = MapPoint.id(coord);
        const mode = cells[id]?.type || MapPointType.dne;
        if (mode !== MapPointType.dne) {
          const name = cells[id]?.name;
          const item = new MapPoint({ col, row }, gridMath, mode, name);
          mapPoints[item.id] = item;
          const position = gridMath.coordToPoint({ col, row });
          item.graphics.position.copyFrom(position);
          gridLayer.addChild(item.graphics);
        }
      }
    }
    game.pixiWorld.addChild(gridLayer);

    this.drawCityNames(game, mapPoints);

    // this.acceptCityNaming(game, gridMath, mapPoints);

    game.onPixiUpdate(() => {
      if (game.keyPressed.has('c')) {
        const delta = game.mouse.wasJustPressed ? 1 : game.mouse.wasJustPressedRight ? types.length - 1 : 0;
        if (delta) {
          const nearestCoord = gridMath.pointToNearestCoord(game.mouse.worldPosition);
          const id = MapPoint.id(nearestCoord);
          const item = mapPoints[id];
          if (item) {
            const currentType = item.type;
            const currentTypeIndex = types.indexOf(currentType);
            const newType = types[(currentTypeIndex + delta) % types.length];
            localCells[id] = newType;
            item.type = newType;
            this.savePointTypes(localCells);
          } else {
            const newItem = new MapPoint(nearestCoord, gridMath, MapPointType.dot);
            const position = gridMath.coordToPoint(nearestCoord);
            newItem.graphics.position.copyFrom(position);
            console.log({ newItem });
            localCells[newItem.id] = newItem.type;
            this.savePointTypes(localCells);
            gridLayer.addChild(newItem.graphics);
          }
        }
      }
    });

    const center = gridMath.coordToPoint({
      col: Math.floor(cols / 2),
      row: Math.floor(rows / 2),
    });
    game.viewTarget.position.set(center);

    this.showNearestGridItemIndicator(game, gridMath);
  }

  private drawCityNames(game: Game, mapPoints: Record<string, MapPoint>) {
    this.labelLayer.removeChildren();
    game.pixiWorld.addChild(this.labelLayer);

    Object.keys(mapPoints).forEach(id => {
      const mapPoint = mapPoints[id];
      const localName = this.localNames[id];
      const name = mapPoint.name || localName;
      if (name) {
        this.addCityLabel(name, new Point(mapPoint.graphics.position).move(0, -10), mapPoint.type);
      }
    });

    game.onPixiUpdate(() => {
      this.labelLayer.children.forEach(child => {
        const text = child as Text;
        const scale = Math.max(0.1, Math.min(0.25, 0.5 / game.viewTarget.scale));
        text.scale.copyFrom({
          x: scale,
          y: scale,
        });
        text.style.strokeThickness = 1 + 10 / game.viewTarget.scale;
      });
    });
  }

  private addCityLabel(name: string, position: PointLike, type: MapPointType) {
    const labelStyle = new TextStyle({
      align: 'center',
      fontSize: 64,
      fill: type === MapPointType.hexCity ? 0xffffff : 0x000000,
      fontFamily: 'Monaco, Courier New',
      // dropShadow: true,
      // dropShadowDistance: 10,
      // dropShadowColor: type === MapPointType.hexCity ? 0x000000 : 0xffffff,
      // dropShadowAlpha: 1,
      // dropShadowBlur: 4,
      // dropShadowAngle: πh,
      stroke: type === MapPointType.hexCity ? 0x000000 : 0xffffff,
      strokeThickness: 5,
    });

    // if (type !== MapPointType.hexCity) {
    //   labelStyle.dropShadowColor = 0xffffff;
    // }

    const text = new Text(name, labelStyle);
    text.position.copyFrom(position);
    this.labelLayer.addChild(text);
    text.anchor.copyFrom({
      x: 0.5,
      y: 1,
    });
  }

  private acceptCityNaming(game: Game, gridMath: GridMath, mapPoints: Record<string, MapPoint>) {
    game.onPixiUpdate(() => {
      if (game.keyPressed.has('t')) {
        const delta = game.mouse.wasJustPressed ? 1 : game.mouse.wasJustPressedRight ? types.length - 1 : 0;
        if (delta) {
          const nearestCoord = gridMath.pointToNearestCoord(game.mouse.worldPosition);
          const id = MapPoint.id(nearestCoord);
          const mapPoint = mapPoints[id];
          if (mapPoint) {
            game.keyPressed.clear();
            game.mouse.isPressed = false;
            game.mouse.isPressedRight = false;
            game.mouse.wasJustPressed = false;
            game.mouse.wasJustPressedRight = false;
            const name = prompt('City Name');
            if (name) {
              this.addCityLabel(name, new Point(mapPoint.graphics.position).move(0, -10), mapPoint.type);
              this.localNames[mapPoint.id] = name;
              window.localStorage.setItem('local-names', JSON.stringify(this.localNames));
            }
          }
        }
      }
    });
  }

  savePointTypes(mapPoints: Record<string, MapPointType>) {
    const points: Record<string, MapPointType> = {};
    Object.keys(mapPoints).forEach(id => {
      const type = mapPoints[id];
      if (type !== MapPointType.dne) {
        // const coord = MapPoint.coord(id);
        // coord.col += 2;
        // const newId = MapPoint.id(coord);
        const newId = id;
        points[newId] = mapPoints[id];
      }
    });
    window.localStorage.setItem('map-points', JSON.stringify(points));
  }

  showNearestGridItemIndicator(game: Game, gridMath: GridMath) {
    // nearest grid item
    const nearestDotIndicator = new Graphics();
    nearestDotIndicator.lineStyle(2, 0x000000, 0.5);
    const nearestDotIndicatorSize = 16;
    nearestDotIndicator.drawStar(0, 0, 6, nearestDotIndicatorSize, nearestDotIndicatorSize * hexRatio);
    game.pixiWorld.addChild(nearestDotIndicator);
    game.onPixiUpdate(() => {
      nearestDotIndicator.rotation += 0.05 / Math.pow(nearestDotIndicator.scale.x, 2);
      const nearestCoord = gridMath.pointToNearestCoord(game.mouse.worldPosition);
      const nearestGridPoint = gridMath.coordToPoint(nearestCoord);

      const current = new Point(nearestDotIndicator.position);
      let dist = current.distanceTo(nearestGridPoint);
      dist = dist < 1 ? dist : Math.ceil(dist * 0.2);

      if (dist > 1 && nearestDotIndicator.scale.x > 0.5) {
        nearestDotIndicator.scale.x -= 0.05;
        nearestDotIndicator.scale.y -= 0.05;
      } else if (dist === 0 && nearestDotIndicator.scale.x < 1) {
        nearestDotIndicator.scale.x += 0.1;
        nearestDotIndicator.scale.y += 0.1;
      }

      current.moveToward(nearestGridPoint, dist);
      nearestDotIndicator.position.copyFrom(current);
    });
  }

  addCountryOutline(game: Game, size: number) {
    const outline = new Drawing('country-outline', 1, 0x333333, 0xffffff);
    game.pixiWorld.addChild(outline.graphics);

    game.onPixiUpdate(() => {
      if (game.keyPressed.has('o')) {
        if (game.mouse.wasJustPressed) {
          outline
            .addPoint(game.mouse.worldPosition)
            .save()
            .draw();
        } else if (game.mouse.wasJustPressedRight) {
          outline
            .removeLastPoint()
            .save()
            .draw();
        }
      }
    });
  }
}
