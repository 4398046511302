import { Body, Composite, Engine, World } from 'matter-js';
import { Application, DisplayObject } from 'pixi.js';
import { PointLike } from '../point';
import { playStereoSound, sounds } from './sound';
import { Universe } from './universe.interface';

export enum Tag {
  asteroid,
  ship,
  bullet,
  wall,
}

export interface UpdateData {
  mouse: PointLike;
  isMouseDown: boolean;
  keyPressed: Set<string>;
}

export abstract class Entity {
  protected app: Application;
  protected world: World;
  protected entities: Entity[];
  public physBodyToEntityMap: Record<number, Entity>;
  protected physBodies = new Set<Body>();
  protected pixiBodies = new Set<DisplayObject>();

  constructor(universe: Universe) {
    this.app = universe.app;
    this.world = universe.world;
    this.entities = universe.entities;
    this.physBodyToEntityMap = universe.physBodyToEntityMap;

    // Add to entities
    this.entities.push(this);
  }

  abstract get position(): PointLike;

  onUpdate(_data: UpdateData) {}
  onDraw() {}
  // abstract onDestroy(): void;

  delete = false;

  destroy() {
    this.delete = true;
  }

  onDestroy() {
    this.pixiBodies.forEach(body => this.app.stage.removeChild(body));
    this.physBodies.forEach(body => Composite.remove(this.world, body));
  }

  readonly tags = new Set<Tag>();

  registerPhysBody(body: Body) {
    World.addBody(this.world, body);
    this.physBodyToEntityMap[body.id] = this;
    this.physBodies.add(body);
  }

  registerPixiBody(body: DisplayObject) {
    this.app.stage.addChild(body);
    this.pixiBodies.add(body);
  }

  handleCollision(_entity: Entity) {}

  hasTag(tag: Tag) {
    return this.tags.has(tag);
  }

  playSound(name: keyof typeof sounds, volume = 1) {
    playStereoSound(name, this.position.x, this.app.view.width, volume);
  }
}
